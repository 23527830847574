import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { cartDetails,bookmarkItems } from '../action';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { BASE_URL } from "../helper";
import Rating from "react-rating";
import {

  Link
} from "react-router-dom";
import {connect} from "react-redux";
// import Modal from 'react-modal';
import { Modal,Button } from 'react-bootstrap';
// import CheckOtp from '../pagesCheckOtp';

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import ForgotPassword from '../pages/ForgotPassword';
import CheckOtp from '../pages/CheckOtp';

const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
// const customStyles = {
//   content: {
//     top: '10%',
//     left: '10%',
//     right: '10%',
//     bottom: '10%',

//   },
// };
class ProductView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
          imageToShow:this.props.details.images[0]? BASE_URL.slice(0, -1)+`${this.props.details.images[0]}`:`${process.env.PUBLIC_URL}/assets/images/product/7.png`,
          cart_items:[],
          showModal: false,
          ratingModal: false,
          formType:null,
          rate_value:0,
          favorite:{status:false,value:0},
          buy:false,
          showSharePanel:false,
          copied:false
        };
        this.rateItem=this.rateItem.bind(this);
        this.ToolTip = React.createRef();
      }
      handleOpenModal= (form)=> {
        this.setState({formType:form},()=>{this.setState({ showModal: true })});
      }
      
      handleCloseModal = ()=> {
        this.setState({ showModal: false });
      }
      setShowImage=(imageToShow)=>{
         this.setState({imageToShow})
      }
      handleClick = () => {
        this.props.history.push("/cart")
        
      };

      checkFavStatus=async(data)=>{
        let id = localStorage.getItem('user_id');
        let token = localStorage.getItem('omg_token');
        // console.log("product_detail",id);
        if(token != "" && token != null)
        {
          this.setState({favorite:{status:true,value:0}})
          return await axios({
            method: 'post',
        
            url: BASE_URL+`api/product/favourites_check`,
            headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },    
            data: {
          "product_type":this.props.product_type,
          "product_id":data.id,
          "user_id":id
            }
          })
          .then( (response)=> {
            // alert(JSON.stringify(response));
            // console.log("favorite",response.data)
            this.setState({favorite:{status:true,value:response.data.favourite}})
            return Promise.resolve(response);
      
            
          })
          .catch((error)=> {
            this.setState({favorite:{status:false,value:0}})
            return Promise.reject(error);
          
          }); 
        }else{
        //  this.setState({favorite:{status:false,value:0}})  
        }

    
      }
      componentDidMount = ()=>{
        this.checkFavStatus(this.props.details);
      }
      addToCart=async()=>{

        let id = localStorage.getItem('user_id');
        let token = localStorage.getItem('omg_token');
      
  
        if(token === "" || token === null)
        {
          this.props.addToast(`Please Sign In To Continue`, {
            appearance: 'error',
            autoDismiss: true,
          })
          this.handleOpenModal('Login');
        // this.props.history.push("/login")
        return Promise.reject();  
        }else{
         

          var cartData=[];
         if(this.props.product_type =='object'){
          cartData=[{
            "product_type":"object",
            "object":this.props.details.id,
            "material":"",
            "graphics":"",
            "user_id":id,
            "seller_id":"2",
            "unit_price":this.props.details.price,
            "quantity":"1"
          }]
         }else if( this.props.product_type =='material'){
          cartData=[{
            "product_type":"material",
            "object":"",
            "material":this.props.details.id,
            "graphics":"",
            "user_id":id,
            "seller_id":"2",
            "unit_price":this.props.details.price,
            "quantity":"1"
          }]
         }else{
          cartData=[{
            "product_type":"graphics",
            "object":"",
            "material":"",
            "graphics":this.props.details.id,
            "user_id":id,
            "seller_id":"2",
            "unit_price":this.props.details.price,
            "quantity":"1"
          }]
         }
// alert(JSON.stringify(this.state.cart_items));
          return await axios({
            method: 'post',
            url: BASE_URL+'api/cart/cartitem/',
            headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
            data: {cart_items:cartData}
          })
          .then( (response)=> {

            if(this.props.product_type =='object'){
              this.props.addToast(`${this.props.details.object_name} added to cart`, {
                appearance: 'success',
                autoDismiss: true,
              })
            }else if( this.props.product_type =='material'){
              this.props.addToast(`${this.props.details.material_name} added to cart`, {
                appearance: 'success',
                autoDismiss: true,
              })
            }else {
              this.props.addToast(`${this.props.details.graphics_name} added to cart`, {
                appearance: 'success',
                autoDismiss: true,
              })
            }
        
            this.props.updateCartDetails();
            this.props.updateBookmarkItems();
            if(this.state.buy){
              this.setState({buy:false},()=>{
                this.props.history.push("/cart");
              })
            }
            return Promise.resolve(response);
           
            
          })
          .catch((error)=> {
            
            return Promise.reject(error);
           
          });
        }





      }


      addToBookMark=async()=>{
        let id = localStorage.getItem('user_id');
        let token = localStorage.getItem('omg_token');
        console.log(token)
        if(token === "" || token === null)
        {
          this.props.addToast(`Please Sign In To Continue`, {
            appearance: 'error',
            autoDismiss: true,
          })
          this.handleOpenModal('Login');
      
        // this.props.history.push("/login")
        return Promise.reject();  
        }else{


          if(this.props.bookmarkItems.length > 20){
            this.props.addToast(`Sorry,Your limit exceed only 20 product is allowed in bookmark`, {
              appearance: 'warning',
              autoDismiss: true,
            })
          }else{
            return await axios({
              method: 'post',
              url: BASE_URL+'api/cart/bookmark/',
              headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
              data: {
                "product_type":this.props.product_type,
                "object":(this.props.product_type=="object")?this.props.details.id:"",
                "material":(this.props.product_type=="material")?this.props.details.id:"",
                "graphics":(this.props.product_type=="graphics")?this.props.details.id:"",
                "user_id":id,
        
             
              }
            })
            .then( (response)=> {
  
              if(this.props.product_type =='object'){
                this.props.addToast(`${this.props.details.object_name} added to bookmarks`, {
                  appearance: 'success',
                  autoDismiss: true,
                })
              }else if( this.props.product_type =='material'){
                this.props.addToast(`${this.props.details.material_name} added to bookmarks`, {
                  appearance: 'success',
                  autoDismiss: true,
                })
              }else {
                this.props.addToast(`${this.props.details.graphics_name} added to bookmarks`, {
                  appearance: 'success',
                  autoDismiss: true,
                })
              }
  
  
               
          // this.props.showBookmark();
             console.log(response)
             this.props.getCategoryState();
             this.props.updateBookmarkItems();
              return Promise.resolve(response);
            
              
            })
            .catch((error)=> {
              this.props.getCategoryState();
             this.props.updateBookmarkItems();
              return Promise.reject(error);
             
            });
          }


        
        }
      
      
      }
      addToFavorites=async()=>{
        let id = localStorage.getItem('user_id');
        let token = localStorage.getItem('omg_token');
        console.log(token)
        // alert(id);
        if(token === "" || token === null)
        {
          this.props.addToast(`Please Sign In To Continue`, {
            appearance: 'error',
            autoDismiss: true,
          })
          this.handleOpenModal('Login');
      
        // this.props.history.push("/login")
        return Promise.reject();  
        }else{


            return await axios({
              method: 'post',
              url: BASE_URL+'api/product/favourites/',
              headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
              data: {
                "product_type":this.props.product_type,
                "object":(this.props.product_type=="object")?this.props.details.id:"",
                "material":(this.props.product_type=="material")?this.props.details.id:"",
                "graphics":(this.props.product_type=="graphics")?this.props.details.id:"",
                "user_id":id,
        
             
              }
            })
            .then( (response)=> {
  
              if(this.props.product_type =='object'){
                this.props.addToast(`${this.props.details.object_name} ${response.data.status ==="removed" ? "removed from " :"added to"}  favorites`, {
                  appearance: 'success',
                  autoDismiss: true,
                })
              }else if( this.props.product_type =='material'){
                this.props.addToast(`${this.props.details.material_name} ${response.data.status ==="removed" ? "removed from " :"added to"}  favorites`, {
                  appearance: 'success',
                  autoDismiss: true,
                })
              }else {
                this.props.addToast(`${this.props.details.graphics_name} ${response.data.status ==="removed" ? "removed from " :"added to"}  favorites`, {
                  appearance: 'success',
                  autoDismiss: true,
                })
              }
  
  
              this.checkFavStatus(this.props.details);
          // this.props.showBookmark();
            //  console.log("add to favourite",response)
            //  this.props.getCategoryState();
            //  this.props.updateBookmarkItems();
              return Promise.resolve(response);
            
              
            })
            .catch((error)=> {
              this.checkFavStatus(this.props.details);
              return Promise.reject(error);
             
            });
          


        
        }
      
      
      }
      
rateItem(){
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');

  // this.props.history.push("/login")
  
  }else{
    
    this.setState({ratingModal:true});
  }
  
}
submitRating=async()=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');

  // this.props.history.push("/login")
  return Promise.reject();  
  }else{


 
      return await axios({
        method: 'post',
        url: BASE_URL+'api/product/add_rating',
        headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
        data: {
          "product_type":this.props.product_type,
          "review_user":id,
          "rating":this.state.rate_value,
          "product":this.props.details.id,
  
       
        }
       
      })
      .then( (response)=> {
        
        this.setState({rate_value:0,ratingModal:false,},()=>{
          this.props.addToast(`Rating submitted successfully`, {
            appearance: 'success',
            autoDismiss: true,
          })
        });

       

         
    // this.props.showBookmark();
       
      
        return Promise.resolve(response);
      
        
      })
      .catch((error)=> {
        this.setState({rate_value:0,ratingModal:false,});
         console.log(error,'Error');
        // alert(error);
        // this.props.addToast(error, {
        //   appearance: 'error',
        //   autoDismiss: true,
        // })
        return Promise.reject(error);
       
      });
    


  
  }


}
showSharePanel=()=>{
  console.log('ok')
  this.setState({showSharePanel:!this.state.showSharePanel})

}
    render() {
      console.log(this.state)
      if(this.props.product_type =='object'){
        return (
         <>
             <Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>

  <Modal.Body>
{this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
} 
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}      {this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
     


</Modal.Body>

</Modal> 
<Modal className="BModal" show={this.state.ratingModal}
size="md"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({ratingModal:false})}>
  <Modal.Header >
          <Modal.Title>Your Rating <a style={{top:0,right:0,position: "absolute",padding: 10,margin:2,color: "#e06526",cursor:"pointer"}} onClick={()=>{this.setState({ratingModal:false})}}>X</a></Modal.Title>
        </Modal.Header>
  <Modal.Body>
  <div className="container  " style={{margin:0}}>
       
       <Rating
                   
                   initialRating={this.state.rate_value}
                   emptySymbol={<i className="fa fa-star " style={{color:'gray'}}></i>}
                   fullSymbol={<i className="fa fa-star checked"></i>}
                   onChange={(val)=>{this.setState({rate_value:val})}}
                    />
                   

       </div>     


</Modal.Body>
<Modal.Footer>
         
          <Button style={{color: '#fff',
    backgroundColor: '#ff7230',
    borderColor: '#ff7230',
}} onClick={this.submitRating} >
           Submit
          </Button>
        </Modal.Footer>
</Modal> 
       <section className="proddetail">
       <div className="container-fluid">
         <div className="row" style={{marginTop:'10em'}}>
           {/* Product Gallery section */}
           <div className="col-sm-12 col-md-6 px-5">
             <figure className="prddtfg">
               <img src={this.state.imageToShow} alt="product" className="img-fluid product-main-image" />
               <div className="prdtsoic">
                 {this.state.favorite.status && <span className="prdtsoic-item" onClick={()=>{this.addToFavorites()}}><i className="fa fa-heart"  style={{color:this.state.favorite.value === 1?"red":"#fff" }}/></span>}
                 
                 <span className="prdtsoic-item" onClick={()=>{this.rateItem()}}><i className="fa fa-star" /></span>
                 <span className="prdtsoic-item" onClick={()=>{this.showSharePanel()}}><i className="fa fa-share-alt" />
                 {/* <Overlay target={this.ToolTip.current} show={this.state.copied} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            My Tooltip
          </Tooltip>
        )}
      </Overlay> */}
                 <div class={this.state.showSharePanel? "social-slide-in from-right show":"social-slide-in from-right"}>
  <div class="slide-in-content">
    <ul>
      <li>  <TwitterShareButton
             openShareDialogOnClick={true}
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
          <i class="fab fa-twitter"></i>
          </TwitterShareButton></li>
      <li>  <FacebookShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
           <i class="fab fa-facebook-f"></i>
          </FacebookShareButton></li>
      <li>  <PinterestShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
         <i class="fab fa-pinterest-p"></i>
          </PinterestShareButton></li>
          <li>  <EmailShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
        <i class="far fa-envelope"></i>
          </EmailShareButton></li>
          <li> 
          <CopyToClipboard text={"this.state.value"}
          onCopy={() => this.setState({copied: true})}>

    
            <i class="far fa-copy" ref={this.ToolTip}></i>
         
     
        </CopyToClipboard>
        {/* {this.state.copied &&
        <span style={{backgroundColor:'green',padding:5,color:"#fff",alignSelf:'center'}}>Copied</span>
        } */}
          
          </li>
         
    </ul>
  </div>
</div>
                 </span>

                 <span className="prdtsoic-item" onClick={()=>{this.addToBookMark()}}> <img style={{width:17}} src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} alt="product" className="img-fluid" /></span>
               </div>
               <figurecaption className="prddtfgc">
                   {
                       this.props.details.images.length !== 0 &&
                       <>
                         {this.props.details.images.map((val)=>
               <figure onClick={()=>this.setShowImage(BASE_URL.slice(0, -1)+`${val}`)} className="prddtfgc-item">
                 <img src={BASE_URL.slice(0, -1)+`${val}`} alt="product" className={BASE_URL.slice(0, -1)+`${val}` === this.state.imageToShow?"img-fluid active":"img-fluid"} /></figure>
               )
               
               }
                       </>
                   }
             
                 {/* <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/7.png`} alt="product" className="img-fluid active" /></figure>
                 <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/8.png`} alt="product" className="img-fluid" /></figure>
                 <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/9.png`} alt="product" className="img-fluid" /></figure>
                 <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/10.png`} alt="product" className="img-fluid" /></figure> */}
               </figurecaption>
             </figure>
           </div>
           {/* Content */}
           <div className="col-sm-12 col-md-6">
             <h2>{this.props.details.object_name.toUpperCase()}<span className="boxhighlight-green">{this.props.details.asset}</span></h2>
             <div className="usr">
            
                            <>
                            
                            <Link to={{
                                pathname: `../../userprofile/${this.props.details.seller_id}`,
                                state: { id: this.props.details.seller_id }
                              }}
                            > 
                             {this.props.details.designer_image && this.props.details.designer_image !="" && (
                            <img src={BASE_URL.slice(0, -1)+`${this.props.details.designer_image}`} alt="user" className="img-fluid" width="90px" />
                            )}
                           
                            {!this.props.details.designer_image && this.props.details.designer_image =="" && (
                            <>
                             <img src={`${process.env.PUBLIC_URL}/assets/images/product/user1.png`} alt="user" className="img-fluid" width="90px" />
                            </>
                        )}
                         </Link>

                          
                             
                           
                            </>
                       
                        
            

                                  <Link to={{
                                    pathname: `../../userprofile/${this.props.details.seller_id}`,
                                    state: { id: this.props.details.seller_id }
                                  }}>
               
               <span className="usr-txt"> {this.props.details.designer_name}</span>
               </Link>
             </div>
             {/* Price Section */}
             <div className="buycont">
               <div className="col-sm-12 col-md-4 d-flex dt-price-wrap">
                 <div className="buycont-item">
                   <span className="prd-price">${this.props.details.price}</span>
                 </div>
               </div>
               <div className="col-sm-12 col-md-4">
                 <div className="buycont-item">
                   <a href="#" onClick={()=>{this.setState({buy:true},()=>{
                     this.addToCart()
                   })}} className="buy">Buy Now</a>
                 </div>
               </div>
               <div className="col-sm-3 col-md-2">
                 <a href="#" onClick={()=>{this.addToCart()}} className="buycont-item">
                   <img src={`${process.env.PUBLIC_URL}/assets/images/product/v1.png`}  alt="product" className="img-fluid item3" />
                 </a>
               </div>
               <div className="col-sm-3 col-md-2">
                 {/* <a  href="#" onClick={()=>{this.addToBookMark()}} className="buycont-item">
                   <img src={`${process.env.PUBLIC_URL}/assets/images/product/v2.png`} alt="product" className="img-fluid item4" />
                 </a> */}
               </div>
             </div>
             {/* Tag section */}
             <div className="tags mt-4">
                            <h4>Tags</h4>
             {this.props.details.tags && this.props.details.tags != null && this.props.details.tags.length > 0 && (
                              <>
                              
                               <ul className="prodtag">
                                 {this.props.details.tags.map((val)=>
  <li className="prodtag-item"><a href="#">{val}</a></li>
                                 )}
                               </ul>
                              
                              </>
                            ) }
                             </div>
             {/* Tab Section */}
             <ul className="nav nav-tabs mt-4" id="prodtab" role="tablist">
              
               <li className="nav-item">
                 <a className="nav-link active" id="desc-tab" data-toggle="tab" href="#desc" role="tab" aria-controls="desc" aria-selected="false">Description</a>
               </li>
               <li className="nav-item">
                 <a className="nav-link " id="more-tab" data-toggle="tab" href="#more" role="tab" aria-controls="more" aria-selected="true">Model Details</a>
               </li>
               <li className="nav-item">
                 <a className="nav-link" id="terms-tab" data-toggle="tab" href="#terms" role="tab" aria-controls="terms" aria-selected="false">Terms of Purchase</a>
               </li>
               <li className="nav-item">
                 <a className="nav-link" id="rating-tab" data-toggle="tab" href="#rating" role="tab" aria-controls="rating" aria-selected="false">Rating</a>
               </li>
             </ul>
             <div className="tab-content" id="proddesc">
               {/* <div className="tab-pane fade " id="more" role="tabpanel" aria-labelledby="more-tab">
                 <p>Category : {this.props.details.category}</p>
                 <p>Subcategory : {this.props.details.sub_category}</p>
                 <p>Platform : {this.props.details.platform}</p>
                 <p>Render : {this.props.details.render}</p>
                 <p>Material : {this.props.details.material}</p>
                 {this.props.details.three_d_printable ==1 && (
                   <>
                    <p>Product Link : <a href={`${this.props.details.product_link}`}>{this.props.details.product_link}</a> </p>
                   </>
                 )}
               </div> */}
                  <div className="tab-pane fade modelTab" id="more" role="tabpanel" aria-labelledby="more-tab">
                              {/* {JSON.stringify(this.props.details)} */}
                            {/* <p>Category : {this.props.details.category}</p>
                 <p>Image Type : {this.props.details.image_type}</p>
                 <p>Image Size : {this.props.details.image_size}</p>
                 <p>Orientation : {this.props.details.orientation}</p>
                 <p>People : {this.props.details.People}</p> */}

<div className="row">
                              <div className="col-5 left">
                              <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Category :</span>
                          <span className="label">{this.props.details.category}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Sub Category :</span>
                          <span className="label">{this.props.details.sub_category}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Material :</span>
                          <span className="label">{this.props.details.material}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Style :</span>
                          <span className="label">{this.props.details.style}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Platform :</span>
                          <span className="label">{this.props.details.platform}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Date :</span>
                          <span className="label">{this.props.details.uploaded_on}</span>
                      </div>
                            </div>
                            <div className="col-5 right">
                          
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Render :</span>
                          <span className="label">{this.props.details.render}</span>
                      </div>
                    
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Polygons :</span>
                          <span className="label">{this.props.details.polygon}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Vertices :</span>
                          <span className="label">{this.props.details.vertices}</span>
                      </div>
                                     
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Product Link:</span>
                          <span className="label"><a href={`${this.props.details.product_link}`} target="_blank"> <img src={`${process.env.PUBLIC_URL}/assets/images/browser.png`} width={30} height={29}/></a></span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between',alignItems:"center"}}>
                      
                      <span className="label">Rating :</span>
                      <div>
                            <Rating
                              readonly
                              initialRating={this.props.details.avg_rating?this.props.details.avg_rating:0}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                        <span className="label">| {this.props.details.avg_rating?this.props.details.avg_rating:0}</span>
                        </div>  
                      </div>
                    



             



                            </div>

                            </div>


                            </div>
               <div className="tab-pane fade show active" id="desc" role="tabpanel" aria-labelledby="desc-tab">
               {this.props.details.description} 
               </div>
               <div className="tab-pane fade" id="terms" role="tabpanel" aria-labelledby="terms-tab">
                 Vestibulum a purus quis enim finibus volutpat. Nunc fermentum erat et dui aliquet efficitur. Praesent pretium lectus ac diam rhoncus, at pellentesque tellus egestas. Etiam consectetur mi a luctus egestas. Donec egestas leo ac lacinia rutrum. Pellentesque egestas maximus lacus ut porta. Aliquam erat volutpat. 
               </div>
               <div className="tab-pane fade ratingTab" id="rating" role="tabpanel" aria-labelledby="rating-tab">
                              <div className="row">
                              <div className="col-5 left">
                              <h4 className="text-center my-3">Customer Rating</h4>
                              <div style={{flexDirection:'column',justifyContent:'center',alignItems:"center",display:'flex'}}>
                              
                              <div className="trl-box">
                              <h4 className="label l-left">{this.props.details.avg_rating?this.props.details.avg_rating:0} out of 5</h4>
                            <Rating
                              readonly
                              initialRating={this.props.details.avg_rating?this.props.details.avg_rating:0}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                            </div>
                            </div>
                            </div>
                            <div className="col-6 right">
                       <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={5}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">OMG! </span>
                      </div>



                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={4}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Love it</span>
                      </div>

                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={3}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Like it</span>
                      </div>
                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={2}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">It's okay</span>
                      </div>
                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={1}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Not a Fan</span>
                      </div>



                            </div>

                            </div>
                            </div>
             </div>
           </div>
         </div>{/* End Row */}
         {/* Related Models */}
         {this.props.related_items && this.props.related_items.length > 0 && (
                        <>
                         <div className="related-head mt-4 ml-4 pl-2">
                        <h3><i>Related Models</i></h3>
                      </div>
                      <div className="related-item">
                      {this.props.related_items.map((item)=>
                        <div className="item-wrap">
                        <div className="card prod-item">
                          {/* <Link exact to={{
                              pathname: `/productDetails/${'object'}/${item.id}`,
                              // search: '?id=modus-create',
                              state: { id: item.id,product_type:'object' }
                            }} activeClassName="active" className="link">
                              <img src={`http://165.22.212.233:8002${item.thumbnail_image}`} className="card-img-top" alt="" />
                          </Link> */}
                           <a href={`/productDetails/object/${item.id}`}  activeClassName="active" className="link">
                            <img src={BASE_URL.slice(0, -1)+`${item.thumbnail_image}`} className="card-img-top" alt="" />
                          </a>

                          <img src="images/badge.png" className="pbadge" alt="" />
                          <div className="card-body">
                            <h5 className="card-title">{item.object_name.toUpperCase()}</h5>
                            <p className="card-text">{item.avg_rating?item.avg_rating:0}
                            <span style={{marginLeft:8}}><Rating
                   readonly
                   initialRating={item.avg_rating?item.avg_rating:0}
                   emptySymbol={<i className="fa fa-star " style={{color:'gray',fontSize:10}}></i>}
                   fullSymbol={<i className="fa fa-star checked" style={{fontSize:10}}></i>}
                    /></span>
                              {/* <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" /> */}
                            </p>
                          </div>
                        </div>
                      </div>
                      )}

                  
                    
                      </div>{/* End Related Item */}
                        </>
                      )}
       </div>
     </section> 
     </>
        );
                }else if(this.props.product_type =='material'){
                  return (
             <>
       <Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>

  <Modal.Body>
{this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
} 
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}      {this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
     


</Modal.Body>

</Modal> 
<Modal className="BModal" show={this.state.ratingModal}
size="md"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({ratingModal:false})}>
  <Modal.Header >
          <Modal.Title>Your Rating <a style={{top:0,right:0,position: "absolute",padding: 10,margin:2,color: "#e06526",cursor:"pointer"}} onClick={()=>{this.setState({ratingModal:false})}}>X</a></Modal.Title>
        </Modal.Header>
  <Modal.Body>
  <div className="container  " style={{margin:0}}>
       
       <Rating
                   
                   initialRating={this.state.rate_value}
                   emptySymbol={<i className="fa fa-star " style={{color:'gray'}}></i>}
                   fullSymbol={<i className="fa fa-star checked"></i>}
                   onChange={(val)=>{this.setState({rate_value:val})}}
                    />
                   

       </div>     


</Modal.Body>
<Modal.Footer>
         
          <Button style={{color: '#fff',
    backgroundColor: '#ff7230',
    borderColor: '#ff7230',
}} onClick={this.submitRating} >
           Submit
          </Button>
        </Modal.Footer>
</Modal> 
                    <section className="proddetail">
                    <div className="container-fluid">
                      <div className="row" style={{marginTop:'10em'}}>
                        {/* Product Gallery section */}
                        <div className="col-sm-12 col-md-6 px-5">
                          <figure className="prddtfg">
                            <img src={this.state.imageToShow} alt="product" className="img-fluid product-main-image" />
                            <div className="prdtsoic">
                            {this.state.favorite.status && <span className="prdtsoic-item" onClick={()=>{this.addToFavorites()}}><i className="fa fa-heart"  style={{color:this.state.favorite.value === 1?"red":"#fff" }}/></span>}
                              <span className="prdtsoic-item" onClick={()=>{this.rateItem()}}><i className="fa fa-star" /></span>
                              <span className="prdtsoic-item" onClick={()=>{this.showSharePanel()}}><i className="fa fa-share-alt" />
                 {/* <Overlay target={this.ToolTip.current} show={this.state.copied} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            My Tooltip
          </Tooltip>
        )}
      </Overlay> */}
                 <div class={this.state.showSharePanel? "social-slide-in from-right show":"social-slide-in from-right"}>
  <div class="slide-in-content">
    <ul>
      <li>  <TwitterShareButton
             openShareDialogOnClick={true}
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
          <i class="fab fa-twitter"></i>
          </TwitterShareButton></li>
      <li>  <FacebookShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
           <i class="fab fa-facebook-f"></i>
          </FacebookShareButton></li>
      <li>  <PinterestShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
         <i class="fab fa-pinterest-p"></i>
          </PinterestShareButton></li>
          <li>  <EmailShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
        <i class="far fa-envelope"></i>
          </EmailShareButton></li>
          <li> 
          <CopyToClipboard text={"this.state.value"}
          onCopy={() => this.setState({copied: true})}>

    
            <i class="far fa-copy" ref={this.ToolTip}></i>
         
     
        </CopyToClipboard>
        {/* {this.state.copied &&
        <span style={{backgroundColor:'green',padding:5,color:"#fff",alignSelf:'center'}}>Copied</span>
        } */}
          
          </li>
         
    </ul>
  </div>
</div>
                 </span>
                              <span className="prdtsoic-item" onClick={()=>{this.addToBookMark()}}> <img style={{width:17}} src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} alt="product" className="img-fluid" /></span>
                            </div>
                            <figurecaption className="prddtfgc">
                                {
                                    this.props.details.images.length !== 0 &&
                                    <>
                                      {this.props.details.images.map((val)=>
                            <figure onClick={()=>this.setShowImage(BASE_URL.slice(0, -1)+`${val}`)} className="prddtfgc-item"><img src={BASE_URL.slice(0, -1)+`${val}`} alt="product" className={BASE_URL.slice(0, -1)+`${val}` === this.state.imageToShow?"img-fluid active":"img-fluid"} /></figure>
                            )
                            
                            }
                                    </>
                                }
                          
                              {/* <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/7.png`} alt="product" className="img-fluid active" /></figure>
                              <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/8.png`} alt="product" className="img-fluid" /></figure>
                              <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/9.png`} alt="product" className="img-fluid" /></figure>
                              <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/10.png`} alt="product" className="img-fluid" /></figure> */}
                            </figurecaption>
                          </figure>
                        </div>
                        {/* Content */}
                        <div className="col-sm-12 col-md-6">
                          <h2>{this.props.details.material_name.toUpperCase()}<span className="boxhighlight-green">{this.props.details.asset}</span></h2>
                          <div className="usr">
                            {/* <img src={`${process.env.PUBLIC_URL}/assets/images/product/user1.png`} alt="user" className="img-fluid" />
                            <span className="usr-txt">Lorem Ipsum</span> */}
                            <div className="usr">


                            <Link to={{
                                pathname: `../../userprofile/${this.props.details.seller_id}`,
                                state: { id: this.props.details.seller_id }
                              }}
                            > 
                             {this.props.details.designer_image && this.props.details.designer_image !="" && (
                            <img src={BASE_URL.slice(0, -1)+`${this.props.details.designer_image}`} alt="user" className="img-fluid" width="90px" />
                            )}
                           
                            {!this.props.details.designer_image && this.props.details.designer_image =="" && (
                            <>
                             <img src={`${process.env.PUBLIC_URL}/assets/images/product/user1.png`} alt="user" className="img-fluid" width="90px" />
                            </>
                        )}
                         </Link>



                             

                              <Link to={{
                                    pathname: `../../userprofile/${this.props.details.seller_id}`,
                                    state: { id: this.props.details.seller_id }
                                  }}>
                                
                                <span className="usr-txt"> {this.props.details.designer_name}</span>
                                </Link>
                            </div>

                          </div>
                          {/* Price Section */}
                          <div className="buycont">
                          <div className="col-sm-12 col-md-4 d-flex dt-price-wrap">
                              <div className="buycont-item">
                                <span className="prd-price">${this.props.details.price}</span>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="buycont-item">
                                <a href="#" onClick={()=>{this.setState({buy:true},()=>{
                     this.addToCart()
                   })}} className="buy">Buy Now</a>
                              </div>
                            </div>
                            <div className="col-sm-3 col-md-2">
                              <a href="#" onClick={()=>{this.addToCart()}} className="buycont-item">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/v1.png`}  alt="product" className="img-fluid item3" />
                              </a>
                            </div>
                            <div className="col-sm-3 col-md-2">
                              {/* <a className="buycont-item" onClick={()=>{this.addToBookMark()}}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/v2.png`} alt="product" className="img-fluid item4" />
                              </a> */}
                            </div>
                          </div>
                          {/* Tag section */}
                          <div className="tags mt-4">
                            <h4>Tags</h4>
                          {this.props.details.tags && this.props.details.tags != null && this.props.details.tags.length > 0 && (
                              <>
                              
                               <ul className="prodtag">
                                 {this.props.details.tags.map((val)=>
  <li className="prodtag-item"><a href="#">{val}</a></li>
                                 )}
                               </ul>
                              
                              </>
                            ) }
                             </div>
                          {/* <div className="tags mt-4">
                            <h4>Tags</h4>
                            <ul className="prodtag">
                              <li className="prodtag-item"><a href="#">Cloths</a></li>
                              <li className="prodtag-item"><a href="#">Materials</a></li>
                              <li className="prodtag-item"><a href="#">Cleaver</a></li>
                              <li className="prodtag-item"><a href="#">Ax</a></li>
                              <li className="prodtag-item"><a href="#">Stump</a></li>
                              <li className="prodtag-item"><a href="#">Log</a></li>
                              <li className="prodtag-item"><a href="#">Deckhouse</a></li>
                              <li className="prodtag-item"><a href="#">Firewood</a></li>
                              <li className="prodtag-item"><a href="#">Workshop</a></li>
                              <li className="prodtag-item"><a href="#">Yard</a></li>
                              <li className="prodtag-item"><a href="#">Barn</a></li>
                              <li className="prodtag-item"><a href="#">Birch</a></li>
                              <li className="prodtag-item"><a href="#">Garage</a></li>
                              <li className="prodtag-item"><a href="#">Tool</a></li>
                              <li className="prodtag-item"><a href="#">Metal</a></li>
                              <li className="prodtag-item"><a href="#">Wood</a></li>
                            </ul>
                          </div> */}
                          {/* Tab Section */}
                          <ul className="nav nav-tabs mt-4" id="prodtab" role="tablist">
                           
                            <li className="nav-item ">
                              <a className="nav-link active" id="desc-tab" data-toggle="tab" href="#desc" role="tab" aria-controls="desc" aria-selected="false">Description</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link " id="more-tab" data-toggle="tab" href="#more" role="tab" aria-controls="more" aria-selected="true">Model Details</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" id="terms-tab" data-toggle="tab" href="#terms" role="tab" aria-controls="terms" aria-selected="false">Terms of Purchase</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" id="rating-tab" data-toggle="tab" href="#rating" role="tab" aria-controls="rating" aria-selected="false">Rating</a>
                            </li>
                          </ul>
                          <div className="tab-content" id="proddesc">
                          <div className="tab-pane fade modelTab" id="more" role="tabpanel" aria-labelledby="more-tab">
                              {/* {JSON.stringify(this.props.details)} */}
                            {/* <p>Category : {this.props.details.category}</p>
                 <p>Image Type : {this.props.details.image_type}</p>
                 <p>Image Size : {this.props.details.image_size}</p>
                 <p>Orientation : {this.props.details.orientation}</p>
                 <p>People : {this.props.details.People}</p> */}

<div className="row">
                              <div className="col-5 left">
                              <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Category :</span>
                          <span className="label">{this.props.details.category}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Sub Category :</span>
                          <span className="label">{this.props.details.sub_category}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Material :</span>
                          <span className="label">{this.props.details.material}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Style :</span>
                          <span className="label">{this.props.details.style}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Platform :</span>
                          <span className="label">{this.props.details.platform}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Date :</span>
                          <span className="label">{this.props.details.uploaded_on}</span>
                      </div>
                            </div>
                            <div className="col-5 right">
                          
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Render :</span>
                          <span className="label">{this.props.details.render}</span>
                      </div>
                    
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Polygons :</span>
                          <span className="label">{this.props.details.polygon}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Vertices :</span>
                          <span className="label">{this.props.details.vertices}</span>
                      </div>
                                     
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between',marginBottom:2}}>
                          <span className="label">Product Link:</span>
                         
                          <span className="label"><a href={`${this.props.details.product_link}`} target="_blank"> <img src={`${process.env.PUBLIC_URL}/assets/images/browser.png`} width={30} height={29}/></a></span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between',alignItems:"center"}}>
                      
                      <span className="label">Rating :</span>
                      <div>
                            <Rating
                              readonly
                              initialRating={this.props.details.avg_rating?this.props.details.avg_rating:0}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                        <span className="label">| {this.props.details.avg_rating?this.props.details.avg_rating:0}</span>
                        </div>  
                      </div>
                    



             



                            </div>

                            </div>


                            </div>
                            {/* <div className="tab-pane fade " id="more" role="tabpanel" aria-labelledby="more-tab">
                            <p>Category : {this.props.details.category}</p>
                 <p>Subcategory : {this.props.details.sub_category}</p>
                 <p>Platform : {this.props.details.platform}</p>
                 <p>Render : {this.props.details.render}</p>
                 <p>Material : {this.props.details.material}</p>
                            </div> */}
                            <div className="tab-pane fade show active" id="desc" role="tabpanel" aria-labelledby="desc-tab">
                            {this.props.details.description} 
                            </div>
                            <div className="tab-pane fade" id="terms" role="tabpanel" aria-labelledby="terms-tab">
                              Vestibulum a purus quis enim finibus volutpat. Nunc fermentum erat et dui aliquet efficitur. Praesent pretium lectus ac diam rhoncus, at pellentesque tellus egestas. Etiam consectetur mi a luctus egestas. Donec egestas leo ac lacinia rutrum. Pellentesque egestas maximus lacus ut porta. Aliquam erat volutpat. 
                            </div>
                            <div className="tab-pane fade ratingTab" id="rating" role="tabpanel" aria-labelledby="rating-tab">
                              <div className="row">
                              <div className="col-5 left">
                              <h4 className="text-center my-3">Customer Rating</h4>
                              <div style={{flexDirection:'column',justifyContent:'center',alignItems:"center",display:'flex'}}>
                              
                              <div className="trl-box">
                              <h4 className="label l-left">{this.props.details.avg_rating?this.props.details.avg_rating:0} out of 5</h4>
                            <Rating
                              readonly
                              initialRating={this.props.details.avg_rating?this.props.details.avg_rating:0}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                            </div>
                            </div>
                            </div>
                            <div className="col-6 right">
                       <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={5}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">OMG! </span>
                      </div>



                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={4}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Love it</span>
                      </div>

                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={3}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Like it</span>
                      </div>
                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={2}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">It's okay</span>
                      </div>
                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={1}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Not a Fan</span>
                      </div>



                            </div>

                            </div>
                            </div>
                          </div>
                        </div>
                      </div>{/* End Row */}
                      {/* Related Models */}
                      {this.props.related_items && this.props.related_items.length > 0 && (
                        <>
                         <div className="related-head mt-4 ml-4 pl-2">
                        <h3><i>Related Models</i></h3>
                      </div>
                      <div className="related-item">
                      {this.props.related_items.map((item)=>
                        <div className="item-wrap">
                        <div className="card prod-item">
                          {/* <Link exact to={{
                            pathname: `/productDetails/${'material'}/${item.id}`,
                            // search: '?id=modus-create',
                            state: { id: item.id,product_type:'material' }
                          }} activeClassName="active" className="link">
                            <img src={`http://165.22.212.233:8002${item.thumbnail_image}`} className="card-img-top" alt="" />
                            </Link> */}

                          <a href={`/productDetails/material/${item.id}`}  activeClassName="active" className="link">
                            <img src={BASE_URL.slice(0, -1)+`${item.thumbnail_image}`} className="card-img-top" alt="" />
                          </a>

                          <img src="images/badge.png" className="pbadge" alt="" />
                          <div className="card-body">
                            <h5 className="card-title">{item.material_name.toUpperCase()}</h5>
                            <p className="card-text">{item.avg_rating?item.avg_rating:0}
                            <span style={{marginLeft:8}}><Rating
                   readonly
                   initialRating={item.avg_rating?item.avg_rating:0}
                   emptySymbol={<i className="fa fa-star " style={{color:'gray',fontSize:10}}></i>}
                   fullSymbol={<i className="fa fa-star checked" style={{fontSize:10}}></i>}
                    /></span>
                              {/* <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" /> */}
                            </p>
                          </div>
                        </div>
                      </div>
                      )}

                  
                    
                      </div>{/* End Related Item */}
                        </>
                      )}
                    </div>
                  </section> 
                  </>
                     );
                }else if(this.props.product_type =='graphics'){
                  return (
             <>
           <Modal className="BModal" show={this.state.showModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered

              onHide={()=>this.setState({showModal:false})}>

                <Modal.Body>
              {this.state.formType === "Login" &&
              <Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
              }

              {this.state.formType === "Signup" &&
              <Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
              } 
              {this.state.formType === "ForgotPassword" &&
              <ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
              }        


              </Modal.Body>

            </Modal> 
            <Modal className="BModal" show={this.state.ratingModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered

            onHide={()=>this.setState({ratingModal:false})}>
              <Modal.Header >
                      <Modal.Title>Your Rating <a style={{top:0,right:0,position: "absolute",padding: 10,margin:2,color: "#e06526",cursor:"pointer"}} onClick={()=>{this.setState({ratingModal:false})}}>X</a></Modal.Title>
                    </Modal.Header>
              <Modal.Body>
              <div className="container  " style={{margin:0}}>
                  
                  <Rating
                              
                              initialRating={this.state.rate_value}
                              emptySymbol={<i className="fa fa-star " style={{color:'gray'}}></i>}
                              fullSymbol={<i className="fa fa-star checked"></i>}
                              onChange={(val)=>{this.setState({rate_value:val})}}
                                />
                              

                  </div>     


            </Modal.Body>
            <Modal.Footer>
                    
                      <Button style={{color: '#fff',
                backgroundColor: '#ff7230',
                borderColor: '#ff7230',
            }} onClick={this.submitRating} >
                      Submit
                      </Button>
                    </Modal.Footer>
            </Modal> 
                    <section className="proddetail">
                    <div className="container-fluid">
                      <div className="row" style={{marginTop:'10em'}}>
                        {/* Product Gallery section */}
                        <div className="col-sm-12 col-md-6 px-5">
                          <figure className="prddtfg">
                            <img src={this.state.imageToShow} alt="product" className="img-fluid product-main-image" />
                            <div className="prdtsoic">
                              {this.state.favorite.status && <span className="prdtsoic-item" onClick={()=>{this.addToFavorites()}}><i className="fa fa-heart"  style={{color:this.state.favorite.value === 1?"red":"#fff" }}/></span>}
                                <span className="prdtsoic-item" onClick={()=>{this.rateItem()}}><i className="fa fa-star" /></span>
                                <span className="prdtsoic-item" onClick={()=>{this.showSharePanel()}}><i className="fa fa-share-alt" />
                  
                                <div class={this.state.showSharePanel? "social-slide-in from-right show":"social-slide-in from-right"}>
                                  <div class="slide-in-content">
                                    <ul>
                                      <li>  <TwitterShareButton
                                            openShareDialogOnClick={true}
                                            url={'http://twitter.com/'}
                                            title={"Test Title"}
                                            className="Demo__some-network__share-button"
                                          >
                                          <i class="fab fa-twitter"></i>
                                          </TwitterShareButton></li>
                                      <li>  <FacebookShareButton
                                            url={'http://twitter.com/'}
                                            title={"Test Title"}
                                            className="Demo__some-network__share-button"
                                          >
                                          <i class="fab fa-facebook-f"></i>
                                          </FacebookShareButton></li>
                                      <li>  <PinterestShareButton
                                            url={'http://twitter.com/'}
                                            title={"Test Title"}
                                            className="Demo__some-network__share-button"
                                          >
                                        <i class="fab fa-pinterest-p"></i>
                                          </PinterestShareButton></li>
                                          <li>  <EmailShareButton
                                            url={'http://twitter.com/'}
                                            title={"Test Title"}
                                            className="Demo__some-network__share-button"
                                          >
                                        <i class="far fa-envelope"></i>
                                          </EmailShareButton></li>
                                          <li> 
                                          <CopyToClipboard text={"this.state.value"}
                                          onCopy={() => this.setState({copied: true})}>

                                    
                                            <i class="far fa-copy" ref={this.ToolTip}></i>
                                        
                                    
                                        </CopyToClipboard>
                                        {/* {this.state.copied &&
                                        <span style={{backgroundColor:'green',padding:5,color:"#fff",alignSelf:'center'}}>Copied</span>
                                        } */}
                                          
                                          </li>
                                        
                                    </ul>
                                  </div>
                                </div>
                              </span>
                                <span className="prdtsoic-item" onClick={()=>{this.addToBookMark()}}> <img style={{width:17}} src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} alt="product" className="img-fluid" /></span>
                            </div>
                            <figurecaption className="prddtfgc">
                                {
                                    this.props.details.images.length !== 0 &&
                                    <>
                                      {this.props.details.images.map((val)=>
                            <figure onClick={()=>this.setShowImage(BASE_URL.slice(0, -1)+`${val}`)} className="prddtfgc-item"><img src={BASE_URL.slice(0, -1)+`${val}`} alt="product" className={BASE_URL.slice(0, -1)+`${val}` === this.state.imageToShow?"img-fluid active":"img-fluid"} /></figure>
                            )
                            
                            }
                                    </>
                                }
                          
                              {/* <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/7.png`} alt="product" className="img-fluid active" /></figure>
                              <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/8.png`} alt="product" className="img-fluid" /></figure>
                              <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/9.png`} alt="product" className="img-fluid" /></figure>
                              <figure className="prddtfgc-item"><img src={`${process.env.PUBLIC_URL}/assets/images/product/10.png`} alt="product" className="img-fluid" /></figure> */}
                            </figurecaption>
                          </figure>
                        </div>
                        {/* Content */}
                        <div className="col-sm-12 col-md-6">
                          <h2>{this.props.details.graphics_name.toUpperCase()}</h2>
                         
                          <div className="usr">
                          <Link to={{
                                pathname: `../../userprofile/${this.props.details.seller_id}`,
                                state: { id: this.props.details.seller_id }
                              }}
                            > 
                             {this.props.details.designer_image && this.props.details.designer_image !="" && (
                            <img src={BASE_URL.slice(0, -1)+`${this.props.details.designer_image}`} alt="user" className="img-fluid" width="90px" />
                            )}
                           
                            {!this.props.details.designer_image && this.props.details.designer_image =="" && (
                            <>
                             <img src={`${process.env.PUBLIC_URL}/assets/images/product/user1.png`} alt="user" className="img-fluid" width="90px" />
                            </>
                        )}
                         </Link>


                              <Link to={{
                                    pathname: `../../userprofile/${this.props.details.seller_id}`,
                                    state: { id: this.props.details.seller_id }
                                  }}>
                                  <span className="usr-txt"> {this.props.details.designer_name}</span>
                              </Link>
                            </div>
                          {/* Price Section */}
                          <div className="buycont">
                          <div className="col-sm-12 col-md-4 d-flex dt-price-wrap">
                              <div className="buycont-item">
                                <span className="prd-price">${this.props.details.price}</span>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="buycont-item">
                                <a href="#" onClick={()=>{this.setState({buy:true},()=>{
                     this.addToCart()
                   })}} className="buy">Buy Now</a>
                              </div>
                            </div>
                            <div className="col-sm-3 col-md-2">
                              <a href="#" onClick={()=>{this.addToCart()}} className="buycont-item">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/v1.png`}  alt="product" className="img-fluid item3" />
                              </a>
                            </div>
                            <div className="col-sm-3 col-md-2">
                              {/* <a className="buycont-item" onClick={()=>{this.addToBookMark()}}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/product/v2.png`} alt="product" className="img-fluid item4" />
                              </a> */}
                            </div>
                          </div>
                          {/* Tag section */}
                          <div className="tags mt-4">
                            <h4>Tags</h4>
                            {this.props.details.tags && this.props.details.tags != null && this.props.details.tags.length > 0 && (
                              <>
                               
                               <ul className="prodtag">
                                 {this.props.details.tags.map((val)=>
  <li className="prodtag-item"><a href="#">{val}</a></li>
                                 )}
                               </ul>
                              
                              </>
                            ) }
 </div>
                            {/* {this.props.details.tags} */}
                            {/* <ul className="prodtag">
                              <li className="prodtag-item"><a href="#">Cloths</a></li>
                              <li className="prodtag-item"><a href="#">Materials</a></li>
                              <li className="prodtag-item"><a href="#">Cleaver</a></li>
                              <li className="prodtag-item"><a href="#">Ax</a></li>
                              <li className="prodtag-item"><a href="#">Stump</a></li>
                              <li className="prodtag-item"><a href="#">Log</a></li>
                              <li className="prodtag-item"><a href="#">Deckhouse</a></li>
                              <li className="prodtag-item"><a href="#">Firewood</a></li>
                              <li className="prodtag-item"><a href="#">Workshop</a></li>
                              <li className="prodtag-item"><a href="#">Yard</a></li>
                              <li className="prodtag-item"><a href="#">Barn</a></li>
                              <li className="prodtag-item"><a href="#">Birch</a></li>
                              <li className="prodtag-item"><a href="#">Garage</a></li>
                              <li className="prodtag-item"><a href="#">Tool</a></li>
                              <li className="prodtag-item"><a href="#">Metal</a></li>
                              <li className="prodtag-item"><a href="#">Wood</a></li>
                            </ul> */}
                         
                          {/* Tab Section */}
                          <ul className="nav nav-tabs mt-4" id="prodtab" role="tablist">
                           
                            <li className="nav-item">
                              <a className="nav-link active" id="desc-tab" data-toggle="tab" href="#desc" role="tab" aria-controls="desc" aria-selected="false">Description</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link " id="more-tab" data-toggle="tab" href="#more" role="tab" aria-controls="more" aria-selected="true">Details</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" id="terms-tab" data-toggle="tab" href="#terms" role="tab" aria-controls="terms" aria-selected="false">Terms of Purchase</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" id="rating-tab" data-toggle="tab" href="#rating" role="tab" aria-controls="rating" aria-selected="false">Rating</a>
                            </li>
                          </ul>
                          <div className="tab-content" id="proddesc">
                            <div className="tab-pane fade modelTab" id="more" role="tabpanel" aria-labelledby="more-tab">
                              {/* {JSON.stringify(this.props.details)} */}
                            {/* <p>Category : {this.props.details.category}</p>
                 <p>Image Type : {this.props.details.image_type}</p>
                 <p>Image Size : {this.props.details.image_size}</p>
                 <p>Orientation : {this.props.details.orientation}</p>
                 <p>People : {this.props.details.People}</p> */}

<div className="row">
                              <div className="col-5 left">
                              <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Category :</span>
                          <span className="label">{this.props.details.category}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Image Type :</span>
                          <span className="label">{this.props.details.image_type}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Image Size :</span>
                          <span className="label">{this.props.details.image_size}</span>
                      </div>
                    
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Date :</span>
                          <span className="label">{this.props.details.uploaded_on}</span>
                      </div>
                            </div>
                            <div className="col-5 right">
                            <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Orientation :</span>
                          <span className="label">{this.props.details.orientation}</span>
                      </div>
                       <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">People :</span>
                          <span className="label">{this.props.details.People}</span>
                      </div>
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Gender :</span>
                          <span className="label">{this.props.details.gender}</span>
                      </div>
                      {/* <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Form :</span>
                          <span className="label">{this.props.details.form}</span>
                      </div> */}
                      {/* <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                          <span className="label">Size :</span>
                          <span className="label">{this.props.details.category}</span>
                      </div> */}
                      <div className="items-m1x" style={{flexDirection:'row',display:'flex',justifyContent:'space-between',alignItems:"center"}}>
                      
                      <span className="label">Rating :</span>
                      <div>
                            <Rating
                              readonly
                              initialRating={this.props.details.avg_rating?this.props.details.avg_rating:0}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                        <span className="label">| {this.props.details.avg_rating?this.props.details.avg_rating:0}</span>
                        </div>  
                      </div>
                    



             



                            </div>

                            </div>


                            </div>
                            <div className="tab-pane fade show active" id="desc" role="tabpanel" aria-labelledby="desc-tab">
                            {this.props.details.description} 
                            </div>
                            <div className="tab-pane fade" id="terms" role="tabpanel" aria-labelledby="terms-tab">
                              Vestibulum a purus quis enim finibus volutpat. Nunc fermentum erat et dui aliquet efficitur. Praesent pretium lectus ac diam rhoncus, at pellentesque tellus egestas. Etiam consectetur mi a luctus egestas. Donec egestas leo ac lacinia rutrum. Pellentesque egestas maximus lacus ut porta. Aliquam erat volutpat. 
                            </div>
                            <div className="tab-pane fade ratingTab" id="rating" role="tabpanel" aria-labelledby="rating-tab">
                              <div className="row">
                              <div className="col-5 left">
                              <h4 className="text-center my-3">Customer Rating</h4>
                              <div style={{flexDirection:'column',justifyContent:'center',alignItems:"center",display:'flex'}}>
                              
                              <div className="trl-box">
                              <h4 className="label l-left">{this.props.details.avg_rating?this.props.details.avg_rating:0} out of 5</h4>
                            <Rating
                              readonly
                              initialRating={this.props.details.avg_rating?this.props.details.avg_rating:0}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                            </div>
                            </div>
                            </div>
                            <div className="col-6 right">
                       <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={5}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">OMG!</span>
                      </div>



                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={4}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Love it</span>
                      </div>

                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={3}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Like it</span>
                      </div>
                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={2}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">It's okay</span>
                      </div>
                      <div className="items-r1x" style={{flexDirection:'row',justifyContent:'space-between',alignItems:"center"}}>
                            <Rating
                              readonly
                              initialRating={1}
                              emptySymbol={<ion-icon name="star" style={{color:'#cccccc',fontSize:15}}></ion-icon>}
                              fullSymbol={<ion-icon name="star" style={{color:'#fec12d',fontSize:15}}></ion-icon>}
                            />
                          <span className="label">Not a Fan</span>
                      </div>



                            </div>

                            </div>
                            </div>
                          </div>
                        </div>
                      </div>{/* End Row */}
                      {/* Related Models */}
                      {this.props.related_items && this.props.related_items.length > 0 && (
                        <>
                         <div className="related-head mt-4 ml-4 pl-2">
                        <h3><i>Related Models</i></h3>
                      </div>
                      <div className="related-item">
                      {this.props.related_items.map((item)=>
                        <div className="item-wrap">
                        <div className="card prod-item">
                          {/* <Link exact to={{
                              pathname: `/productDetails/${'graphics'}/${item.id}`,
                              // search: '?id=modus-create',
                              state: { id: item.id,product_type:'graphics' }
                            }} activeClassName="active" className="link">
                            <img src={`http://165.22.212.233:8002${item.thumbnail_image}`} className="card-img-top" alt="" />
                          </Link> */}
                          
                           <a href={`/productDetails/graphics/${item.id}`}  activeClassName="active" className="link">
                            <img src={BASE_URL.slice(0, -1)+`${item.thumbnail_image}`} className="card-img-top" alt="" />
                          </a>
                          <img src="images/badge.png" className="pbadge" alt="" />
                          <div className="card-body">
                            <h5 className="card-title">{item.graphics_name.toUpperCase()}</h5>
                            <p className="card-text">{item.avg_rating}
                             <span style={{marginLeft:8}}><Rating
                   readonly
                   initialRating={item.avg_rating?item.avg_rating:0}
                   emptySymbol={<i className="fa fa-star " style={{color:'gray',fontSize:10}}></i>}
                   fullSymbol={<i className="fa fa-star checked" style={{fontSize:10}}></i>}
                    /></span>
                              {/* <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" />
                              <span className="fa fa-star checked" /> */}
                            </p>
                          </div>
                        </div>
                      </div>
                      )}

                  
                    
                      </div>{/* End Related Item */}
                        </>
                      )}
                     
                     
                    </div>
                  </section> 
                  </>
                     );
                }else{
                  return null;
                }
    }
}

const mapStateToProps = (state) => {
  // console.log(state.cartDetails.data);  
	return {
		cartDetails: state.cartDetails.data,
    bookmarkItems:state.cartDetails.bookmark_items,
   
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: (token,id) => dispatch(cartDetails(token,id)),
    updateBookmarkItems: () => dispatch(bookmarkItems()),
	};
};

export default withRouter(withToast(connect(mapStateToProps, mapDispatchToProps)(ProductView)));