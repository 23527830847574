import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { cartDetails,bookmarkItems } from '../action';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { BASE_URL } from '../helper';

const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
class Favourites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      favourites:[],
     
     
    };
  }

componentDidMount(){
  this.getFavourites();
}
getFavourites(){
    const token = localStorage.getItem('omg_token');
    let id = localStorage.getItem('user_id');
    
    axios({
      method: 'post',
      url: BASE_URL+'api/product/favourites_list',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data:{ 
  
        "user_id":id,      
      }
    })
    .then( (response)=> {
      this.setState({favourites:response.data.favourites})
     
    //   console.log(response)
  
      
    })
    .catch((error)=> {
      console.log('er-downloads',error);
      // this.setState({error:true});
  
    });
}

addToCart=async(item)=>{
 
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');


  if(token === "" || token === null)
  {
    this.props.addToast(`Please Login To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');
  // this.props.history.push("/login")
  return Promise.reject();  
  }else{
   

    var cartData=[];
   if(item.product_type =='object'){
    cartData=[{
      "product_type":"object",
      "object":item.product.id,
      "material":"",
      "graphics":"",
      "user_id":id,
      "seller_id":"2",
      "unit_price":item.product.price,
      "quantity":"1"
    }]
   }else if( item.product_type =='material'){
    cartData=[{
      "product_type":"material",
      "object":"",
      "material":item.product.id,
      "graphics":"",
      "user_id":id,
      "seller_id":"2",
      "unit_price":item.product.price,
      "quantity":"1"
    }]
   }else{
    cartData=[{
      "product_type":"graphics",
      "object":"",
      "material":"",
      "graphics":item.product.id,
      "user_id":id,
      "seller_id":"2",
      "unit_price":item.product.price,
      "quantity":"1"
    }]
   }
// alert(JSON.stringify(this.state.cart_items));
    return await axios({
      method: 'post',
      url: BASE_URL+'api/cart/cartitem/',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data: {cart_items:cartData}
    })
    .then( (response)=> {
      if(item.product_type =='object'){
        this.props.addToast(`${item.product.object_name} added to cart`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }else if( item.product_type =='material'){
        this.props.addToast(`${item.product.material_name} added to cart`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }else {
        this.props.addToast(`${item.product.graphics_name} added to cart`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      this.props.updateCartDetails();
      this.getFavourites();
      return Promise.resolve(response);
     
      
    })
    .catch((error)=> {
      
      return Promise.reject(error);
     
    });
  }





}
  render() {

    return (
 <>
     
     <>
     {/*  Section */}
    
     <div id="favourite" className="col">
     {this.state.favourites.length > 0 && (
         <>
         <table cellPadding={0} cellSpacing={0} border={0} className="table">
                    <thead>
                      <tr>
                        <th colSpan={2}>Product Name</th>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Action</th>
                      </tr>
                    </thead>  
                    <tbody>
                    {this.state.favourites.map((val)=>
                     <tr>
                     {(val.product_type =='object') && (
                         <>
                          <td>
                          <Link to={{
  pathname: `/productDetails/${'object'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'object' }
 }}
 >  
                            <div className="product-img"><img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} alt=""  style={{width:150,height:100}}/></div>
                            </Link>

                            </td>
                         <td><span className="list-product-name">{val.product.object_name.toUpperCase()}</span></td>
                         </>
                     )}


{(val.product_type =='material') && (
                         <>
                          <td>
                          <Link to={{
  pathname: `/productDetails/${'material'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'material' }
 }}
 >  
                            <div className="product-img"><img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} alt=""  style={{width:150,height:100}}/></div>
                            </Link>

                            </td>
                         <td><span className="list-product-name">{val.product.material_name.toUpperCase()}</span></td>
                         </>
                     )}


{(val.product_type =='graphics') && (
                         <>
                          <td>
                          <Link to={{
  pathname: `/productDetails/${'graphics'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'graphics' }
 }}
 >  
                            <div className="product-img"><img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} alt=""  style={{width:150,height:100}}/></div>
                            </Link>

                            </td>
                         <td><span className="list-product-name">{val.product.graphics_name.toUpperCase()}</span></td>
                         </>
                     )}
                     <td>{val.product.uploaded_on}</td>
                     <td>{val.product_type}</td>
                     <td><a href="#" className="addcart-btn btn btn-default" onClick={()=>{this.addToCart(val)}}>Add to Cart</a></td>
                   </tr>
                    )}
                     
                        
                    </tbody>
                  </table>
                  <section className="pagination">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12 d-flex justify-content-between">
                          {/* Page no */}
                          <div className="mb-4 py-2 goto">Go to page <input className="binput" type="text" name defaultValue={1} size={1} /></div>
                          {/* Pagination */}
                          <nav aria-label="pagination">
                            <ul className="pagination" style={{marginTop: 0}}>
                              <li className="page-item">
                                <a className="page-link prev" href="#" aria-label="Previous">
                                  <span aria-hidden="true">&lt;</span>
                                  <span className="sr-only">Previous</span>
                                </a>
                              </li>
                              <li className="page-item active"><a className="page-link" href="#">1</a></li>
                              <li className="page-item "><a className="page-link" href="#">2</a></li>
                              <li className="page-item"><a className="page-link" href="#">3</a></li>
                              <li className="page-item">
                                <a className="page-link next" href="#" aria-label="Next">
                                  <span aria-hidden="true">&gt;</span>
                                  <span className="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </section>
         </>)}
                  
                </div>
      
     </>
     {this.state.favourites.length == 0 && (
         <>
         <div className="col">
        <p>No content found..</p>
         </div>
         </>
     )}
    
 </>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: () => dispatch(cartDetails()),
	};
};

export default withRouter(withToast(connect(null, mapDispatchToProps)(Favourites)));