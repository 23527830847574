import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {

  Link
} from "react-router-dom";
import InspireProductDetail from'./InspireProductDetail';
import { Modal,Button } from 'react-bootstrap';
import { BASE_URL } from '../helper';
import Login from './Login'
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import CheckOtp from './CheckOtp';

const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}




class Inspire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxDisplay:false,
      imagesToShow:"",
      cartItems:null,
      cartTotal:0,
      inspireDetails:[],
      top_inspire:[],
      detail:false,
      selected_item:{},
      showModal: false,
      formType:null,
    };
  }


  componentDidMount() {

    this.getImageInspire();   
  }



  getImageInspire(){
    axios({
      method: 'get',
      url: BASE_URL+`api/inspire/inspire_dashboard`,
      headers: { 'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" }, 
      data: ""
      
    }).then( (response)=> {
    
      // console.log(response.data,'Inspiredetails');
      if(response.data.response_code==200){
        const dashboard_details=response.data.dashboard;
        this.setState({inspireDetails:dashboard_details});
        const top_inspire=response.data.top_inspire;
        this.setState({top_inspire:top_inspire});
      }
     
      
    }).catch((error)=> {
      console.log('er',error);
    
    });
  }

  likeProduct = (item)=>{
 
    let id = localStorage.getItem('user_id');
    let token = localStorage.getItem('omg_token');
    console.log(token)
    if(token === "" || token === null)
    {
       this.props.addToast(`Please Login To Continue`, {
        appearance: 'error',
        autoDismiss: true,
      })
      this.handleOpenModal('Login');
    }else{
      axios({
        method: 'post',
        url: BASE_URL+`api/inspire/inspire_like`,
        headers: { 'Content-Type': 'application/json' },
        data:{ 
          
          "user":id,
          "inspire":item, 
        }
      })
      .then( (response)=> {

        // this.props.addToast(`Inspire liked successfully`, {
          // appearance: 'success',
        //   autoDismiss: true,
        // })

        this.getInspirations();
      //  alert(JSON.stringify(response))
      //  this.setState({bookmarks:response.data.bookmark_items})
        // this.setState({completed:true});
    
        
      })
      .catch((error)=> {
        console.log('er',error);
        this.setState({error:true});
    
      });
    }
    
  }
 

  hideDetail = ()=>{

    this.setState({detail:!this.state.detail,selected_item:{}});
  }

  handleCloseModal = ()=> {
    this.setState({ showModal: false });

  }

  handleOpenModal= (form)=> {
    this.setState({formType:form},()=>{this.setState({ showModal: true })});
  }
  
 


  render() {

    // console.log(this.state.inspireDetails,'inspireDetails');
    // console.log(this.state.top_inspire,'top_inspire');
    return (
 <>
     <Header/>
    


    {/* login modal */}
    <Modal className="BModal" show={this.state.showModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={()=>this.setState({showModal:false})}>

      <Modal.Body>
        {this.state.formType === "Login" &&
        <Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
        }

        {this.state.formType === "Signup" &&
        <Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
        } 
        {this.state.formType === "ForgotPassword" &&
        <ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
        }        
        {this.state.formType === "CheckOtp" &&
        <CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
        } 

      </Modal.Body>
  
    </Modal>

    {/* modal ends */}

      {this.state.detail && (
        <>
        <InspireProductDetail item={this.state.selected_item} hideDetail={this.hideDetail} likeProduct={()=>{this.likeProduct(this.state.selected_item.id)}}></InspireProductDetail >
        </>
      )}

     { this.state.lightboxDisplay ?
            
              <div id="lightbox" onClick={this.hideLightBox}>
    <div id="lightbox-wrap">
    <span onClick={this.props.hideLightBox} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
    <img id="lightbox-img" alt="box" src={this.state.imageToShow}></img>

    </div>
    
    
  </div>
: '' }
     <>
   
        {/* Inspire Section */}
        <section className="inspire-section" style={{}}>        
        <div className="container-fluid px-0 inner-page">
          <div className="row mx-0">
            <div className="col-sm-6">
              <div className="tab-container">
                <ul className="cl">
                  <li><Link exact to="/inspire_details" className="active" >Inspirations</Link></li>
                  <li><Link exact to={{
                    pathname:'/inspire_details',
                    state: { popular: 1, }
                  }} >Popular</Link></li>
                  <li>< Link exact to="/infotainment" >Infotainment</Link></li>
                </ul>
              </div>
              <div className="cover-img position-rel">
                {/* <img src="../assets/images/inspire/inspire-bg1.png" width="100%" alt="" /> */}
                <img   src={`${BASE_URL.slice(0, -1)}${this.state.inspireDetails.image}`} width="100%" alt="" />
                <img src="../assets/images/inspire/thecreative-txt.png" className="inspire-txt translate-bg" alt="" />
              </div>
            </div>
            <div className="col-sm-6">
              <img src="../assets/images/inspire/big-img.jpg" alt="" width="100%" />
              <ul className="img-gallery">

              {this.state.top_inspire.length > 0 && this.state.top_inspire.map((slider, index) => (
                  
                  <a className="link " style={{cursor:"pointer"}} onClick={()=>{this.setState({detail:true,selected_item:slider})}}>
                    <li style={{height:'160px'}}>
                      <img src={`${BASE_URL.slice(0, -1)}${slider.image}`} class="frame-image" alt="" width="100%" height="100%" key={index} />
                    </li>
                  </a>
                ))
              }
                {/* <li>
                  <img src="../assets/images/inspire/img-1.png" alt="" width="100%" />
                </li>
                <li>
                  <img src="../assets/images/inspire/img-2.png" alt="" width="100%" />
                </li>
                <li>
                  <img src="../assets/images/inspire/img-3.png" alt="" width="100%" />
                </li> */}

                <li>
               
                <Link to="/inspire_details" className="animated-arrow">
                 
                    <span className="the-arrow -left">
                      <span className="shaft" />
                    </span>
                    <span className="main">
                      <span className="text">
                        View All
                      </span>
                      <span className="the-arrow -right">
                        <span className="shaft" />
                      </span>
                    </span>
               
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div></section>
     </>
 
     <Footer/>
 </>
    );
  }
}
export default withRouter(withToast(Inspire));