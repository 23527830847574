import React, { Component } from 'react'
import { Modal,Button } from 'react-bootstrap';

import { BASE_URL } from '../helper';
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import Tooltip from 'react-bootstrap/Tooltip'
import {CopyToClipboard} from 'react-copy-to-clipboard';
      const axios = require('axios');
export default class InspireProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show_modal:true,
          selected_item:'',
          showSharePanel:false,
          copied:false

        };
        this.ToolTip = React.createRef();
      }
      componentDidMount(){
       
      }
   
   like=(e)=>{
    e.preventDefault();
    // alert("hii")
   this.props.likeProduct();
   this.setState({show_modal:false},()=>{
    this.props.hideDetail()
   });
   }
   showSharePanel=(e)=>{
    
    // console.log('ok')
    e.preventDefault();
    // alert("ok")
    this.setState({showSharePanel:!this.state.showSharePanel});
  
  }
    render() {
        return (
            <>
                 <Modal
     className="BModal inspireModal"
        show={this.state.show_modal}
        onHide={() => {this.setState({show_modal:false},()=>{
            this.props.hideDetail();
        })}}
centered
size="lg"
        aria-labelledby="contained-modal-title-vcenter"
       
      >
        {/* <Modal.Header closeButton> */}
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Custom Modal Styling
          </Modal.Title> */}
        {/* </Modal.Header> */}
        <Modal.Body >
       <div className="white-popup  insppopup" style={{margin:0}}>
       <span onClick={()=>{this.setState({show_modal:false},()=>{
           this.props.hideDetail();
       })}} className="close-button"><ion-icon name="close-outline"></ion-icon></span> 
       {/* <a className="closeButton" onClick={()=>{this.setState({show_modal:false},()=>{
           this.props.hideDetail();
       })}}><ion-icon name="close"></ion-icon></a> */}
       
        <div className="container inspire-detail p-0">
            
        <div className=" row">
            <div className="col">

            
              <img src={BASE_URL.slice(0, -1)+`${this.props.item.image}`} className="" style={{width:'100%'}} alt="" />
           
             
        <a href="" className={this.props.item.is_liked == 0 ? "linkButton ":"linkButton is_liked"} onClick={this.like}><svg  width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5825 6.39337H12.9014L14.0382 2.96783C14.2427 2.34926 14.1394 1.66355 13.7598 1.13451C13.3802 0.605469 12.7647 0.289062 12.1158 0.289062H10.484C10.1834 0.289062 9.8989 0.423357 9.70555 0.655321L4.94772 6.39337H2.38557C1.269 6.39337 0.360962 7.30596 0.360962 8.42813V17.5846C0.360962 18.7068 1.269 19.6194 2.38557 19.6194H15.8563C16.2682 19.618 16.67 19.491 17.0085 19.2552C17.3471 19.0194 17.6065 18.6859 17.7524 18.2988L20.5433 10.82C20.5857 10.7058 20.6073 10.5848 20.6071 10.4629V8.42813C20.6071 7.30596 19.699 6.39337 18.5825 6.39337ZM2.38557 8.42813H4.41018V17.5846H2.38557V8.42813ZM18.5825 10.2788L15.8563 17.5846H6.43479V7.77904L10.9578 2.32383H12.1179L10.5367 7.08824C10.4853 7.24117 10.471 7.40419 10.4949 7.56379C10.5188 7.72339 10.5802 7.87497 10.674 8.00596C10.7678 8.13695 10.8913 8.24358 11.0343 8.317C11.1774 8.39042 11.3357 8.42851 11.4963 8.42813H18.5825V10.2788Z" fill="black"/>
    </svg>
    </a>
         <a href="" className="linkButton lb2" onClick={this.showSharePanel}><svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5681 16.3864C17.7292 16.3864 16.9551 16.6807 16.3488 17.1722L10.2504 12.7603C10.3525 12.1998 10.3525 11.6254 10.2504 11.065L16.3488 6.65299C16.9551 7.14452 17.7292 7.43885 18.5681 7.43885C20.5165 7.43885 22.1 5.85537 22.1 3.90692C22.1 1.95848 20.5165 0.375 18.5681 0.375C16.6196 0.375 15.0361 1.95848 15.0361 3.90692C15.0361 4.24834 15.0832 4.57504 15.1745 4.88703L9.38212 9.08119C8.52268 7.94214 7.15701 7.20338 5.61768 7.20338C3.01583 7.20338 0.908447 9.31076 0.908447 11.9126C0.908447 14.5145 3.01583 16.6218 5.61768 16.6218C7.15701 16.6218 8.52268 15.8831 9.38212 14.744L15.1745 18.9382C15.0832 19.2502 15.0361 19.5798 15.0361 19.9183C15.0361 21.8667 16.6196 23.4502 18.5681 23.4502C20.5165 23.4502 22.1 21.8667 22.1 19.9183C22.1 17.9699 20.5165 16.3864 18.5681 16.3864ZM18.5681 2.37642C19.4128 2.37642 20.0986 3.0622 20.0986 3.90692C20.0986 4.75164 19.4128 5.43742 18.5681 5.43742C17.7233 5.43742 17.0376 4.75164 17.0376 3.90692C17.0376 3.0622 17.7233 2.37642 18.5681 2.37642ZM5.61768 14.5027C4.19019 14.5027 3.0276 13.3401 3.0276 11.9126C3.0276 10.4851 4.19019 9.32254 5.61768 9.32254C7.04516 9.32254 8.20775 10.4851 8.20775 11.9126C8.20775 13.3401 7.04516 14.5027 5.61768 14.5027ZM18.5681 21.4488C17.7233 21.4488 17.0376 20.763 17.0376 19.9183C17.0376 19.0736 17.7233 18.3878 18.5681 18.3878C19.4128 18.3878 20.0986 19.0736 20.0986 19.9183C20.0986 20.763 19.4128 21.4488 18.5681 21.4488Z" fill="black"/>
</svg>
</a>

<div class={this.state.showSharePanel ? "social-slide-in from-right  from-right-modal show":"social-slide-in from-right from-right-modal"}>
  <div class="slide-in-content share-icons">
    <ul>
      <li>  <TwitterShareButton
             openShareDialogOnClick={true}
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
          <i class="fab fa-twitter"></i>
          </TwitterShareButton></li>
      <li>  <FacebookShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
           <i class="fab fa-facebook-f"></i>
          </FacebookShareButton></li>
      <li>  <PinterestShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
         <i class="fab fa-pinterest-p"></i>
          </PinterestShareButton></li>
          <li>  <EmailShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
        <i class="far fa-envelope"></i>
          </EmailShareButton></li>
          <li> 
          <CopyToClipboard text={"this.state.value"}
          onCopy={() => this.setState({copied: true})}>

    
            <i class="far fa-copy" ref={this.ToolTip}></i>
         
     
        </CopyToClipboard>
        {/* {this.state.copied &&
        <span style={{backgroundColor:'green',padding:5,color:"#fff",alignSelf:'center'}}>Copied</span>
        } */}
          
          </li>
         
    </ul>
  </div>
</div>
          <h5 className="mt-3">{this.props.item.upload_by} </h5> 
          <h4>{this.props.item.title}</h4>
        <p>{this.props.item.description}</p>
          <h4 className="cl-orange">Elements used</h4>
          {this.props.item.elements_link.length > 0 && (
                            <>
                             <div className="tags">
                                {this.props.item.elements_link.map((x, i)=>{
                                  if(x.element_name && x.element_name != ""){
                                    return(
                                      
                                      <a href={x.element_link !=""?x.element_link:"#"} target="_blank"><div className="tag-item m-1">
   <div className="tag-item1">{x.element_name}</div>
  </div>
  </a>
  
                                    )
                                  }else{
                                    return null
                                  }
                                  
                                })}
                           </div>
                            </>
          )}
          {/* <h5 className="card-title">{this.props.item.title}</h5>
          <div className="desc">{this.props.item.description} </div>
          <div className="head1">Elements used</div> */}
                          {/* {this.props.item.elements_link.length > 0 && (
                            <>
                              <div className="tags">
                                {this.props.item.elements_link.map((x, i)=>{
                                  if(x.element_name && x.element_name != ""){
                                    return(
                                      
                                      <a href={x.element_link !=""?x.element_link:"#"} target="_blank"><div className="tag-item m-1">
   <div className="tag-item1">{x.element_name}</div>
  </div>
  </a>
  
                                    )
                                  }else{
                                    return null
                                  }
                                  
                                })}
                           </div>
                           
                          
                            </>
                          )} */}
        </div>

      </div>
      </div>
    
         
        </div>{/* End Container */}
       
 
      
        </Modal.Body>
      </Modal>
            </>
        )
    }
}
