import React, { Component } from 'react'
import { Modal,Button } from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import { BASE_URL } from '../helper';
const validationSchema = yup.object().shape({
      title:yup.string().required().label('Title'),
      description:yup.string().required().label('Description'),
    //   image: yup.mixed().required('Please upload a  image'),
      category:yup.string().required().label('Category'),
    //   phone_number:yup.number().required().label('Phone Number'),
    //   country:yup.string().required().label('Country'),
    //   city:yup.string().required().label('City'),
    //   zipcode:yup.number().required().label('Zip Code'),
   
      });
      const axios = require('axios');
export default class AddInfotainment extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show_modal:true,
          
          completed:false,
          error:false,
          categories:[],


        };
      }
      componentDidMount(){
        this.getCategories();
      }
      getCategories(){
        axios({
          method: 'get',
          url: BASE_URL+'api/inspire/infotainment_category',
          headers: { 'Content-Type': 'application/json' },
        })
        .then( (response)=> {
          // let assetData = response.data.assets;
          // assetData = assetData.filter((item) => item.product_type == "graphics").map(({ id, asset_type }) => ({ id, asset_type }));
          this.setState({categories:response.data.category});
            // alert(JSON.stringify(response));
          
        })
        .catch((error)=> {
          console.log(error)        
        });
      }
      addInfotainment(title,description,image,category,upload_link){
        const token = localStorage.getItem('omg_token');
        const user_id = localStorage.getItem('user_id');
        let name = localStorage.getItem('user_name');
        // let elements_link=["165.22.212.233:8002/api/product/material/1/"];

        // let link_array=[];
        // if(this.state.elements_link != null){
        //     for (const key of Object.keys(this.state.elements_link)) {
        //         link_array.push(this.state.elements_link[key]['link']);
        //     }
        //   }


         
         
        const formdata = new FormData();
        formdata.append('image',image);
        formdata.append('title', title);
        formdata.append('description', description);
        formdata.append('user', user_id);
        formdata.append('category',category);
        formdata.append('media_type',upload_link !=""?'video':'image');
        formdata.append('upload_link',upload_link );
        axios({
            method: 'post',
            url: BASE_URL+'api/inspire/infotainment/',
            headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}`  },
            data:formdata
          })
          .then( (response)=> {
            //   if(response){
            //     this.props.history.push('/profile');
            //   }
            this.setState({completed:true},()=>{
                setTimeout(
                    () => this.setState({ show_modal:false },()=>{
                        this.props.showAddInfotainment()  
                    }), 
                    2000
                  );
              })
           
            // this.setState({completed:true});
        
            
          })
          .catch((error)=> {
            console.log('er',error);
            this.setState({error:true});
        
          });

      }
    render() {
        return (
            <>
                 <Modal
     className="BModal "
        show={this.state.show_modal}
        onHide={() => {this.setState({show_modal:false},()=>{
            this.props.showAddInfotainment();
        })}}
centered
size="lg"
        aria-labelledby="contained-modal-title-vcenter"
       
      >
        {/* <Modal.Header closeButton> */}
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Custom Modal Styling
          </Modal.Title> */}
        {/* </Modal.Header> */}
        <Modal.Body >
       <div className="white-popup  insppopup" style={{margin:0}}>
       <span onClick={()=>{this.setState({show_modal:false},()=>{
            this.props.showAddInfotainment();
        })}} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
       {/* <a style={{top:0,right:0,position: "absolute",padding: 10,margin:2,color: "#e06526",cursor:'pointer'}} onClick={()=>{this.setState({show_modal:false},()=>{
            this.props.showAddInfotainment();
        })}}>X</a> */}
       
        <div className="container ">
         
          
          <Formik
      initialValues={{ title:'',description:'',image:'',category:'',upload_link:''}}
      onSubmit={(values, { setSubmitting, resetForm }) => {
      
      let{title,description,image,category,upload_link}=values;
        
        this.addInfotainment(title,description,image,category,upload_link);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
{(formikProps) => (
     <form  onSubmit={formikProps.handleSubmit}>
         <h2>Add New Infotainment</h2>

         <div className="row">
         <div className="col-md-6 col-sm-6">
         <div className="form-group">
                <label htmlFor="title">Title</label>
                <input type="text" className="form-control" id="title" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.title}  />
                  {formikProps.errors.title && formikProps.touched.title &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.title}</small>
              }
              </div>
             </div>
             <div className="col-md-6 col-sm-6">
         <div className="form-group">
                <label htmlFor="title">Category</label>
                <select className="form-control" name="category" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.category}>
              <option value="" selected disabled hidden>Select Category</option>
              {/* <option value="chair" >Chair</option>
              <option value="sofa" >Sofa</option>
              <option value="table" >Table</option> */}
              {this.state.categories.map((category)=>
               <option value={category.id}>{category.category}</option>
              )}
            
              
            </select>
            {formikProps.errors.category && formikProps.touched.category &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.category}</small>
        }
              </div>
             </div>
         </div>
         <div className="row">
           {formikProps.values.category==1 && (
             <>
               <div className="col-md-6 col-sm-6">
              
              
              <div className="form-group">
                <label htmlFor="image">Image</label>
                <input type="file" className="form-control" id="image" onChange={(event) => {
           formikProps.setFieldValue("image", event.currentTarget.files[0]);
           }} />
           {formikProps.errors.image && formikProps.touched.image &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.image}</small>
        }
              </div>
            </div>
             </>
           )}
          {/* {(formikProps.values.category==2) || (formikProps.values.category==1) && ( */}
            <>
             <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label htmlFor="links">Upload Link({(formikProps.values.category==2 ? "Upload media Link" : "Article Url")} )</label>
                
              
              
               
                <input type="text" className="form-control" id="upload_link" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.upload_link}  />
                  {formikProps.errors.upload_link && formikProps.touched.upload_link &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.upload_link}</small>
              }
              </div>
            </div>
            </>
          {/* )} */}
           
          </div>{/* End row */}
          <div className="row descrow">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label htmlFor="desc">Description</label>
                <textarea className="form-control" id="description" name="description" rows={6} defaultValue={""} onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.description}  />
                  {formikProps.errors.description && formikProps.touched.description &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.description}</small>
              }
              </div>
              <div className="note">
                Note: Loremipsum Loremipsum Loremipsum LoremipsumLoremipsum Loremipsum
                Loremipsum LoremipsumLoremipsum LoremipsumLoremipsum LoremipsumLoremipsum Loremipsum
              </div>
            </div>
          </div>{/* End row */}
         
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <button className="btsave">Done!</button>
              </div>
            </div>
          </div> 
         
     </form>
)}
    </Formik>
    
         
        </div>{/* End Container */}
        {this.state.completed &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-success" role="alert">
Created successfully.
</div>
</div>
}
{this.state.error &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-danger" role="alert">
Something went wrong. Please try again!
</div>
</div>
}  
        </div>
        </Modal.Body>
      </Modal>
            </>
        )
    }
}
