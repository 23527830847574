import axios from 'axios';
import { BASE_URL } from '../helper';
export function GetAssetList(){
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: BASE_URL+'api/product/assets',
            headers: { 'Content-Type': 'application/json' },
          })
          .then( (response)=> {
              resolve(response.data.assets);
            
          })
          .catch((error)=> {
            reject(error);         
          });
    });
}
export function GetCategories(){
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: BASE_URL+'api/product/filter_items',
            headers: { 'Content-Type': 'application/json' },
          })
          .then( (response)=> {
              resolve(response.data.category);
            
          })
          .catch((error)=> {
            reject(error);         
          });
    });
}
export function GetStyles(){
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: BASE_URL+'api/product/styles',
            headers: { 'Content-Type': 'application/json' },
          })
          .then( (response)=> {
              resolve(response.data.styles);
            
          })
          .catch((error)=> {
            reject(error);         
          });
    });
}
export function GetColors(){
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: BASE_URL+'api/product/colors',
            headers: { 'Content-Type': 'application/json' },
          })
          .then( (response)=> {
              resolve(response.data.colors);
            
          })
          .catch((error)=> {
            reject(error);         
          });
    });
}
export function GetForms(){
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: BASE_URL+'api/product/forms',
            headers: { 'Content-Type': 'application/json' },
          })
          .then( (response)=> {
              resolve(response.data.forms);
            
          })
          .catch((error)=> {
            reject(error);         
          });
    });
}