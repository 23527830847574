import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoader from '../widgets/Loader'
import { Formik } from "formik";
import * as yup from "yup";
import {

  Link
} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_URL } from '../helper';

const axios = require('axios');


const validationSchema = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().required().email().label('Email'),
  // password: yup.string().required().label('Password'),
  terms:yup.boolean().oneOf([true],'Please check box to proceed'),
  password: yup.string().required().label('Password').matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Must Contain 8 Characters, One Uppercase,(A-Z) One Lowercase(a-z), One Number(0-9) and one special case Character"
  ),
});

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed:false,
      error:false,
      captcha:false,
    };
  }
componentDidMount=()=>{

}
SignupApiCall=(first_name,last_name, email, password,usertype)=>{

    axios({
      method: 'post',
      url: BASE_URL+'api/accounts/register/',
      headers: { 'Content-Type': 'application/json' }, 
      // headers: {'Access-Control-Allow-Origin': "*",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json' }, 
      data: {
        first_name,last_name, email, password,usertype
      }
    })
    .then( (response)=> {
      console.log(response);
    
      this.setState({completed:true});

      
    })
    .catch((error)=> {
      console.log('er',error);
      this.setState({error:true});

    });
  }

  render() {
    return (
        <>
{false && <ReactLoader/>}
<div>
<section className="signin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-6 login-image1" />
            <div className="col-sm-12 col-xs-12 col-md-6 Modal-Overlay-Right">
              <div className="col-sm-12 col-md-8 ml-3 ">
              <Link to="/" className="logo-signin"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/signin2.png`} alt="logo" /></Link>
              </div>
              <Formik
      initialValues={{ firstName: '',lastName: '', email:'', password:'',usertype:"customer/designer",terms:false }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
      console.log(values);
      //  this.setState({loader:true});
        // setTimeout(() => {
        //   actions.setSubmitting(false);
        // }, 1000);
        let {firstName,lastName, email, password,usertype}=values;
        this.SignupApiCall(firstName,lastName, email, password,usertype);
        resetForm();

      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <>
             <form onSubmit={formikProps.handleSubmit}>
                <div className="signupform">
                 
                  <div className="form-group col-sm-12 col-md-8">
                  <h2>Sign Up</h2>
                  <p>Dream it! Design it! Deal it!</p>
                    <label htmlFor="uname">First Name</label>
                    <input type="text"
              
              
               name="firstName"
               onBlur={formikProps.handleBlur}
               onChange={formikProps.handleChange}
               value={formikProps.values.firstName} 
                className="form-control" id="uname" placeholder="Enter firstname" 
                
                 />
                  {formikProps.errors.firstName && formikProps.touched.firstName &&
           <small id="emailHelp" className="form-text text-warning">{formikProps.errors.firstName}</small>
          }
                  </div>
                  <div className="form-group col-sm-12 col-md-8">
                  
                    <label htmlFor="uname">Last Name</label>
                    <input type="text"
              
              
               name="lastName"
               onBlur={formikProps.handleBlur}
               onChange={formikProps.handleChange}
               value={formikProps.values.lastName} 
                className="form-control" id="lastname" placeholder="Enter lastname" 
                
                 />
                  {formikProps.errors.lastName && formikProps.touched.lastName &&
           <small id="emailHelp" className="form-text text-warning">{formikProps.errors.lastName}</small>
          }
                  </div>
                  <div className="form-group col-sm-12 col-md-8">
                    <label htmlFor="uname">Email</label>
                    <input  type="email"
               id="email"
               name="email"
               onBlur={formikProps.handleBlur}
               onChange={formikProps.handleChange}
               value={formikProps.values.email}
               className="form-control" 
                placeholder="Enter email"  />
                 {formikProps.errors.email && formikProps.touched.email &&
           <small id="emailHelp" className="form-text text-warning">{formikProps.errors.email}</small>
          }
                  </div>
                  <div className="form-group col-sm-12 col-md-8">
                    <label htmlFor="pwd">Create Password</label>
                    <input  type="password"
               name="password"
               onBlur={formikProps.handleBlur}
               onChange={formikProps.handleChange}
               value={formikProps.values.password} 
                className="form-control" id="pwd" placeholder="Enter password"  />
                 {formikProps.errors.password && formikProps.touched.password &&
           <small id="emailHelp" className="form-text text-warning">{formikProps.errors.password}</small>
          }
                  </div>
                  <div className="form-group col-sm-12 col-md-8">
                    <label className="form-check-label">
                      <input type="checkbox" name="terms" 
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.terms} 
                    
                    /> I agree to 3D OMG's <a href="#">Terms and conditions</a></label>
                     {formikProps.errors.terms && formikProps.touched.terms &&
           <small id="emailHelp" className="form-text text-warning">{formikProps.errors.terms}</small>
                     }
                  </div>
                  <ReCAPTCHA
    sitekey="6LcvLskcAAAAAIVhEQWuJ2rJb4dY3rC7cUzRxCCL"
    onChange={() => {this.setState({captcha:true})}}
    onExpired={() => {this.setState({captcha:false})}}
    
  />
                  <div className="col-sm-12 col-md-8">
                    <button type="submit"  className="form-control btn">Create Account</button>
                  </div>
                  <p className="mt-3">Already have an account ? <a href="#" onClick={()=>this.props.handleOpenModal('Login')} className="logo-signin" style={{color:"#000"}}><b>SIGN IN</b></a></p>
{this.state.completed &&
 <div className="col-sm-12 col-md-8 mt-3">
 <div className="alert alert-success" role="alert">
 Registration is successful. Please <a href="#" onClick={()=>this.props.handleOpenModal('Login')}  className="" style={{color:"rgb(224 101 38)",fontWeight:"bold"}}>Login</a>
 </div>
</div>
}
{this.state.error &&
 <div className="col-sm-12 col-md-8 mt-3">
 <div className="alert alert-danger" role="alert">
 Something went wrong. Please try again!
 </div>
</div>
}                 

                </div>
              </form>
              </>
        )}
    </Formik>
            </div>
          </div>
        </div>
      </section>
      </div>
      </>
    );
}
}

Signup.propTypes = {
    
};
