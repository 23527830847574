import { render } from '@testing-library/react';
import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import { cartDetails,bookmarkItems,setSearchKey,setSearchUrl } from '../action';
import Login from '../pages/Login'
// import Modal from 'react-modal';
import { Modal,Button } from 'react-bootstrap';
import Signup from '../pages/Signup';
import Slider from "react-slick";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import ForgotPassword from '../pages/ForgotPassword'
import { BASE_URL } from '../helper';
import CheckOtp from '../pages/CheckOtp';

const axios = require('axios');
// const customStyles = {
//   content: {
//     top: '5%',
//     left: '10%',
//     right: '10%',
//     bottom: '10%',

//   },
// };
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
var search_url='';
// Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.6';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      name:"Lorem Ipsum",
      showModal: false,
      formType:null,
      bookmarks:[],
      cartData:[],
      product_ids:[],
      user_id:null,
      showModalButton:false,
      showBookmarkModal:false,
      bookmarkItems:[],
      selected_option:'/objects',
    };
    
    this.myRef = React.createRef();
    this.inputRef= React.createRef();
    this.handleOptionClick=this.handleOptionClick.bind(this);
    this.searchOptionHandler=this.searchOptionHandler.bind(this);
    // this.bookmarkButtonRef = React.createRef();
  }

  componentDidMount(){
    const self = this;
    window.scrollTo(0, 0);
      const token = localStorage.getItem('omg_token');
      let user_id;
      let user_name;
      let name;
      if(token){
          user_id = localStorage.getItem('user_id');
          user_name=localStorage.getItem('user_name');
          user_name=JSON.parse(user_name);
          name = user_name.first_name+" "+user_name.last_name;
          // let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
          // let acronym = matches.join(''); 
          name = Array.prototype.map.call(name.split(" "), function(x){ return x.substring(0,1).toUpperCase();}).join('');
          
        
      }

      if(token){
        this.props.updateCartDetails();
        this.props.updateBookmarkItems();
      }
      
      this.setState({token,name,user_id},()=>{
       if( this.props.bookmarkItems.length > 0) {
        // this.bookmarkButtonRef.current.classList.add('show-bookmark-animation')
        // this.showModal();
        // this.myRef.current.classList.add('show');
        // this.bookmarkButtonRef.current.classList.add('show-bookmark-animation');
       }
        
      });
      this.$el = window.jQuery(this.el);
      // alert(self.props.search.url);
    // this.$el.somePlugin();
    this.$el.select2({
      dropdownCssClass: "custom-dropdown",
      val:"/graphics",
     
      
   }).on('change', function(e) {
    //  self.setState({search_url:'dddd'});
    // search_url=e.target.value;
    // this.el.onChange();
     self.props.setSearchUrl(e.target.value);
     self.setState({selected_option:e.target.value});
    // this.props.setSearchUrl("test");
    //  alert(e.target.value)
   });
   
   
      // this.showModal()
  }
  handleInputChange = (e) =>{
    this.props.setSearchKey(e.target.value);
   
    
  }
  changeHeaderSearch=(event)=>{
    alert("hii")
  }
  showModal=()=>{
    // this.myRef.current.classList.add('show')
    this.setState({showBookmarkModal:true})
    this.setState({showModalButton:false})
    this.forceUpdate()
    // this.bookmarkButtonRef.current.classList.remove('show-bookmark-animation')
  }
  hideModal=()=>{

    this.setState({showBookmarkModal:false})
    this.setState({showModalButton:true})
    try{
      this.props.hideBookmark();
    }

    catch(e){

    }
    
    
  }

  deleteBookmarks=async(productID)=>{
    console.log("done")
    let id = localStorage.getItem('user_id');
    let token = localStorage.getItem('omg_token');
    console.log(token)
    if(token === "" || token === null)
    {
      this.props.addToast(`Please Login To Continue`, {
        appearance: 'error',
        autoDismiss: true,
      })
      this.handleOpenModal('Login');
  
    // this.props.history.push("/login")
    return Promise.reject();  
    }else{
      return await axios({
        method: 'delete',
        url: `${BASE_URL}api/cart/bookmark/${productID}/`,
        headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
       
      })
      .then( (response)=> {
         this.props.addToast(`Item  removed from bookmarks`, {
        appearance: 'success',
        autoDismiss: true,
      })
    
       console.log(response)
       this.props.updateBookmarkItems().then((res)=>{
        if(this.props.bookmarkItems.length == 0 ){
      this.hideModal();
     }
     
      // this.hideModal();
      return Promise.resolve(response);
     }).catch((err)=>{
      return Promise.resolve(response);
     });
      
        
      })
      .catch((error)=> {
        
        return Promise.reject(error);
       
      });
    }
  
  
  }
  setProductCheck(productid){
    const found = this.state.cartData.some(el => el.product_id === productid);
    if(found){
      return true;
    }else{
      return false;
    }
  }
onLogout=()=>{
    localStorage.removeItem('omg_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_name');
    this.props.updateCartDetails();
    this.props.updateBookmarkItems();
    // const current = this.props.location.pathname;
    this.props.history.replace(`/reload`);
       setTimeout(() => {
         this.props.history.replace('/');
       });
    // this.props.history.push("/")

    }

    handleOpenModal= (form)=> {
      this.setState({formType:form},()=>{this.setState({ showModal: true })});
    }
    
    handleCloseModal = ()=> {
      this.setState({ showModal: false });
    }
    
    handleCheckbox=(val)=>{

      const found = this.state.cartData.some(el => el.product_id === val.product.id);
    if (!found){
      this.setState({cartData:[...this.state.cartData,
        {
          product_type:val.product_type,
          object:(val.product_type =='object')?val.product.id:'',
          material:(val.product_type =='material')?val.product.id:'',
          graphics:(val.product_type =='graphics')?val.product.id:'',
          user_id:this.state.user_id,
          unit_price:val.product.price,
          seller_id:"2",
          "quantity":"1",
          product_id:val.product.id
        }
      ]});
    }else{
      const filteredCartdata = this.state.cartData.filter(item => item.product_id !== val.product.id);
    this.setState({
      cartData:filteredCartdata
    });  
    }


      


      
      // alert(JSON.stringify(val));
    }
    addToCart=()=>{
      if(this.state.cartData.length > 0){
         let id = localStorage.getItem('user_id');
      let token = localStorage.getItem('omg_token');
      if(token === "" || token === null)
      {
        this.props.addToast(`Please Login To Continue`, {
          appearance: 'error',
          autoDismiss: true,
        })
        this.handleOpenModal('Login');
      // this.props.history.push("/login")
     
      }else{
        axios({
              method: 'post',
              url: BASE_URL+'api/cart/cartitem/',
              headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
        
              data: {cart_items:this.state.cartData}
              
            })
            .then( (response)=> {
               this.props.addToast(`${this.state.cartData.length} items added to cart`, {
              appearance: 'success',
              autoDismiss: true,
            })

          this.setState({cartData:[]},()=>{
            this.props.updateCartDetails();
            this.props.updateBookmarkItems().then((res)=>{
              if(this.props.bookmarkItems.length == 0 ){
                this.hideModal();
               }
            });
          })
              
              
             
              
            })
            .catch((error)=> {
              
             console.log(error);
             
            });
      }
      }
     
    
    }

    getState=()=> {
      return this.state;
    }

    enterKeyPressed=(event)=>{
      // console.log(event.keyCode)
      if (event.keyCode == 13) {
        this.handleOptionClick();
      }
    }

    handleOptionClick = () => {

      if(this.props.search.url !=this.props.url &&  this.props.search.url !="" && this.props.search.url !="#"){
        this.props.history.push({
          pathname: this.props.search.url,
          state: { key: this.props.search.key,url: this.props.search.url}
      });
      }else{
        
        this.props.getProducts();
      }


//  alert("hii");
    //   this.props.history.push({
    //     pathname: this.props.search.url,
    //     state: { key: this.props.search.key,url: this.props.search.url}
    // });
   
    
  };
  searchOptionHandler=(event)=>{
    // alert("hii")
    alert(event.target.value)
    // this.props.setSearchUrl(event.target.value);
   
  }

//     shouldComponentUpdate(nextProps,nextState){
     
//   if(nextProps.bookmarkItems !== null){
//   console.log(nextProps.bookmarkItems)
//    console.log(this.props.bookmarkItems)
//   if (nextProps.bookmarkItems !== this.state.bookmarkItems) {
//     this.setState({bookmarkItems:nextProps.bookmarkItems})
      
//   }
// }
//     return true;
//   }
triggerInputFile = () => this.planSection.current.scrollIntoView();
  render(){
    const { inputRef } = this.props;
    // console.log(this.state.bookmarkItems)
    const settings = {
      dots: true,
     
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll:1,
      arrows:true,
      // centerMode: true,
    //  centerPadding: '0',
    //  adaptiveHeight: true

    };
    return (
  <div>
     
     <div onClick={()=>this.showModal()}
      // ref={this.bookmarkButtonRef} 
      className={this.props.bookmarkItems.length !== 0?"show-bookmark-animation show-bookmark":"show-bookmark"} >
        <div className="txt">Show Bookmarks</div>
          {/* <span className="show-bookmark-close" ><img className="close" src={`${process.env.PUBLIC_URL}/assets/images/cross.png`} /></span> */}
      </div>

      
     <div id="modalBottom"  class={this.state.showBookmarkModal?"modal show":"modal"} 
      // ref={this.myRef}
      ref={inputRef}
      tabindex="-1" aria-labelledby="true" style={{paddingRight:15,display:"block",visibility:'hidden'}} aria-modal="true"  role="dialog">
        <div onClick={()=>this.hideModal()} className="bookmark-overlay"></div>
        
        <div className="modal-dialog w-100 mw-100 modal-custom modal-bottom">
      
          <div className="modal-content w-100 h-100 border-0 rounded-0">
            {/* <div className="modal-header">
           
              <h2> <img src={`${process.env.PUBLIC_URL}/assets/images/v5.png`}  className="headimg" /> Bookmarks</h2>
              </div> */}
              <div className="modal-body">
              <div className="bookmark-button-top">
     
            <a onClick={()=>this.hideModal()}>Hide bookmarks</a>
          </div>
             
      <div className="">
     
        <div className="bm">
          <div className="head">
            <div className="hw1">
              
              <h2><img src={`${process.env.PUBLIC_URL}/assets/images/v5.png`}  className="headimg" style={{marginRight:10}} /> Bookmarks</h2>
              
            </div>
            <div className="hw2 row">
            <div className="col">
              <div >
               
              <div className="b13 bm-counter">{this.props.bookmarkItems.length}/20</div>
               
              </div>
              </div>
              <div className="col" >
              <div className="cbtn" onClick={()=>{this.addToCart()}} style={{width:120}}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/crtsm.png`}  className="crt" height="18.4px" />
                <div className="crtxt">Add to Cart</div>
               
              </div>
              </div>
           
            </div>
          </div>
          <div className="bookmark-wrap ">
        
         {this.props.bookmarkItems.length !== 0  &&
  <Slider {...settings}>
{
  this.props.bookmarkItems.map((val)=>(
    <div className="w1 wup">
    <div className="bkimgw">
      <div className="b1 ">
        <div className="b11"><input type="checkbox" checked={this.setProductCheck(val.product.id)} onClick={()=>{this.handleCheckbox(val)}}/></div>
        <div className="b12">
        <span onClick={()=>{this.deleteBookmarks(val.id)}} className="bookmark-delete"><ion-icon name="trash-outline"></ion-icon></span>
         
          {(val.product_type == 'object') && (
            <>
            <Link to={{
  pathname: `/productDetails/${'object'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'object' }
 }}
 >
             <img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} width="100%"  className={this.setProductCheck(val.product.id)?"bookmark-image-border":""}/>
             </Link>
             <div className="b13"><p style={{ textTransform: 'uppercase'}}>{val.product.object_name}</p></div>
             
            </>
          )}
          {(val.product_type == 'material') && (
            <>
             <Link to={{
  pathname: `/productDetails/${'material'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'material' }
 }}
 >
             <img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} width="100%"  className={this.setProductCheck(val.product.id)?"bookmark-image-border":""}/> </Link>
             <div className="b13"><p style={{ textTransform: 'uppercase'}}>{val.product.material_name}</p></div>
            
            </>
          )}
          {(val.product_type == 'graphics') && (
            <>
             <Link to={{
  pathname: `/productDetails/${'graphics'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'graphics' }
 }}
 >
             <img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} width="100%"  className={this.setProductCheck(val.product.id)?"bookmark-image-border":""}/> </Link>
             <div className="b13"><p style={{ textTransform: 'uppercase'}}>{val.product.graphics_name}</p></div>
            
            </>
          )}
        </div>
      </div>
    </div>
  </div>
  ))
}
</Slider>
}
        
          </div>
        </div>
      </div>

                </div>
                </div>
                </div>
                </div>







         {/* <Modal 
       
        className="Modal-Style"
           overlayClassName="Modal-Overlay"
           isOpen={this.state.showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={this.handleCloseModal}
           shouldCloseOnOverlayClick={true}
        >
{this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
} 
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}        


        </Modal>  */}
       {/* Navigation Section */}
       <nav className="navbar navbar-expand-md">
        <a className="navbar-brand" href="/">
        <img className="logo_d" src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} width="135" height="" alt="Logo" />
        <img className="logo_m" src={`${process.env.PUBLIC_URL}/assets/images/logo_m.png`} width="135" height="84" alt="Logo" />
        </a>
        <button className="navbar-toggler navbar-dark" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
            <li><div className="searchbox">
                <div className="form-group has-search">
                  <select ref={el => this.el = el} id="header-search">
                  <option value="/objects" selected={this.props.url=="/objects"?true:false}>Objects</option>
                    <option value="/materials" selected={this.props.url=="/materials"?true:false}> Materials</option>
                    <option value="/graphics" selected={this.props.url=="/graphics"?true:false} >Graphics</option>
                  </select>
                  <input 
                    type="text" 
                    className="form-control search" 
                    placeholder="Get Casting..." 
                    value={this.props.search.key} 
                    onChange={this.handleInputChange}
                    onKeyDown={(event)=>{this.enterKeyPressed(event)}}
                   />
                  <button onClick={()=>this.handleOptionClick()}><span className="fa fa-search form-control-feedback" /></button>
                </div>
              </div></li>
              
              <li className="navbar-item"><NavLink exact to="/" activeClassName="active" className="nav-link">Home</NavLink></li>
          <li className="navbar-item"><NavLink exact to="/objects" activeClassName="active" className="nav-link">Objects</NavLink></li>
          <li className="navbar-item"><NavLink exact to="/materials" activeClassName="active" className="nav-link">Materials</NavLink></li>
          <li className="navbar-item"><NavLink exact to="/graphics" activeClassName="active" className="nav-link">Graphics</NavLink></li>
          <li className="navbar-item"><NavLink exact to="/inspire" activeClassName="active" className="nav-link">Inspire</NavLink></li>
          
          <li className="navbar-item"> <NavHashLink
          smooth={true}
  to="/#section6"
  activeClassName="active" className="nav-link"
  // activeStyle={{ color: 'red' }}
  // etc...
>Plans</NavHashLink>
</li> 


          {/* <li className="navbar-item"><a className="nav-link" href="" onClick={(e)=>{e.preventDefault();
          this.props.history.push({pathname: '/',
          search: '?query=plans',
          state: { plans: true }});
          if(this.props.scrollToPlans != null || this.props.scrollToPlans !== undefined)
            this.props.scrollToPlans();
            this.props.history.push('graphics');
            }}>Plans</a></li> */}
          {/* <li className="navbar-item" href='#'><a className="nav-link" onClick={()=>this.props.fun()}>Plans</a></li> */}

          {(!this.state.token) &&
            <>
            {/* <button onClick={this.handleOpenModal}>Trigger Modal</button> */}
             {/* <li className="navbar-item"><a className="nav-link modal-click" href="#signin">Sign in</a></li>
            <li className="navbar-item signupbtn"><a className="nav-link modal-click" href="#signup">Sign Up</a></li> */}
            <li className="navbar-item"><a href="#" onClick={(e)=>{e.preventDefault();this.handleOpenModal('Login');}} className="nav-link" >Sign in</a></li>
            <li className="navbar-item"><a href="#" onClick={(e)=>{e.preventDefault();this.handleOpenModal('Signup');}} className="nav-link signup" >Sign Up</a></li>
          {/* <li className="navbar-item"><NavLink exact to="/login" activeClassName="active" className="nav-link">Sign in</NavLink></li> */}
          {/* <li className="navbar-item"><NavLink exact to="/signup" activeClassName="active" className="nav-link signup">Sign Up</NavLink></li> */}
          </>
          }
          {(this.state.token) &&
            <>
           <li className="navbar-item pr-0">
             <div className="d-flex justify-content-center align-items-center">
           <img className="logo_d1" src={`${process.env.PUBLIC_URL}/assets/images/user-icon1.png`} style={{width:40,borderRadius:20,marginLeft:10,marginRight:5,borderWidth:0,borderColor:'#f04909',borderStyle:'solid'}} alt="Logo" />
           </div> 
           </li>
        <li className="navbar-item">< Link exact to="/profile" className="nav-link" href="#" style={{marginRight:5}}>{this.state.name}</Link></li>
        <li className="navbar-item"><Link exact onClick={()=>this.onLogout()}  className="nav-link" style={{marginRight:5}}>Logout</Link></li>
        <li className="navbar-item"><Link exact to="/create"  className="nav-link signup">Upload</Link></li>
          </>
          }

          <li className="navbar-item cart-icon-wrap">
            <Link className="nav-link" to="/cart">
              <span className="cart-icon"><i className="fa fa-shopping-cart" aria-hidden="true" /></span>
              <span className="cart-count">{this.props.cartDetails.length  !== 0?this.props.cartDetails.length:0}</span>
            </Link>
          </li>
           
            {/* <li className="navbar-item">
              <a className="nav-link" href="#">
                <span><img src="images/v2.png" alt="" width="25px" /></span>
              </a>
            </li> */}
          </ul>
        </div>
      </nav>

 {/* SIGNIN */}
 <div id="signin" className="mfp-hide white-popup-block">
          <section className="signin">
            <button title="Close (Esc)" type="button" className="mfp-close">×</button>
            <div className="container-fluid">
              <div className="row ">
                <div className="col-sm-12 col-xs-12 col-md-6 px-0">
                  <img src="../assets/images/signin.png" width="100%" height="100%" />
                </div>
                <div className="col-sm-12 col-xs-12 col-md-6 pad1">
                  <div className="col-sm-12 col-md-8 ml-3">
                    <a href="#" className="logo-signin"><img className="img-fluid" src="../assets/images/signin2.png" alt="logo" /></a>
                  </div>
                  <form action="#">
                    <div className="signupform">
                      <div className="col-sm-12 col-md-8">
                        <h2>Sign In</h2>
                        <small>Welcome back! We missed you!</small>
                      </div>
                      <div className="form-group col-sm-12 col-md-8">
                        <label htmlFor="uname">Email</label>
                        <input type="text" className="form-control" id="uname" placeholder name="uname" required />
                      </div>
                      <div className="form-group col-sm-12 col-md-8">
                        <label htmlFor="pwd">Password</label>
                        <input type="password" className="form-control" id="pwd" placeholder name="pswd" required />
                      </div>
                      <div className="form-group form-forgot col-sm-12 col-md-8">
                        <a className="form-forgot-text forgetpwd modal-click" href="#forgotpass">Forgot Password ?</a>
                      </div>
                      <div className="col-sm-12 col-md-8">
                        <button type="submit" className="form-control btn">Sign in</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="signup" className="mfp-hide white-popup-block">
          <section className="signin">
            <button title="Close (Esc)" type="button" className="mfp-close">×</button>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-xs-12 col-md-6 px-0">
                  <img src="../assets/images/signup1.png" width="100%" height="100%" />
                </div>
                <div className="col-sm-12 col-xs-12 col-md-6 pb-4">
                  <div className="col-sm-12 col-md-8 ml-3">
                    <a href="#" className="logo-signin"><img className="img-fluid" src="../assets/images/signin2.png" alt="logo" /></a>
                  </div>
                  <form action="#">
                    <div className="signupform">
                      <div className="col-sm-12 col-md-8">
                        <h2>Sign Up</h2>
                        <small>Dream it! Design it! Deal it!</small>
                      </div>
                      <div className="form-group col-sm-12 col-md-8">
                        <label htmlFor="uname">Full Name</label>
                        <input type="text" className="form-control" id="uname" placeholder name="uname" required />
                      </div>
                      <div className="form-group col-sm-12 col-md-8">
                        <label htmlFor="uname">Email</label>
                        <input type="text" className="form-control" id="uname" placeholder name="uname" required />
                      </div>
                      <div className="form-group col-sm-12 col-md-8">
                        <label htmlFor="pwd">Create Password</label>
                        <input type="password" className="form-control" id="pwd" placeholder name="pswd" required />
                        <small className="hint">Your password should contain at least 8 Characters &amp; 1 Special Character!</small>
                      </div>
                      <div className="form-group col-sm-12 col-md-8 d-flex flex-nowrap flex-row align-items-start">
                        <label className="form-check-label px-0 py-1"><input type="checkbox" /></label>
                        <span className="prvcpolcy">I have read,understood, and agree to 3D OMG privacy policy and Terms &amp; Conditions</span>
                      </div>
                      <div className="col-sm-12 col-md-8">
                        <button type="submit" className="form-control btn">Create Account</button>
                      </div>
                      <div className="col-sm-12 col-md-8 sinuplink">
                        <span className="sublink-text">Already have an account ? </span><span className="modal-click" href="#signin" style={{cursor: 'pointer'}}>SIGN IN</span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>

        <>


<Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>
  {/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
  </Modal.Header> */}
  <Modal.Body>
    
  <span onClick={()=>this.setState({showModal:false})} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
 
  {this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
} 
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}  
{this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}   
    
    
  </Modal.Body>
  {/* <Modal.Footer>
    <Button variant="secondary" onClick={()=>this.setState({showBModal:false})}>
      Close
    </Button>
    <Button variant="primary" onClick={()=>this.setState({showBModal:false})}>
      Save Changes
    </Button>
  </Modal.Footer> */}
</Modal>
</>    
    </div>
    );
}
}

const mapStateToProps = (state) => {
  console.log("reduxstate",state);  
	return {
		cartDetails: state.cartDetails.data,
    bookmarkItems:state.cartDetails.bookmark_items,
    search:{key:state.cartDetails.search_key,url:state.cartDetails.search_url}
   
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: () => dispatch(cartDetails()),
    updateBookmarkItems: () => dispatch(bookmarkItems()),
    setSearchKey: data => dispatch(setSearchKey(data)),
    setSearchUrl: data => dispatch(setSearchUrl(data)),
	};
};

export default withRouter(withToast(connect(mapStateToProps, mapDispatchToProps)(Header)));


