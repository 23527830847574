import React, { Component } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Iv2hrJQ9plTptrEYixmHjNjsSfq2k7awLs9HXzUsPRBeIGSHRB2Mqtone5igfAH3OZA65eSZQYWQqtj5vCm1Fmx00F1Ni57DG');

const options = {
  // passing the client secret obtained from the server
  clientSecret: 'sk_test_51Iv2hrJQ9plTptrE46ztbZUXSQo5Cm16lqMZGmSISc0poxUWPBVFW3kkqugdCjo4oPBkcigZMVBO4S4xA9eUl1f100APO4POH6',
};

// var PaymentStripe = function App() {
class PaymentStripe extends Component {
 
  render() {
    alert();
   return (
    <Elements stripe={stripePromise} options={options}>
       <form>
      <PaymentElement />
      <button>Submit</button>
      </form>
    </Elements>
  );
   }
};

export default PaymentStripe;
