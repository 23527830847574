// CheckOtp

import React, { Component } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import {

  Link
} from "react-router-dom";
import { BASE_URL } from '../helper';

const axios = require('axios');

const validationSchema = yup.object().shape({
  otp: yup.string().required().label('OTP'),
  password:yup.string().required().label('Password'),
  // lastName: yup.string().required().label('Last Name'),
  // email: yup.string().required().email().label('Email'),
//   password: yup.string().required().label('Password')
  // password: yup.string().required().label('Password').matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase,(A-Z) One Lowercase(a-z), One Number(0-9) and one special case Character"
  // ),
});
class CheckOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
          otpModal:false,
          errorMessage:'',
          successMessage:'',
        };
      }
    componentDidMount=()=>{
        console.log(this.props);
    
    }
    SignupApiCall=(otp,password)=>{
// alert();
        const email=localStorage.getItem('omg_forgotemail');
        // console.log(email);
        // return;
       
          axios({
            method: 'post',
            url: BASE_URL+'api/accounts/resetpassword',
            headers: { "Access-Control-Allow-Origin": "*",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json' }, 
            data: {
              email,otp,password
            }
          })
          .then( (response)=> {
            console.log(response);
            // this.setState({loader:false});
            // this.setState({errorMessage:""});
            if(response.data.response_code == 101){
            //   this.setState({'otpModal':true});
            //   this.props.handleOpenModal('CheckOtp')
                this.setState({errorMessage:"Something went wrong"});
            }else if(response.data.response_code == 200){
                this.setState({successMessage:"Something went wrong"});
            }
            //   this.setState({registrationCompleted:true});
            // }else{
            //   this.setState({errorMessage:"Something went wrong"});
            // }
            
          })
          .catch((error)=> {
            console.log('er',error);
            // this.setState({errorMessage:error.response.data.result})
            // this.setState({loader:false});
          });
        }
    render() {
        return (
            <section className="signin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-6 login-image2" />
            <div className="col-sm-12 col-xs-12 col-md-6  Modal-Overlay-Right">
              <div className="col-sm-12 col-md-8 ml-3">
              <Link to="/" className="logo-signin"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/signin2.png`} alt="logo" /></Link>
              </div>
                <Formik
                    initialValues={{ otp:'',password:''}}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                
            
           
                    let {otp,password}=values;
                    this.SignupApiCall(otp,password);
      
                    }}
                    validationSchema={validationSchema}
                >
                    {(formikProps) => (
                    <>
                    <form onSubmit={formikProps.handleSubmit}>
                        <div className="signupform">
                        
                        <div className="form-group form-group col-sm-12 col-md-8">
                            <h2>Forgot Password</h2>

                            <div className="form-group col-sm-12 col-md-12">

                                <label htmlFor="uname">OTP</label>
                                    <input  type="text" name="otp"
                                    onBlur={formikProps.handleBlur}
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.otp} 
                                    className="form-control" id="uname" placeholder="Enter OTP sent to your Email" />
                                    {formikProps.errors.otp && formikProps.touched.otp &&
                                        <small id="otpHelp" className="form-text text-warning">{formikProps.errors.otp}</small>
                                    }
                            </div>
                        

                            <div className="form-group col-sm-12 col-md-12">
                                <label htmlFor="pwd">Password</label>
                                <input type="password"
                                name="password"
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange}
                                value={formikProps.values.password}
                                className="form-control" id="pwd" placeholder="Enter password"/>
                            {formikProps.errors.password && formikProps.touched.password &&
                                <small id="emailHelp" className="form-text text-warning">{formikProps.errors.password}</small>
                                }
                            </div>
                        </div>

                        <div className="form-group col-sm-12 col-md-8 mt-3">
                            <button type="submit" className="form-control btn">Update</button>
                        </div>
                        </div>
                        {this.state.errorMessage &&
                            <div className="col-sm-12 col-md-8 mt-3">
                            <div className="alert alert-danger" role="alert">
                            Something went wrong. Please try again!
                            </div>
                            </div>
                        } 
                        {this.state.successMessage &&
                            <div className="col-sm-12 col-md-8 mt-3">
                            <div className="alert alert-success " role="alert">
                           Password Updated Successfully.Please  <a href="#" onClick={()=>this.props.handleOpenModal('Login')}>Login</a>.
                            </div>
                            </div>
                        } 
                    </form>
                    </>
                    )}
                </Formik>
            </div>
          </div>
        </div>
      </section>
        );
    }
}

export default CheckOtp;