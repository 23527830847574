import axios from 'axios';
import { BASE_URL } from '../helper';


export const loginState = (data) => (dispatch) =>{
    dispatch({ type: 'Login',status:data});
}

export const setSearchKey = (data) => (dispatch) =>{
  dispatch({ type: 'SET_SEARCH_KEY',key:data});
}
export const setSearchUrl = (data) => (dispatch) =>{
  dispatch({ type: 'SET_SEARCH_URL',url:data});
}


export const cartDetails = () => (dispatch) =>{

  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
console.log(id)
if(id && token){
  axios({
    method: 'post',
    url: BASE_URL+'api/cart/cartlist',
    headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
    data:{ 

      "user_id":id,      
    }
  })
  .then( (response)=> {
    console.log(response)
    dispatch({ type: 'CART_DETAILS',details:response.data.cart_items});
   
    
  })
  .catch((error)=> {
    console.log('er',error.response);
    
  });
}else{
  dispatch({ type: 'CART_DETAILS',details:[]});
}

 

}
export const bookmarkItems = () => (dispatch) =>{
  return new Promise(function(resolve, reject) {
    let id = localStorage.getItem('user_id');
    let token = localStorage.getItem('omg_token');
  // console.log(id)
  if(id && token){
    axios({
      method: 'post',
      url: BASE_URL+'api/cart/bookmarklist',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data:{ 
  
        "user_id":id,      
      }
    })
    .then( (response)=> {
      console.log(response)
      dispatch({ type: 'BOOKMARK_ITEMS',items:response.data.bookmark_items});
     resolve(true);
      
    })
    .catch((error)=> {
      console.log('er',error.response);
      reject(true)
    });
  }else{
    dispatch({ type: 'BOOKMARK_ITEMS',items:[]});
    resolve(true);
  }
  });


  

 

}





