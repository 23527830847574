import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { BASE_URL } from "../helper";






const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
class LightBox extends Component {


    constructor(props) {
        super(props);
        this.state = {
          token:null,
          images:this.props.imagesToShow,
          name:"Lorem Ipsum",
          showModal: false,
          formType:null,
          imageToShow:this.props.imagesToShow[0]
      
        };

        this.escFunction = this.escFunction.bind(this);

        // alert(this.state.imageToShow);
      }
 //show next image in lightbox
 showNext = (e) => {
  e.stopPropagation();
  let currentIndex = this.state.images.indexOf(this.state.imageToShow);
  if (currentIndex >= this.state.images.length - 1) {
    // this.props.hideLightBox();
  } else {
    let nextImage = this.state.images[currentIndex + 1];
    this.setState({imageToShow:nextImage});
  }
};

//show previous image in lightbox
showPrev = (e) => {
  e.stopPropagation();
  let currentIndex = this.state.images.indexOf(this.state.imageToShow);
  if (currentIndex <= 0) {
    // this.props.hideLightBox();
  } else {
    let nextImage = this.state.images[currentIndex - 1];
    this.setState({imageToShow:nextImage});
  }
};
addFav=()=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  if(token === "" || token === null)
  {
    this.props.hideLightBox();
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    // this.handleOpenModal('Login');
  }else{
    axios({
      method: 'post',
      url: BASE_URL+'api/product/favourites/',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data: {
        "product_type":this.props.product_type,
        "object":(this.props.product_type=="object")?this.props.productIDToLink:"",
        "material":(this.props.product_type=="material")?this.props.productIDToLink:"",
        "graphics":(this.props.product_type=="graphics")?this.props.productIDToLink:"",
        "user_id":id,

     
      }
    })
    .then( (response)=> {

      if(this.props.product_type =='object'){
        this.props.addToast(`${this.props.activeProductName} ${response.data.status ==="removed" ? "removed from " :"added to"}  favorites`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }else if( this.props.product_type =='material'){
        this.props.addToast(`${this.props.activeProductName} ${response.data.status ==="removed" ? "removed from " :"added to"}  favorites`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }else {
        this.props.addToast(`${this.props.activeProductName} ${response.data.status ==="removed" ? "removed from " :"added to"}  favorites`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      this.props.hideLightBox();
      this.props.getProduct();
 
      
    })
    .catch((error)=> {
     
      this.props.hideLightBox();
      this.props.getProduct();
     
    });
  }
}

escFunction(event){
  console.log(event.key)
  if (event.key === "Escape") {
  
    this.props.hideLightBox();
    //Do whatever when esc is pressed
  }else if(event.key === "ArrowLeft"){
    this.showPrev(event);
    
  }else if(event.key === 'ArrowRight'){
    this.showNext(event);
  }
}

componentDidMount(){
  document.addEventListener("keydown", this.escFunction, false);
}
componentWillUnmount(){
  document.removeEventListener("keydown", this.escFunction, false);
}



    render() {

     

      
        // console.log(this.props.imagesToShow)
        return (
            // onClick={this.props.hideLightBox}
            <div id="lightbox"   >
            
            <div id="lightbox-wrap"  style={{}} >
            <span onClick={this.props.hideLightBox} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
            <span className="favButton" onClick={this.addFav}><i className="fa fa-heart"  style={{color:this.props.isFavourite === 1?"red":"#fff" }}></i></span>
            <span   onClick={this.showPrev} className="left-arrow"><ion-icon name="chevron-back-outline"></ion-icon></span>
            <span  onClick={this.showNext}  className="right-arrow"><ion-icon name="chevron-forward-outline"></ion-icon></span>

            <img  id="lightbox-img" alt="box1" src={BASE_URL.slice(0, -1)+`${this.state.imageToShow}`}></img>

            <div className="lightbox-bottom mfp-bottom-bar">
              <div><h4>{this.props.activeProductName}</h4></div>
              <div className="inner-wrap">
                <div className="bm" onClick={()=>{this.props.hideLightBox();this.props.addToBookMark(this.props.productIDToLink)}}>
                    <img className="mwi" src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} /><a href="#" >BookMark</a></div>
                <div className="vw"><Link to={{
                 pathname: `/productDetails/${this.props.product_type}/${this.props.productIDToLink}`,
         
                state: { id: this.props.productIDToLink }
         }}
         ><img className="mwi" src={`${process.env.PUBLIC_URL}/assets/images/v3.png`} />View</Link></div>
               
               
               
   <div className="cr"  onClick={()=>{this.props.hideLightBox();this.props.addToCart(this.props.productIDToLink)}}><img className="mwi" src={`${process.env.PUBLIC_URL}/assets/images/v2.png`} /><a href="#">Cart</a></div>
                </div>
                </div>
            </div>
            
            
          </div>
        );
    }
}
export default withRouter(withToast(LightBox));
