import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {

  Link
} from "react-router-dom";
import { Modal,Button } from 'react-bootstrap';
import AddInfotainment from './AddInfotainment';
import { BASE_URL } from '../helper';
import Login from './Login'
import Signup from './Signup';
import ForgotPassword from './ForgotPassword'
import CheckOtp from './CheckOtp';
import {
  EmailShareButton,
  FacebookShareButton,
 
  PinterestShareButton,
  TwitterShareButton,
 
} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

class Infotainment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxDisplay:false,
      imagesToShow:"",
      cartItems:null,
      cartTotal:0,
      show_modal:false,
      add_infotainment:false,
      infotainments:[],
      showModal: false,
      formType:null,
      search_text:"",
      tab_index:0,
      categories:[],
      category_id:'',
      total_items:0,
      next:null,
      previous:null,
      currentPage:1,
      perPage:20,
      showSharePanel:false,
      pageNumbers:[],
      page:1,
      modalOpen:false,
      showupDetails:[],
      selected_item:{},
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }
  componentDidMount(){
    this.getInfotainments();
    this.getCategories();
  }
  handleOpenModal= (form)=> {
    this.setState({formType:form},()=>{this.setState({ showModal: true })});
  }

  
  // handleOpenModal_Details= (form)=> {
  //   this.setState({formType:form},()=>{this.setState({ showModal: true })});
  // }
  getCategories(){
    axios({
      method: 'get',
      url: BASE_URL+'api/inspire/infotainment_category',
      headers: { 'Content-Type': 'application/json' },
    })
    .then( (response)=> {
      // let assetData = response.data.assets;
      // assetData = assetData.filter((item) => item.product_type == "graphics").map(({ id, asset_type }) => ({ id, asset_type }));
      this.setState({categories:response.data.category});
        // alert(JSON.stringify(response));
      
    })
    .catch((error)=> {
      console.log(error)        
    });
  }
  handleCloseModal = ()=> {
    this.setState({ showModal: false });
  }
 
 getInfotainments(){
  const token = localStorage.getItem('omg_token');
  const user_id = localStorage.getItem('user_id');
  axios({
    method: 'post',
    url: BASE_URL+`api/inspire/infotainment_search?page=${this.state.page}`,
    headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
    data:{ 
      "search_text":this.state.search_text, 
      "category_id":this.state.category_id,
    }
  })
  .then( (response)=> {
    // alert(JSON.stringify(response));
    this.setState({infotainments:response.data.results,total_items:response.data.count?response.data.count:0,next:response.data.next,previous:response.data.previous});
    if(response.data.results.length >0){
      let pages = [];
      for (let i = 1; i <= Math.ceil(response.data.count / this.state.perPage); i++) {
        pages.push(i);
      }
      this.setState({pageNumbers:pages});

    }
    // this.setState({completed:true});

    
  })
  .catch((error)=> {
    console.log('er',error);
  

  });
 }
 handleClick =  (event) => {
  event.preventDefault();
  this.setState({
    page: Number(event.target.id)
  },()=>{
    this.getInfotainments();
  });
}
handleNextClick =  (event) => {
  event.preventDefault();
  if(this.state.next != null){

    var page = this.state.next.split('page=')[1];
    this.setState({
    page: page
  },()=>{
    this.getInfotainments();
  });
  }
  
}
handlePrevClick =  (event) => {
  event.preventDefault();
  if(this.state.previous != null){

    var page = this.state.previous.split('page=')[1];
    if(!page){
      page=1;
    }
    
   
    this.setState({
    page: page
  },()=>{
    this.getInfotainments();
  });
  }
  
}
onKeyUp(event) {
  if (event.charCode === 13) {
    if(event.target.value >0){
     
      this.setState({page:event.target.value},()=>{
        this.getInfotainments();
      })
    }
    
    
  }
}
 addInfotainmentForm(){
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    // this.props.addToast(`Please Login To Continue`, {
    //   appearance: 'error',
    //   autoDismiss: true,
    // })
    this.handleOpenModal('Login');

  // this.props.history.push("/login")
  return Promise.reject();  
  }else{
   
      this.setState({add_infotainment:true});
   
    
  }
 }


showAddInfotainment = ()=>{

  this.setState({add_infotainment:!this.state.add_infotainment},()=>{
    this.getInfotainments();
  });
}
showLightBox = (image) => {
  //set imageToShow to be the one that's been clicked on   
  this.setState({imageToShow:image}) 
  // this.setState({productIDToLink:id})
  // this.setState({activeProductName:product_name})
  // this.setState({activeProductPrice:price})  
  this.setState({lightboxDisplay:true}) 

};
hideLightBox = () => {
  this.setState({lightboxDisplay:false}) 
}

showSharePanel(item){
  this.setState({showSharePanel:!this.state.showSharePanel,selected_item:item});

  // e.preventDefault();
  // this.setState({showSharePanel:!this.state.showSharePanel});
  // alert(this.state.showSharePanel);

}


  render() {

    const liStyle = {float : 'right', fontSize:'26px',cursor:'pointer',padding:"20px"}
    return (
 <>
     <Header/>
     <Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>
  {/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
  </Modal.Header> */}
  <Modal.Body>
{this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
} 
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}   
{this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}  
      

</Modal.Body>
  {/* <Modal.Footer>
    <Button variant="secondary" onClick={()=>this.setState({showBModal:false})}>
      Close
    </Button>
    <Button variant="primary" onClick={()=>this.setState({showBModal:false})}>
      Save Changes
    </Button>
  </Modal.Footer> */}
</Modal>
{ this.state.lightboxDisplay ?
            
            <div id="lightbox" onClick={this.hideLightBox}>
  <div id="lightbox-wrap">
  <span onClick={this.props.hideLightBox} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
  <img id="lightbox-img" alt="box" src={this.state.imageToShow}></img>

  </div>
  
  
</div>
: '' }



      <Modal 
        
         className="Modal2222-2"
          show={this.state.modalOpen}
           isOpen={this.state.modalOpen}
           contentLabel="onRequestClose Example"
           onRequestClose={()=>{this.setState({modalOpen:false})}}
           shouldCloseOnOverlayClick={true}
           
           
        >
          <div className="modal-checkout-message">    
              <div>

              <i  style={liStyle} class="fa" onClick={()=>this.setState({modalOpen:false})}>&#xf00d;</i>

             

               
                                 
              <div className="card-body">
                    <h5 className="card-title add-pointer">
                         {this.state.showupDetails.title}
                    </h5>


                    {this.state.showupDetails.category=='1' && this.state.showupDetails.image != null && (
                      <a className="link" href={this.state.showupDetails.upload_link} target="_blank" ><img src={BASE_URL.slice(0, -1)+`${this.state.showupDetails.image}`} className="card-img-top" alt="" /></a>
                    )}
                    {this.state.showupDetails.category=='2' &&  (
                        <a className="link" href={this.state.showupDetails.upload_link} target="_blank"><img src={this.state.showupDetails.video_thumbnail != null ?this.state.showupDetails.video_thumbnail:"../assets/images/video-placeholder.jpg"} className="card-img-top" alt="" /></a>
                    )}

                    <a href="#" class="infotainment_a">{this.state.showupDetails.category=='1' ? "Article" :"Tutorials"}</a>
                  

                    <p class="info_desc">{this.state.showupDetails.description}</p>
              </div>

                <h3>

                </h3>
              
              
              </div>
              
          </div>
      </Modal> 



     <>
     <section className="inspire-cont">
          <div className="insp-banner">
            <img src="../assets/images/dream.png" width="100%" height="100%" />
          </div>
        </section>
        {/* Inspire Section */}
        <section className="inspire">
          <div className="container-fluid">
            <div className="row">
              {/* Start col-9 */}
              <div className="col-xl-12 col-md-12 px-4">
                <div className="ibw">
                  {/* Left */}
                  <ul className="nav nav-products products-tab" id="products-tab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active btn-tabs" id="products-home-tab" data-toggle="pill" href="#products-home" role="tab" aria-controls="products-home" aria-selected="true" onClick={()=>{this.setState({category_id:''},()=>{
                          this.getInfotainments()
                        })}}>All</a>
                    </li>

                    {this.state.categories.length > 0 && (<>
                    {this.state.categories.map((cat)=>{
                      return(
                        <li className="nav-item">
                        <a className="nav-link  btn-tabs" id="products-profile-tab" data-toggle="pill" href="#products-home" role="tab" aria-controls="products-profile" aria-selected="false" onClick={()=>{this.setState({category_id:cat.id},()=>{
                          this.getInfotainments()
                        })}}>{cat.category}</a>
                      </li>
                      )
                        
                    })}
                  </>)}
                  <li className="nav-item">
                    < Link exact to="/inspire_details" className="nav-link  btn-tabs" id="products-contact-tab" data-toggle="pill" href="#products-contact" role="tab" aria-controls="products-contact" aria-selected="false" >Inspirations</Link>
                  </li>

                   
                   
                  </ul>
                  {/* Right */}
                  <div className="ibwr">
                    <div className="cartbtn-wrap searchw">
                      <input type="text" name="search"  className="searchinp" value={this.state.search_text} onChange={(e)=>{this.setState({search_text:e.target.value})}}/>
                      <button className="searchb"><span className="fa fa-search" onClick={()=>{this.getInfotainments()}} /></button>
                    </div>
                    <button className="inspbtn open-popup-link" href="#insp-popup1" onClick={()=>{this.addInfotainmentForm()}}>Add Infotainment</button>
                  </div>
                </div>
                {/*Product Breadcrump*/}
                {/* <div className="prod_breadcrum tiny_desc">Showing 12 of 1384</div> */}
                <div className="tab-content mt-4 " id="products-tabContent">
                  <div className="tab-pane fade show active" id="products-home" role="tabpanel" aria-labelledby="products-home-tab">
                    {/* Start Wrapper */}
                    <div className="d-flex flex-wrap justify-content-around flex-row">

                    {this.state.infotainments.length > 0 && this.state.infotainments.map((item)=>{
return(
  <>
   <div className="card prod-item m-4 ">
          {item.category=='1' && item.image != null && (
            <a className="link" href={item.upload_link} target="_blank" ><img src={BASE_URL.slice(0, -1)+`${item.image}`} className="card-img-top" alt="" /></a>
          )}
          {item.category=='2' &&  (
            <a className="link" href={item.upload_link} target="_blank"><img src={item.video_thumbnail != null ?item.video_thumbnail:"../assets/images/video-placeholder.jpg"} className="card-img-top" alt="" />
           {item.video_thumbnail != null ? <img  className ="custom-play-btn" src= "../assets/images/play-button-transparent.png"/> : ""} 
            </a>
          )}
         
          <img src="../assets/images/sh.png" className="pbadge1" alt="" onClick={()=>{this.showSharePanel(item)}}/>

          <div class={(this.state.showSharePanel && this.state.selected_item==item) ? "social-slide-in from-right  from-right-modal show":"social-slide-in from-right from-right-modal"}>
            <div class="slide-in-content share-icons">
              <ul>
                <li>  <TwitterShareButton
                      openShareDialogOnClick={true}
                      url={'http://twitter.com/'}
                      title={"Test Title"}
                      className="Demo__some-network__share-button"
                    >
                    <i class="fab fa-twitter"></i>
                    </TwitterShareButton></li>
                <li>  <FacebookShareButton
                      url={'http://twitter.com/'}
                      title={"Test Title"}
                      className="Demo__some-network__share-button"
                    >
                    <i class="fab fa-facebook-f"></i>
                    </FacebookShareButton></li>
                <li>  <PinterestShareButton
                      url={'http://twitter.com/'}
                      title={"Test Title"}
                      className="Demo__some-network__share-button"
                    >
                  <i class="fab fa-pinterest-p"></i>
                    </PinterestShareButton></li>
                    <li>  <EmailShareButton
                      url={'http://twitter.com/'}
                      title={"Test Title"}
                      className="Demo__some-network__share-button"
                    >
                  <i class="far fa-envelope"></i>
                    </EmailShareButton></li>
                    <li> 
                    <CopyToClipboard text={"this.state.value"}
                    onCopy={() => this.setState({copied: true})}>

              
                      <i class="far fa-copy" ref={this.ToolTip}></i>
                  
              
                  </CopyToClipboard>
                  {/* {this.state.copied &&
                  <span style={{backgroundColor:'green',padding:5,color:"#fff",alignSelf:'center'}}>Copied</span>
                  } */}
                    
                    </li>
                  
              </ul>
            </div>
          </div>
         
          <div className="card-body">


              {item.media_type=='image' && item.image != null && (
                <a className="link" onClick={() => this.showLightBox(BASE_URL.slice(0, -1)+`${item.image}`)} ><h5 className="card-title add-pointer">{item.title}</h5></a>
                    )
              }
              {item.media_type=='video' &&  (
                <a className="link" onClick={()=>this.setState({modalOpen:true,showupDetails:item})}><h5 className="card-title add-pointer">{item.title}</h5></a>
              )}
          
          </div>
        </div>
  </>
)
                    })}

                    {this.state.infotainments.length == 0 && (
                      <>
                      <div className="col-md-12 info-msg">No content found..</div>
                      </>
                    )}
        {/* Product */}
       
        {/* Product */}
        {/* Product */}
        {/* <div className="card prod-item">
          <a className="link" href="../assets/images/video-placeholder.jpg"><img src="../assets/images/video-placeholder.jpg" className="card-img-top" alt="" /></a>
          <img src="../assets/images/sh.png" className="pbadge1" alt="" />
         
          <div className="card-body">
            
            <h5 className="card-title">Eames style Longue chair &amp; Ottoman Premium, Chair and Ottoman Premium</h5>
           
          </div>
        </div> */}
        {/* Product */}
        {/* Product */}
        {/* <div className="card prod-item">
          <a className="link" href="../assets/images/220.png"><img src="../assets/images/r76.png" className="card-img-top" alt="" /></a>
          <img src="../assets/images/sh.png" className="pbadge1" alt="" />
         
          <div className="card-body">
            
            <h5 className="card-title">Eames style Longue chair &amp; Ottoman Premium, Chair and Ottoman Premium</h5>
           
          </div>
        </div> */}
        {/* Product */}
        {/* Product */}
        {/* <div className="card prod-item">
          <a className="link" href="../assets/images/220.png"><img src="../assets/images/r76.png" className="card-img-top" alt="" /></a>
          <img src="../assets/images/sh.png" className="pbadge1" alt="" />
         
          <div className="card-body">
            
            <h5 className="card-title">Eames style Longue chair &amp; Ottoman Premium, Chair and Ottoman Premium</h5>
           
          </div>
        </div> */}
      
        {/* Product */}
      </div>
                    {/* End Wrapper */}
                  </div>
                  <div className="tab-pane fade" id="products-profile" role="tabpanel" aria-labelledby="products-profile-tab">
                  <div className="col-md-12 info-msg">No content found..</div>
                  </div>
                  <div className="tab-pane fade" id="products-contact" role="tabpanel" aria-labelledby="products-contact-tab">
                    <div className="col-md-12 info-msg">No content found..</div>
                  </div>
                </div>
              </div>
              {/* End col-9*/}
            </div>
            {/* End container*/}
          </div>
          {/* End Row*/}
        </section>
        {/* End Shop Section*/}
        {/* Pagination */}
        <section className="pagination">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12 ml-auto px-5 d-flex flex-wrap flex-row justify-content-between">
                {/* Page no */}
                <div className="mb-4 py-2 goto">Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp} /></div>
                {/* Pagination */}
                <nav aria-label="pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link prev" href="#" aria-label="Previous" onClick={this.handlePrevClick}>
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {this.state.pageNumbers.map((val)=>
 <li className={this.state.page == val ?"page-item active":"page-item"}><a className="page-link" href="#" id={val} onClick={this.handleClick}>{val}</a></li>
                    )}
                    {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item active"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                    <li className="page-item">
                      <a className="page-link next" href="#" aria-label="Next" onClick={this.handleNextClick}>
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>





      
     </>
     
     {this.state.add_infotainment && (<>
     <AddInfotainment showAddInfotainment={this.showAddInfotainment}/>
     </>)}
     <Footer/>
 </>
    );
  }
}
export default withRouter(withToast(Infotainment));