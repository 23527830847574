import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.

 


// const stripePromise = loadStripe('pk_test_51Iv2hrJQ9plTptrEYixmHjNjsSfq2k7awLs9HXzUsPRBeIGSHRB2Mqtone5igfAH3OZA65eSZQYWQqtj5vCm1Fmx00F1Ni57DG');


export default function App(props) {
  console.log(props,'propss');
  const [clientSecret, setClientSecret] = useState("");
  const stripePromise = loadStripe(props.stripekey);

  // const stringClientSecret = clientSecret.toString();


  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    var urlencoded = new URLSearchParams();
urlencoded.append("amount", (props.cartTotal*100));
urlencoded.append("currency", "usd");

    fetch("https://api.stripe.com/v1/payment_intents", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" ,"Authorization":`Bearer ${props.stripesecretkey}`},
      body: urlencoded,
    })
      .then((res) => res.json(),)
      .then((data) => 
      // console.log('er',data),
        setClientSecret(data.client_secret)
       
      )
      .catch((error)=> {
       
      });

    
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };


  
  console.log(options);
  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise} placeOrder={props.placeOrder}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

