import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
export default class Faq extends Component {
  render() {
    return (
        <>
        <Header/>
        <section className="Terms-wrap"> 
        <div  style={{"text-align": "center"}} className="container px-0 inner-page">
            <h3 className="text-center my-3">Faqs</h3>

	<a style={{"align-content": "center"}} href="https://mis.3domg.com/media/files/faq/FAQ.docx" target="_blank" class="btn signup text-center ">Download PDF</a>

            <div style={{"margin-bottom":"2rem"}}></div>
        </div> 
        </section>
        <Footer/>
        </>
    );
  }
}
