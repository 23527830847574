import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Objects from './pages/Objects'
import Materials from './pages/Materials';
import Graphics from './pages/Graphics';
import Inspire from './pages/Inspire';
import InspireDetails from './pages/InspireDetails';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Payment from './pages/Payment';
import Billing from './pages/Billing';
import Create from './pages/Create';
import Downloads from './pages/Downloads';
import Profile from './pages/Profile';
import UpdateProfile from './pages/UpdateProfile';
import Infotainment from './pages/Infotainment';
import GraphicTest  from './pages/GraphicTest';
import Terms  from './pages/Terms';
import Faq from './pages/Faq';
import PaymentStripe from './pages/PaymentStripe';
import UserProfile from './pages/UserProfile';
// import UserProfile from './pages/UserProfile';

function App() {
  return (

    <Router>
  
 

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route exact path="/">
        <Home />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route exact path="/forgot_password">
            <ForgotPassword />
          </Route>
          <Route exact path="/reset_password">
            <ResetPassword />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/objects">
            <Objects />
          </Route>
          <Route exact path="/materials">
            <Materials />
          </Route>
          <Route exact path="/graphics">
            <Graphics />
          </Route>
          <Route exact path="/graphicstest">
            <GraphicTest />
          </Route>
          <Route exact path="/inspire">
            <Inspire/>
          </Route>
          <Route exact path="/inspire_details">
            <InspireDetails/>
          </Route>
          <Route exact path="/productDetails/:product_type/:id">
            <ProductDetails />
          </Route>
          <Route exact path="/cart">
            <Cart />
          </Route>
          <Route exact path="/payment">
            <Payment />
          </Route>
          <Route exact path="/billing">
            <Billing />
          </Route>
          <Route exact path="/create">
            <Create />
          </Route>
          <Route exact path="/downloads">
            <Downloads />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/update_profile">
            <UpdateProfile />
          </Route>
          <Route exact path="/infotainment">
            <Infotainment />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/faq">
            <Faq/>
          </Route>
          <Route exact path="/paymentstripe">
            <PaymentStripe/>
          </Route>
          <Route exact path="/userprofile/:id">
            <UserProfile/>
          </Route> 
          <Route exact path="/profile/:id">
            <Profile/>
          </Route>

          
        </Switch>
   
    </Router>
  
  );
}

export default App;
