import React from 'react';
import PropTypes from 'prop-types';
import Loader  from "react-loader-spinner";
Loader.propTypes = {
    
};

function ReactLoader(props) {
    return (
        <div class="loading">
        <div class="spinner">
        <Loader
              type="Oval"
              color="#E06526"
              height={50}
              width={50}
              // timeout={3000}
            />
        </div>
      </div>
    );
}

export default ReactLoader;
