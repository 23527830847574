import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";
import {Link} from "react-router-dom";
import CreateObjectMaterials from './CreateObjectMaterials';
import CreateGraphics from './CreateGraphics';
import ReactLoader from '../widgets/Loader'
import { BASE_URL } from '../helper';

const axios = require('axios');

const validationSchema = yup.object().shape({
  // title:yup.string().required().label('Title'),
  price:yup.number().required().label('Price'),
  description:yup.string().required().label('Description'),
  imageFiles: yup.mixed().required('Please upload images'),
  
  });

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      complete:false,
      error:false,
      formMode:"O/M",
      terms_and_conditions:null,
      rules:null
      // loader:true,
      // loading:true,

    };
  }

componentDidMount(){
  const token = localStorage.getItem('omg_token');
  this.setState({token});

  this.getRules();
  // <ReactLoader /> 
  // {this.state.loading ? <ReactLoader /> :''}
}

getRules(){
  axios({
    method: 'get',
    url: BASE_URL+`api/appsettings/app_info`,
    headers: { 'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" }, 
    data: ""
    
  }).then( (response)=> {
  
    // console.log(response.data,'Inspiredetails');
    if(response.data.response_code==200){
      const rules=response.data.upload_rules;
      this.setState({rules:rules});
      const terms_and_conditions=response.data.terms_and_conditions;
      this.setState({terms_and_conditions:terms_and_conditions});
    }
   
    
  }).catch((error)=> {
    console.log('er',error);
  
  });
}
 


  render() {
  // <ReactLoader /> 
    // <ReactLoader></ReactLoader>

let {formMode} = this.state;
    return (
 <>
     <Header/>
     <>
     {/* Model Section */}
    
     <section className="model">
        <div className="container-fluid spl-styl" style={{}}>
        <div className="row">
        <div className="col-sm-12 col-md-12">
        <span className="create-button-wrap"><a style={{
           color: formMode === "O/M"?'#e06526':'grey',
          borderBottomColor:formMode === "O/M"?'#e06526':'grey'
  
  }} onClick={()=>{this.setState({formMode:"O/M"})}} href="#">Objects/Materials</a> <a
  style={{
    color: formMode === "G"?'#e06526':'grey',
   borderBottomColor:formMode === "G"?'#e06526':'grey'

}}
  
  onClick={()=>{this.setState({formMode:"G"})}} href="#">Graphics</a></span>
        </div> 
        </div>
        {formMode === "O/M" &&
        <CreateObjectMaterials   rules={this.state.rules}/>
        }
          {formMode === "G" &&
         <CreateGraphics rules={this.state.rules}/>
        }  
         
        </div>
      </section> 
     
     </>
 
     <Footer/>
 </>
    );
  }
}
export default withRouter(Create);