import React, { Component } from 'react'
import { Modal,Button } from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import { BASE_URL } from '../helper';
const validationSchema = yup.object().shape({
      // title:yup.string().required().label('Title'),
      // description:yup.string().required().label('Description'),
      // image: yup.mixed().required('Please upload a  image'),
    //   address:yup.string().required().label('Address'),
    //   phone_number:yup.number().required().label('Phone Number'),
    //   country:yup.string().required().label('Country'),
    //   city:yup.string().required().label('City'),
    //   zipcode:yup.number().required().label('Zip Code'),
   
      });
      const axios = require('axios');
export default class AddInspire extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show_modal:true,
          elements_link:[{element_name:"",element_link:""}],
          completed:false,
          error:false


        };
      }
      setElementLink=(elements_link)=>{
          
        this.setState({elements_link:elements_link})
      }
      handleLinkChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.elements_link];
        list[index][name] = value;
       
        this.setElementLink(list);
      };
       handleRemoveClick = index => {
        const list = [...this.state.elements_link];
        list.splice(index, 1);
        
        this.setElementLink(list);
      };
     handleAddClick = () => {
        this.setElementLink([...this.state.elements_link, { element_name:"",element_link:"" }]);
      };
      addInspiration(title,description,image){
        const token = localStorage.getItem('omg_token');
        const user_id = localStorage.getItem('user_id');
        let name = localStorage.getItem('user_name');
        // let elements_link=["165.22.212.233:8002/api/product/material/1/"];

        // let link_array=[];
        // if(this.state.elements_link != null){
        //     for (const key of Object.keys(this.state.elements_link)) {
        //         link_array.push(this.state.elements_link[key]['link']);
        //     }
        //   }
        let user=JSON.parse(name);
        
       

         
         
        const formdata = new FormData();
        formdata.append('image',image);
        formdata.append('title', title);
        formdata.append('description', description);
        formdata.append('user', user_id);
        formdata.append('upload_by', user.first_name +" "+user.last_name);
        formdata.append('elements_link', JSON.stringify(this.state.elements_link));
        
        axios({
            method: 'post',
            url: BASE_URL+'api/inspire/inspiration/',
            headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}`  },
            data:formdata
          })
          .then( (response)=> {
            //   if(response){
            //     this.props.history.push('/profile');
            //   }
            this.setState({completed:true},()=>{
                setTimeout(
                    () => this.setState({ show_modal:false },()=>{
                        this.props.showAddInspire()  
                    }), 
                    2000
                  );
              })
           
            // this.setState({completed:true});
        
            
          })
          .catch((error)=> {
            console.log('er',error);
            this.setState({error:true});
        
          });

      }
    render() {
        return (
            <>
                 <Modal
     className="BModal "
        show={this.state.show_modal}
        onHide={() => {this.setState({show_modal:false},()=>{
            this.props.showAddInspire();
        })}}
centered
size="lg"
        aria-labelledby="contained-modal-title-vcenter"
       
      >
        {/* <Modal.Header closeButton> */}
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Custom Modal Styling
          </Modal.Title> */}
        {/* </Modal.Header> */}
        <Modal.Body >
       <div className="white-popup  insppopup" style={{margin:0}}>
       <a style={{top:0,right:0,position: "absolute",padding: 10,margin:2,color: "#e06526",cursor:'pointer'}} onClick={()=>{this.setState({show_modal:false},()=>{
            this.props.showAddInspire();
        })}}>X</a>
       
        <div className="container ">
         
          
          <Formik
      initialValues={{ title:'',description:'',image:''}}
      onSubmit={(values, { setSubmitting, resetForm }) => {
      
      let{title,description,image}=values;
        
        this.addInspiration(title,description,image);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
{(formikProps) => (
     <form  onSubmit={formikProps.handleSubmit}>
         <h2>Add New Inspiration</h2>
         <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input type="text" className="form-control" id="title" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.title}  />
                  {formikProps.errors.title && formikProps.touched.title &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.title}</small>
              }
              </div>
              {/* <div className="form-group">
                <label htmlFor="upload">Upload By</label>
                <input type="text" className="form-control" id="upload" />
              </div> */}
              <div className="form-group">
                <label htmlFor="image">Image</label>
                <input type="file" className="form-control" id="image" onChange={(event) => {
           formikProps.setFieldValue("image", event.currentTarget.files[0]);
           }} />
           {formikProps.errors.image && formikProps.touched.image &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.image}</small>
        }
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <label htmlFor="links">Links of Elements Used</label>
                
              
                {this.state.elements_link.map((x, i) => {
                    return(
                        <>
                         <div className="form-row p-0">
                           <div className="col-3">
                           <input type="text" className="form-control mb-2" id={`element_name${i}`} name="element_name" value={x.element_name} onChange={(e)=>{this.handleLinkChange(e, i)}} placeholder="Element Name" />
                           </div>
                         <div class="col-7">
                         <input type="text" className="form-control mb-2" id={`element_link${i}`} name="element_link" value={x.element_link} onChange={(e)=>{this.handleLinkChange(e, i)}} placeholder="Element Link" />
                       </div>
                       <div class="col-2">
                        {this.state.elements_link.length !== 1 && <span style={{color: "#e06526"}} onClick={() => this.handleRemoveClick(i)}>X</span>}
                         </div>
                         </div>
                       
                  
                   </>
                   
                        )
                     
                    
                })}
               
                {/* <input type="text" className="form-control mb-2" id="links" />
                <input type="text" className="form-control mb-2" id="links" /> */}
                <a href="#" className="addlinks" onClick={this.handleAddClick}>Add new links</a>
              </div>
            </div>
          </div>{/* End row */}
          <div className="row descrow">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label htmlFor="desc">Description</label>
                <textarea className="form-control" id="description" name="description" rows={6} defaultValue={""} onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.description}  />
                  {formikProps.errors.description && formikProps.touched.description &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.description}</small>
              }
              </div>
              <div className="note">
                Note: Loremipsum Loremipsum Loremipsum LoremipsumLoremipsum Loremipsum
                Loremipsum LoremipsumLoremipsum LoremipsumLoremipsum LoremipsumLoremipsum Loremipsum
              </div>
            </div>
          </div>{/* End row */}
         
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <button className="btsave">Done!</button>
              </div>
            </div>
          </div> 
         
     </form>
)}
    </Formik>
    
         
        </div>{/* End Container */}
        {this.state.completed &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-success" role="alert">
Created successfully.
</div>
</div>
}
{this.state.error &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-danger" role="alert">
Something went wrong. Please try again!
</div>
</div>
}  
        </div>
        </Modal.Body>
      </Modal>
            </>
        )
    }
}
