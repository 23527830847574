

const cartState={data:[],bookmark_items:[],search_key:'',search_url:'/objects'}


export function cartDetails(state=cartState,action){
    switch (action.type) {
        case 'CART_DETAILS':
            return { ...state,data:action.details };
            case 'SET_SEARCH_KEY':
                return { ...state,search_key:action.key };
                case 'SET_SEARCH_URL':
                    return { ...state,search_url:action.url};
            case 'BOOKMARK_ITEMS':
            return { ...state,bookmark_items:action.items };        
        default:
            return state;
    }
}

