import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { Formik,FieldArray,Field } from "formik";
import * as yup from "yup";
import {Link} from "react-router-dom";
import { BASE_URL } from '../helper';
import ReactTags from 'react-tag-autocomplete'
import ImageUploading from "react-images-uploading";
import ReactLoader from '../widgets/Loader'

const axios = require('axios');
const validationSchema = yup.object().shape({
  model:yup.string().required().label('Model'),
  title:yup.string().required().label('Title'),
  category:yup.string().required().label('Category'),
  // material:yup.string().required().label('Material'),
  // style:yup.string().required().label('Style'),
  platform:yup.string().required().label('Platform'),
  render:yup.string().when('three_d_printable',{
    is:true,
    then:yup.string().notRequired(),
    otherwise: yup.string().required('This field is required ')
  }),
  product_link:yup.string().when('three_d_printable',{
    is:true,
    then:yup.string().required('This field is required'),
    otherwise: yup.string().notRequired()
  }),
  // tags:yup.string().required().label('Tags'),
  description:yup.string().required().label('Description'),
  // imageFiles: yup.mixed().required('Please upload images'),
  document: yup.mixed().required('Please upload a document'),
  polygons:yup.string().notRequired().when(['model', 'three_d_printable'], {
    is: (model, three_d_printable) => (model=='object') && three_d_printable,
    then: yup.string().notRequired(),
    is: (model, three_d_printable) => (model=='object') && !three_d_printable,
    then: yup.string().required(),
    // is: (val) => val == 'object',
    // then: yup.string().required('This field is required '),
    otherwise: yup.string().notRequired()
    }),
    vertices:yup.string().notRequired().when(['model', 'three_d_printable'], {
      is: (model, three_d_printable) => (model=='object') && three_d_printable,
      then: yup.string().notRequired(),
      is: (model, three_d_printable) => (model=='object') && !three_d_printable,
      then: yup.string().required(),
      // is: (val) => val == 'object',
      // then: yup.string().required('This field is required '),
      otherwise: yup.string().notRequired()
      }),
      three_d_printable:yup.boolean(),
  });
class CreateObjectsMaterials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxDisplay:false,
      imageToShow:"",
      token:null,
      complete:false,
      error:false,
      category:[],
      material:[],
      style:[],
      platform:[],
      subcategory:[],
      tags: [],
      render:[],
      files:[],
      document:{},
      assets:[],
      asset_data:[],
      images:[],
      errormessage:"",
      loading:false,

    };
    this.categoryChange=this.categoryChange.bind(this);
    this.reactTags = React.createRef();
    this.deleteFile=this.deleteFile.bind(this);
    this.setAssetData=this.setAssetData.bind(this);
  }
  onDelete (i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition (tag) {

    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }
componentDidMount(){
  const token = localStorage.getItem('omg_token');
  this.setState({token});
  this.getDropdownData();
}
categoryChange(category){
 if(category !=""){
  // const filteredCategory = this.state.category.filter(category => category.id === category);

  const categoryData = this.state.category.find(item => item.id === Number(category));
const resultValue = categoryData['sub_category'];


  this.setState({subcategory:resultValue});


 }
}
getDropdownData(){
  let token = localStorage.getItem('omg_token');

  axios({
    method: 'get',
    url: BASE_URL+'api/product/filter_items',
    // headers: { 'Content-Type': 'application/json' },
    headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
  })
  .then( (response)=> {
    // let assetData = response.data.assets;
    // assetData = assetData.filter((item) => item.product_type == "graphics").map(({ id, asset_type }) => ({ id, asset_type }));
    this.setState({category:response.data.category,material:response.data.material,style:response.data.style,platform:response.data.platform,render:response.data.render,assets:response.data.assets});
      // alert(JSON.stringify(response));

  })
  .catch((error)=> {
    console.log(error)
  });
}
  createApiCall=(model,title,imageFiles,document,category,style,price,description,material,platform,render,tags,subcategory,polygons,vertices,asset,three_d_printable,product_link)=>{
    const user_id = localStorage.getItem('user_id');
    var tag=[];

    if(this.state.tags.length > 0){



       this.state.tags.map(({ name }) => {
          tag.push(name);
      });

    }
    // console.log(tag);

   try{
    const formdata = new FormData();
    formdata.append('data',JSON.stringify({
      "model": model,
      "product_name":title,
      "description":description,
      "platform": platform,
      "render": render,
      "material": material,
      "tags":tag,
      "category": category,
      "sub_category": subcategory,
      "style": style,
      "asset": asset,
      "seller_id": user_id,
      "polygon":polygons,
      "vertices":vertices,
      "product_link":product_link,
      "three_d_printable":three_d_printable?1:0
    }));


  if(this.state.images != null){
    for (const key of Object.keys(this.state.images)) {
     formdata.append('image', this.state.images[key]['file'])
    }
  }
  // if(this.state.images.length > 0){
  //   for (var i=0;i< this.state.images.length;i++) {
  //    formdata.append('image', this.state.images[i]);
  //   }
  // }
  if(document != null){

      formdata.append('product_file', document);

  }

  this.setState({loading:true});
  // return;
  axios({
        method: 'post',
        url: BASE_URL+'api/product/create_product',
        data: formdata,
        headers: { 'content-type': '',"Authorization" : `Token ${this.state.token}` },

      })
      .then( (response)=> {


       this.setState({completed:true,files:[],document:{},tags:[],images:[]});
       this.setState({loading:false});
       return true;

      })
      .catch((error)=> {
        let dataerror=error.response?.data;
        this.setState({errormessage:dataerror?.message});
        // if(dataerror.is_oversize==1){
        //   this.setState({errormessage:dataerror?.message});
        // }else{
        //   this.setState({error:true})

        // }


        // console.log('er',error);
        this.setState({loading:false});
        return false;

      });
    }
    catch(e){
      this.setState({error:true});
      this.setState({loading:false});
    }


    }
    setImages(images){

      var imageFile=[];
      for(var i=0;i < images.length;i++){
        imageFile.push(images[i]);


      }
      this.setState({files:imageFile},()=>{
        // console.log(this.state.files);
      });

    }
    setDoc(file){
      if(file.length !== 0){
        this.setState({document:{...this.state.document,url:URL.createObjectURL(file),name:file['name']}},()=>{
          // console.log(this.state.document);
        });
      }

    }
    deleteFile(e) {
      const s = this.state.files.filter((item, index) => index !== e);
      this.setState({files:s});
    }
    setAssetData(model){


      if(model!=""){
        let type= (model=="object"?"objects":"materials");
        let assetData = this.state.assets;

    assetData = assetData.filter((item) => item.product_type == type).map(({ id, asset_type }) => ({ id, asset_type }));
    // alert(JSON.stringify(assetData));
    this.setState({asset_data:assetData});
      }else{
        this.setState({asset_data:[]});
      }

    }

onChange = (imageList, addUpdateIndex) => {
      // data for submit
      console.log(imageList, addUpdateIndex);
      this.setState({images:imageList})
    };

    showLightBox = (image) => {
      //set imageToShow to be the one that's been clicked on
      this.setState({imageToShow:image})
      this.setState({lightboxDisplay:true})

    };

    hideLightBox = () => {
      this.setState({lightboxDisplay:false})
    }



    // triggerInputFile = () => this.fileInput.click();
  render() {

    // alert(this.state.loading);

    return (



      <div className="row">
      {/* col left */}

      {this.state.loading == true ? <ReactLoader/> : '' }

      <div className="col-sm-12 col-md-6">



      <Formik
initialValues={{  model:'',title: '',imageFiles: null, document:null, category:'',style:'',price:'',description:'',material:'',platform:'',render:'',tags:'',subcategory:'',polygons:'',vertices:'',asset:'',three_d_printable:'',product_link:''}}
onSubmit={(values, { setSubmitting, resetForm }) => {
// console.log(values);
let{model,title,imageFiles,document,category,style,price,description,material,platform,render,tags,subcategory,polygons,vertices,asset,three_d_printable,product_link}=values;

  if(this.createApiCall(model,title,imageFiles,document,category,style,price,description,material,platform,render,tags,subcategory,polygons,vertices,asset,three_d_printable,product_link)){
    resetForm()
  }

  // resetForm();
  // this.setState({completed:true,files:[],document:{},tags:[],images:[]});
  // this.setState({loading:false});
}}
validationSchema={validationSchema}
>
{(formikProps) => (
  <>
       <form

       onSubmit={formikProps.handleSubmit}
       >
        {/* <div className="form-group">
          <h2>Model Upload</h2>
        </div> */}
             <div className="form-group">
          <label htmlFor="title">Model</label>
          <select name="model" onBlur={formikProps.handleBlur}

onChange={e => {

  formikProps.handleChange(e)
  this.setAssetData(e.currentTarget.value)

  // let someValue = e.currentTarget.value

}}
                //  onChange={formikProps.handleChange}
                 value={formikProps.values.model} >
              <option value="" selected disabled hidden>Select Object/Material</option>
              <option value="object">Object</option>
              <option value="material">Material</option>

            </select>
              {formikProps.errors.model && formikProps.touched.model &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.model}</small>
        }
        </div>
        {formikProps.values.model=="object" &&(
          <>
            <div className="form-row">
          <div className="col">
        <div className="form-check  form-check-inline">
  <input className="form-check-input" type="checkbox" onBlur={formikProps.handleBlur}  value={formikProps.values.three_d_printable}  id="three_d_printable" onChange={(e) => {formikProps.setFieldValue('three_d_printable',e.target.checked)}}/>
  <label className="form-check-label" for="defaultCheck1">
   3D  printable
  </label>
</div>
{formikProps.errors.three_d_printable && formikProps.touched.three_d_printable &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.three_d_printable}</small>
        }
</div>

          {/* <input type="checkbox"
           name="3d_printable"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.title}
          className="form-control"
           id="title"
           placeholder="Enter Title"
            />
             <label htmlFor="title">3D Printable</label>
              {formikProps.errors.title && formikProps.touched.title &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.title}</small>
        } */}
        </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="title">Model Title</label>
          <input type="text"
           name="title"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.title}
          className="form-control"
           id="title"
           placeholder="Enter Title"
            />
              {formikProps.errors.title && formikProps.touched.title &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.title}</small>
        }
        </div>


        <div className="form-row">
          <div className="col">
            <label htmlFor="category">Category</label>
            <select name="category" onBlur={formikProps.handleBlur}
            onChange={(event) => {
              formikProps.setFieldValue("category", event.currentTarget.value,this.categoryChange(event.currentTarget.value));
              }}

                 value={formikProps.values.category}>
              <option value="" selected disabled hidden>Select Category</option>
              {this.state.category.map((category)=>
               <option value={category.id}>{category.category_name}</option>
              )}


            </select>
            {formikProps.errors.category && formikProps.touched.category &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.category}</small>
        }
          </div>
          <div className="col">
          <label htmlFor="subcategory">Subcategory</label>
        <select name="subcategory" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.subcategory}>
              <option value="" selected disabled hidden>Select Subcategory</option>
              {this.state.subcategory.length > 0 &&   this.state.subcategory.map((item)=>
               <option value={item.id}>{item.subcategory_name}</option>
              )}

            </select>
            {formikProps.errors.subcategory && formikProps.touched.subcategory &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.subcategory}</small>
        }



          </div>
        </div>



        <div className="form-row">
          <div className="col">

          <label htmlFor="material">Material</label>
            <select name="material" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.material}
                 disabled={(formikProps.values.three_d_printable || formikProps.values.model=='object')? true:false}
                 >
              <option value="" selected disabled hidden>Select Material</option>
              {this.state.material.map((material)=>
               <option value={material.id}>{material.material_name}</option>
              )}

            </select>
            {formikProps.errors.material && formikProps.touched.material &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.material}</small>
        }


          </div>
          <div className="col">

          <label htmlFor="style">Style</label>
            <select name="style" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.style}
                 disabled={formikProps.values.three_d_printable?true:false}
                 >
              <option value="" selected disabled hidden>Select Style</option>
              {this.state.style.map((style)=>
               <option value={style.id}>{style.style_name}</option>
              )}
            </select>
            {formikProps.errors.style && formikProps.touched.style &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.style}</small>
        }


          </div>
        </div>
        <div className="form-row">
          <div className="col">
          <label htmlFor="platform">Platform</label>
            <select name="platform" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.platform}>
              <option value="" selected disabled hidden>Select Platform</option>
              {this.state.platform.map((platform)=>
               <option value={platform.id}>{platform.platform}</option>
              )}

            </select>
            {formikProps.errors.platform && formikProps.touched.platform &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.platform}</small>
        }

          </div>
          <div className="col">




<label htmlFor="render">Render</label>
        <select name="render" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.render}
                 disabled={formikProps.values.three_d_printable?true:false}
                 >
              <option value="" selected disabled hidden>Select Render</option>
              {this.state.render.length > 0 &&   this.state.render.map((item)=>
               <option value={item.id}>{item.render}</option>
              )}

            </select>
            {formikProps.errors.render && formikProps.touched.render &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.render}</small>
        }
          </div>
        </div>
        <div className="form-row">
          <div className="col">
          <label htmlFor="title">Polygons</label>
          <input type="text"
           name="polygons"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.polygons}
          className="form-control"
           id="polygons"
           disabled={(formikProps.values.three_d_printable || formikProps.values.model=='material')?true:false}
           placeholder="Enter Polygons"
            />
              {formikProps.errors.polygons && formikProps.touched.polygons &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.polygons}</small>
        }

          </div>
          <div className="col">




          <label htmlFor="title">Vertices</label>
          <input type="text"
           name="vertices"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.vertices}
          className="form-control"
           id="vertices"
           disabled={(formikProps.values.three_d_printable || formikProps.values.model=='material')?true:false}
           placeholder="Enter Vertices"
            />
              {formikProps.errors.vertices && formikProps.touched.vertices &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.vertices}</small>
        }

          </div>
        </div>

        <div className="form-group">
          <label htmlFor="asset">Pro Model/Bonus Model/OEM</label>
          <select name="asset" onBlur={formikProps.handleBlur}

                 onChange={formikProps.handleChange}
                 value={formikProps.values.asset}>
              <option value="" selected disabled hidden>Select </option>
              {this.state.asset_data.length > 0 &&   this.state.asset_data.map((item)=>
               <option value={item.id}>{item.asset_type}</option>
              )}

            </select>
            {formikProps.errors.asset && formikProps.touched.asset &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.asset}</small>
        }
        </div>

        <div className="form-group">
          <label htmlFor="3d" className="fulabl">Thumbnail Images(Max Upload 4 Images of 640x640 Pixels)</label>
          <div className="row preview">
        {this.state.files.length > 0 &&
          this.state.files.map((item, index) => {
            return (
              <div key={item} className="col" style={{minWidth:150}}>
                <img src={URL.createObjectURL(item)} alt="" style={{width:100,height:100}} className="img-thumbnail"/>
                <h6>{item.name.split('.').slice(0, -1).join('.')}</h6>
                <button type="button" onClick={() => this.deleteFile(index)}
                style={{position: "absolute",
    left: 78,
    top: 10,
    color: "#fff",
    backgroundColor: "#00000091",
    display: "flex",
    padding: 5,
    zIndex:1000}}
     >
                 <ion-icon name="trash-outline"></ion-icon>
                </button>
              </div>
            );
          })}
      </div>

      <ImageUploading
        multiple
        value={this.state.images}
        onChange={this.onChange}
        maxNumber={4}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">

            <div  onClick={onImageUpload}
              {...dragProps} style={{backgroundColor:'#fff',display:'flex',borderWidth:2,borderColor:"#c9c9c9",borderStyle:'dotted',minHeight:100,alignItems:'center',justifyContent:'center',flexDirection:"row"}}>
<ion-icon style={{marginBottom:0,marginLeft:10}} name="images-outline"></ion-icon>
<p style={{marginBottom:0,marginLeft:10}}>Click or Drop here</p>
            </div>
            {/* <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button> */}

            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div style={{display:'flex',flexDirection:'row',flexWrap:'nowrap'}}>
            {imageList.map((image, index) => (
              <div key={index} className="image-item" style={{backgroundImage: `url(${image['data_url']})`}}>
                {/* <img src={image['data_url']} alt="" width="100%" /> */}
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}><ion-icon name="create-outline"></ion-icon></button>
                  <button onClick={() => onImageRemove(index)}><ion-icon name="trash-outline"></ion-icon></button>
                </div>
              </div>
            ))}

</div>
          </div>
        )}
      </ImageUploading>

      {/* <label htmlFor="3d" className="fulabl">Max Upload 4 Images</label> */}
          {/* <div class="file-upload-wrapper" data-text="Select your file!">
          <input type="file"  multiple className="fuinp file-upload-field" name="imageFiles"

            onChange={(event) => {

              if(event.currentTarget.files.length <=4){
                formikProps.setFieldValue("imageFiles", event.currentTarget.files,this.setImages(event.currentTarget.files));
              }else{
                alert("Sorry,Your limit exceed only 4 image is allowed for upload");
              }

              }}
            />
            </div> */}
         {formikProps.errors.imageFiles && formikProps.touched.imageFiles &&
        <small id="emailHelp" className="form-text text-warning">{formikProps.errors.imageFiles}</small>
         }
        </div>

        <div className="form-group">
          <label htmlFor="3d" className="fulabl">3D Model(Max Upload Size 300MB Zipped)</label>
          {Object.keys(this.state.document).length === 0 && (
          <div
          //  onClick={this.triggerInputFile}
            class="file-upload-wrapper" data-text="Select your file!">
              {/* <div className="upload-label"><p>Select your file!</p></div>
              <div className="remove-label"><p>Upload</p></div> */}
          <input
          // onClick={()=>{}}
          //  ref={fileInput => this.fileInput = fileInput}
            type="file" className="fuinp file-upload-field" onChange={(event) => {
           formikProps.setFieldValue("document", event.currentTarget.files[0],this.setDoc(event.currentTarget.files[0]));
           }}
       />
        </div>
          )
        }
        <div className="preview-model">
        {Object.keys(this.state.document).length !=0 && (
          <div className="mt-3 image-preview">
          <img src={`${process.env.PUBLIC_URL}/assets/images/file.jpg`} alt="" style={{width:50,height:50}} className="img-thumbnail mr-2"/>
          <h6 className="mb-0">{this.state.document.name}</h6>
          <ion-icon name="close" onClick={()=>{this.setState({document:{}})}}></ion-icon>
          {/* <button type="button" >
            delete
          </button> */}
        </div>
          )}
      </div>
        {formikProps.errors.document && formikProps.touched.document &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.document}</small>
        }
       {/* onChange={(e)=>this.onDataFileChange(e)} */}
          </div>

          <div className="form-group">
          <label htmlFor="title">Tags</label>
          <ReactTags

        ref={this.reactTags}
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        onDelete={this.onDelete.bind(this)}
        allowNew={this.state.tags.length <=6?true:false}
        placeholderText=" Add Tag "
        onAddition={this.onAddition.bind(this)}
        classNames={{root: `${this.state.tags.length <=6?"react-tags":"react-tags hideInput"}`}}
        minQueryLength={1}

         />


          {/* <input type="text"
           name="tags"
           onBlur={formikProps.handleBlur}
          //  onChange={formikProps.handleChange}
          //  value={formikProps.values.tags}
          onChange={(event) => {
            formikProps.setFieldValue("tags",event.currentTarget.value,this.categoryChange(event.currentTarget.value));
            }}
          className="form-control"
           id="tags"

            />
              {formikProps.errors.tags && formikProps.touched.tags &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.tags}</small>
        } */}
        </div>


        {/* <div className="form-group">
          <label htmlFor="tags">Price</label>
          <input type="number"
           name="price"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.price}
           className="form-control"
           placeholder="0"
            />
            {formikProps.errors.price && formikProps.touched.price &&
            <small id="emailHelp" className="form-text text-warning">{formikProps.errors.price}</small>
            }
        </div> */}
        <div className="form-group">
          <label htmlFor="tags">Description</label>
          <textarea className="form-control"
            name="description"
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            value={formikProps.values.description}

             />
              {formikProps.errors.description && formikProps.touched.description &&
            <small id="emailHelp" className="form-text text-warning">{formikProps.errors.description}</small>
            }
        </div>
        <div className="form-group">
          <label htmlFor="title">Add Product Link </label>
          <input type="text"
           name="product_link"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.product_link}
          className="form-control"
           id="product_link"
           placeholder="Enter Product Link"
            />
              {formikProps.errors.product_link && formikProps.touched.product_link &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.product_link}</small>
        }
        </div>
        <div className="form-group">
          <button type="submit" className="fubut">Done!</button>
        </div>
        {this.state.completed &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-success" role="alert">
Created successfully.Waiting for approval...
</div>
</div>
}
{this.state.error &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-danger" role="alert">
Something went wrong. Please try again!
</div>
</div>
}

{this.state.errormessage &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-danger" role="alert">
  {this.state.errormessage}
</div>
</div>
}

        </form>
        </>
  )}
</Formik>
      </div>
      {/* col right */}
      <div className="col-sm-12 col-md-6">
        <div className="rule-cont">
          <div className="form-group">
            <h2>Rules</h2>
          </div>
          <ul className="rules">
            <li className="rule-item">{this.props.rules}</li>

          </ul>
          <div class="row">

              <a className="uploadimages"   onClick={() => this.showLightBox("../assets/images/upload-1.jpeg")} >
                <img  className=" upload-imgs m-2"  src="../assets/images/upload-1.jpeg" alt="" />
              </a>
              <a   className="uploadimages" onClick={() => this.showLightBox("../assets/images/upload-2.jpeg")} >
                <img className=" upload-imgs m-2" src="../assets/images/upload-2.jpeg" alt="" />
              </a>
              <a className="uploadimages" onClick={() => this.showLightBox("../assets/images/upload-3.jpeg")} >
                <img className=" upload-imgs m-2" src="../assets/images/upload-3.jpeg" alt="" />
              </a>

          </div>
        </div>

        <div className="rule-cont" style={{marginTop:"20px"}}>
        <div className="row">

              <a className="uploadimages"   onClick={() => this.showLightBox("../assets/images/upload-4.jpg")} >
                <img  className=" upload-imgs m-2"  src="../assets/images/upload-4.jpg" alt="" />
              </a>
              <a   className="uploadimages" onClick={() => this.showLightBox("../assets/images/upload-5.jpg")} >
                <img className=" upload-imgs m-2" src="../assets/images/upload-5.jpg" alt="" />
              </a>
              </div>
        </div>
        <div style={{marginTop:"20px"}}></div>
        <div className="rule-cont" style={{padding: "1em 0 1em 1em"}}>
          <div className="row">
          <Link  to={{ pathname: BASE_URL+`sample_asset`}} target='_blank'  style={{margin:'auto'}} className="nav-link signup">Download link for sample Render Scene and Material Slot</Link>

          </div>
        </div>
      </div>


      { this.state.lightboxDisplay ?

            <div id="lightbox" onClick={this.hideLightBox}>
              <div id="lightbox-wrap">
              <span onClick={this.hideLightBox} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
              <img id="lightbox-img" style={{height:"85vh"}} alt="box" src={this.state.imageToShow}></img>

              </div>


            </div>

              : ''
          }

    </div>
    );
  }
}
export default withRouter(CreateObjectsMaterials);
