import React, { Component } from 'react';
import Header from '../widgets/Header';
import Footer from '../widgets/Footer';
import { BASE_URL } from '../helper';
const axios = require('axios');

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
      terms_and_conditions:null,
      

    };
  }

    componentDidMount(){
      const token = localStorage.getItem('omg_token');
      this.setState({token});

      this.getRules();
    
    }

    getRules(){
      axios({
        method: 'get',
        url: BASE_URL+`api/appsettings/app_info`,
        headers: { 'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" }, 
        data: ""
        
      }).then( (response)=> {
      
        // console.log(response.data,'Inspiredetails');
        if(response.data.response_code==200){
         
          const terms_and_conditions=response.data.terms_and_conditions;
          this.setState({terms_and_conditions:terms_and_conditions});
        }
      
        
      }).catch((error)=> {
        console.log('er',error);
      
      });
    }
    


  render() {
    return (
        <>
        <Header/>
        <section className="Terms-wrap"> 
        <div className="container px-0 inner-page">
            <h3 className="text-center my-3">Terms and Conditions</h3>
            <p>{this.state.terms_and_conditions}</p>
            <a href="https://mis.3domg.com/media/files/tandc/Terms%20and%20Conditions.docx" target="_blank" class="btn signup">Download PDF</a>
           
        
        </div> 
        </section>
        <Footer/>
        </>
    );
  }
}

export default Terms;
