import {useState} from "react";

const usePaypal = (props) => {
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");


    const total = (props.cartTotal && props.cartTotal.toString()) ?? "0";
    const initialOptions = {
        "client-id": "ATo4x4adWB2dVX1Hy9lFiMjbW71JLOTBetrEc83j98GaGmjl6uZxRuXz-bH6BLqVsW0tZqXPCAYoYVSw",
        currency: "USD",
        intent: "capture",
    };

    const createOrder = (data, actions) => {
        if (message !== "") setMessage("");
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: total,
                    },
                },
            ],
        });
    }

    const onApprove = (data, actions) => {
        return actions.order.capture().then( async (details) =>{
            props.placeOrder(details);
            setSuccess(true);
            setMessage("Payment succeeded!");
        });
    }

    const onError = (data, actions) => {
        setMessage("An Error occurred with your payment ");
    }

    return {
        initialOptions,
        createOrder,
        onApprove,
        onError,
        message,
        success
    };
}

export default usePaypal;
