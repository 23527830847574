import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import ProductView from '../widgets/ProductView';
import { BASE_URL } from '../helper';

import {

  Link
} from "react-router-dom";

import { ToastProvider, useToasts } from 'react-toast-notifications';
const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
 class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:null,
      details:null,
      product_type:null,
      showBookmarkModal:false,
      related_items:[],
    };
    this.category_handler = React.createRef();
  }
  getProductDetails=async()=>{
    const token = localStorage.getItem('omg_token');
    return await axios({
      method: 'get',
      url: BASE_URL+`api/product/${this.props.match.params.product_type}/${this.props.match.params.id}/`,
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },    
      data: {
        
      }
    })
    .then( (response)=> {
      // alert(JSON.stringify(response));
      console.log(response)
      return Promise.resolve(response);

      
    })
    .catch((error)=> {
      
      return Promise.reject(error);
    
    }); 
  }
  hideBookmark=()=>{
    this.inputNode.classList.remove('show');
  }
componentDidMount=()=>{
  this.setState({id:this.props.match.params.id,product_type:this.props.match.params.product_type});
  this.getProductDetails().then((response)=>{
    console.log(this.state.product_type)
    console.log(response)
    if(this.state.product_type =='material'){
      this.setState({details:response.data.material_detail,related_items:response.data.related_models});
    }else if(this.state.product_type =='object'){
      this.setState({details:response.data.object_detail,related_items:response.data.related_models});
    }else if(this.state.product_type =='graphics'){
      this.setState({details:response.data.graphics_detail,related_items:response.data.related_models});
    }else{
      this.setState({details:null});
    }
    
  })
  .catch((e)=>{
    this.setState({error:true});
  })
}
getCategoryState = () => {
  // console.log(this.inputNode)
  this.inputNode.classList.add('show');
  // return this.category_handler.current.getState();
};
  render() {

    return (
 <>
      <Header showBookmarkModal={this.state.showBookmarkModal} hideBookmark={()=>this.hideBookmark()} inputRef={node => this.inputNode = node} ref={this.category_handler}/>
     <>
       {/* Bread Crumb */}
       <section className="breadcrum1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12">
            
            {this.state.details &&
        <ProductView details={this.state.details} product_type={this.state.product_type} getCategoryState={()=>this.getCategoryState()} related_items={this.state.related_items}/>
      }
      {!this.state.details &&
        <div className="col-sm-12 col-md-8 mt-3">
        <div className="alert alert-warning" role="alert">
         Loading details..
        </div>
       </div>
      }
       {(this.state.error) &&
       
          <div className="col-sm-12 col-md-8 mt-3">
          <div className="alert alert-danger" role="alert">
           Something went wrong.
          </div>
         </div>
          
       }
            </div>
          </div>
        </div>
      </section>
    
     </>
 
     <Footer/>
 </>
    );
  }
}


export default withToast(withRouter(ProductDetails));
