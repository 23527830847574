import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store";
import SimpleReactLightbox from 'simple-react-lightbox'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { DefaultToast } from 'react-toast-notifications';
const MyCustomToast = ({ children, ...props }) => {
  console.log(props)
  return (

  <DefaultToast {...props}>
    {children}
  </DefaultToast>
)};
ReactDOM.render(
  <React.StrictMode>
    <Provider store = { store }>
    <ToastProvider components={{ Toast: MyCustomToast }}>
    <SimpleReactLightbox>
    <App />
    </SimpleReactLightbox>
    </ToastProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
