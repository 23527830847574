import React, {Component, useEffect} from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import {withRouter} from 'react-router-dom';
import {ToastProvider, useToasts} from 'react-toast-notifications';
import {

    Link
} from "react-router-dom";
import ShoppingCart from './ShoppingCart';
import Billing from './Billing';
import Payment from './Payment';
import Modal from 'react-modal';
import {BASE_URL} from '../helper';


// stripe 

import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import PaypalPayment from "./paypal";


//  stripe ends
const axios = require('axios');

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}

const customStyles = {
    content: {
        top: '40%',
        left: '40%',
        right: '40%',
        bottom: '40%',

    },
};

const queryParams = new URLSearchParams(window.location.search);

const clientSecret = queryParams.get('payment_intent_client_secret');
const payment_intent = queryParams.get('payment_intent');
// const clientSecret = new URLSearchParams(window.location.search).get(
//   "payment_intent_client_secret"
// );

// const payment_intent=new URLSearchParams(window.location.search).get(
//   "payment_intent"
// );
class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            cartItems: null,
            cartTotal: 0,
            active_button: 'cart',
            billing_address: {
                name: '',
                email: '',
                address: '',
                phone_number: '',
                country: '',
                city: '',
                zipcode: ''
            },
            payment: {},
            discount: 100,
            final_amount: 0,
            payment_type: '',
            products: [],
            cart_ids: [],
            current_step: 1,
            payment_intent: '',
            stripekey: '',
            stripesecretkey: '',
            offer_id:0,
        };
    }


    handleBillingClick = () => {
        this.props.history.push("/billing")

    };
    handleActiveButtonClick = (step) => {
        this.setState({current_step: step});

    };


    handleCallback = (childData) =>{

        // console.log(childData.offer_id);console.log(childData.cartTotal);
        this.setState({offer_id: childData.offer_id,cartTotal:childData.cartTotal});
    }
  


    handlePaymentClick = () => {
        this.props.history.push("/payment")

    };

    getCartRefresh() {
        const token = localStorage.getItem('omg_token');
        let id = localStorage.getItem('user_id');
        // let token = 'dbb2f09ec75e0af0fa9df0330c4143141dc93f78';
        // let id = 2;

        axios({
            method: 'post',
            url: BASE_URL + 'api/cart/cartlist',
            headers: {'Content-Type': 'application/json', "Authorization": `Token ${token}`},
            data: {

                "user_id": id,
            }
        })
            .then((response) => {

                this.setState({cartItems: response.data.cart_items})
                this.setState({cartTotal: response.data.total_amount})

            })
            .catch((error) => {
                console.log('er-cartlist', error);
                // this.setState({error:true});

            });
    }

    getCartItems() {
        const token = localStorage.getItem('omg_token');
        let id = localStorage.getItem('user_id');
        // let token = 'dbb2f09ec75e0af0fa9df0330c4143141dc93f78';
        // let id = 2;

        axios({
            method: 'post',
            url: BASE_URL + 'api/cart/cartlist',
            headers: {'Content-Type': 'application/json', "Authorization": `Token ${token}`},
            data: {

                "user_id": id,
            }
        })
            .then((response) => {

                this.setState({cartItems: response.data.cart_items})
                this.setState({cartTotal: response.data.total_amount})
                console.log(response)
                // payment success
                if (clientSecret != null && payment_intent != null) {

                    // alert(clientSecret);
                    console.log(localStorage.getItem('BillingAddress'), 'Addr');
                    if (localStorage.getItem('BillingAddress')) {
                        this.setState({
                            billing_address: JSON.parse(localStorage.getItem('BillingAddress')),
                        });
                    }

                    this.setState({payment_intent: payment_intent});
                    console.log()
                    this.getCartProducts();
                    this.placeOrder();


                }

            })
            .catch((error) => {
                console.log('er-cartlist', error);
                // this.setState({error:true});

            });
    }

    getCartProducts() {
        return new Promise((resolve, reject) => {
            let product_data = {};
            let productList = [];

            let cartList = [];
            this.state.cartItems.forEach((item) => {
                product_data = {
                    'product_type': item.product_type,
                    'unit_price': item.unit_price,
                    'object': item.product_type == 'object' ? item.product.id : '',
                    'material': item.product_type == 'material' ? item.product.id : '',
                    'graphics': item.product_type == 'graphics' ? item.product.id : '',
                    'plan': item.product_type == 'plan' ? item.product.id : '',
                    "seller_id": item.seller_id,
                }
                productList.push(product_data);
                cartList.push(item.id);
                this.setState({products: productList, cart_ids: cartList});
            });
            resolve(true);
        });

    }

    //  stripe


    placeOrder = (payment) => {
        this.getCartProducts().then((res) => {

            // alert(JSON.stringify(this.state.cart_ids));
            let id = localStorage.getItem('user_id');

            let token = localStorage.getItem('omg_token');

            axios({
                method: 'post',
                url: BASE_URL + 'api/checkout/place_order',

                headers: {'Content-Type': 'application/json', "Authorization": `Token ${token}`},
                data: {
                    "products": this.state.products,
                    "cart_ids": this.state.cart_ids,
                    "total_amount": this.state.cartTotal,
                    "discount": this.state.discount,
                    "final_amount": this.state.cartTotal,
                    "payment_type": "paypal",
                    "payment" : {},
                    "user": id,
                    "billing_address": this.state.billing_address,
                    "payment_intent": "",
                    "paypal_payment": payment,
                    "offer_id":this.state.offer_id,
                }

            })
                .then((response) => {

                    if (this.state.cartTotal == 0) {
                        window.location = "/downloads";
                        return;
                    }

                    console.log(response);
                    // console.log(response.response_code);
                    // console.log(response.data.response_code);
                    if (response.data.response_code == 200) {


                        const params = new URLSearchParams(window.location.search);


                        // alert(params);
                        this.setState({modalOpen: true});
                        localStorage.removeItem('BillingAddress');
                        params.delete('payment_intent_client_secret');
                        // alert(params);

                    } else {

                        this.setState({errorMsg: response.data.message});
                        this.setState({modalOpen: false});
                        // alert();
                    }


                    this.getCartRefresh();
                    //
                    // alert(JSON.stringify(response));

                })
                .catch((error) => {
                    console.log('er', error);
                    this.setState({error: true})
                });


        });


    }

    componentDidMount() {
        this.getCartItems();
        this.getStripeKey();

    }

    removeItem(id) {
        const token = localStorage.getItem('omg_token');
        axios({
            method: 'delete',
            url: BASE_URL + 'api/cart/cartitem/' + id,
            headers: {'Content-Type': 'application/json', "Authorization": `Token ${token}`},

        })
            .then((response) => {
                this.props.addToast(`item removed from cart succcessfully`, {
                    appearance: 'success',
                    autoDismiss: true,
                })
                console.log(response)

                this.getCartItems();

            })
            .catch((error) => {
                console.log('er-cartlist', error);
                // this.setState({error:true});

            });
    }

    test = (test) => {
        alert(test);
    }
    getLanguage = (language) => {
        alert(language);
    }
    updateBillingAddress = (billing_info) => {
        this.setState({billing_address: billing_info});
        localStorage.setItem('BillingAddress', JSON.stringify(billing_info));
    }
    updatePaymentInfo = (payment_info) => {
        this.setState({payment_info: payment_info});
    }

    getStripeKey() {

        const token = localStorage.getItem('omg_token');
        axios({
            method: 'get',
            url: BASE_URL + 'api/appsettings/stripe_keys',
            headers: {'Content-Type': 'application/json', "Authorization": `Token ${token}`},

        })
            .then((response) => {
                // alert(response.data.stripe_keys.test_publishable_key);
                // if(response.stripe_keys){
                // alert(response.stripe_keys.test_publishable_key);

                this.setState({'stripekey': response.data.stripe_keys.test_publishable_key});
                this.setState({'stripesecretkey': response.data.stripe_keys.test_secret_key});

                // }

            })
            .catch((error) => {
                console.log('er-cartlist', error);
                // this.setState({error:true});

            });
    }

    render() {

        return (
            <>
                <Header/>
                <>
                    <div className="checkout">
                        <Modal
                            style={customStyles}
                            className="Modal2222"
                            isOpen={this.state.modalOpen}
                            contentLabel="onRequestClose Example"
                            onRequestClose={() => {
                                this.setState({modalOpen: false})
                            }}
                            shouldCloseOnOverlayClick={true}

                        >
                            <div className="modal-checkout-message">
                                <div><h3>Your Payment was successful.</h3>
                                    {/* <p>Thankyou for your Payment.</p> */}

                                </div>
                                <Link exact to="/downloads">
                                    <button>Go To Downloads</button>
                                </Link>
                            </div>
                        </Modal>
                    </div>
                    <section className="cart">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    {/* Cart Buttons */}
                                    <div className="cartbtn">
                                        <div className="cartbtn-wrap">
                                            <button
                                                className={(this.state.current_step == 1) ? "cartbtn-item active blg" : "cartbtn-item blg"}
                                                onClick={() => this.handleActiveButtonClick(1)}>Shopping Cart
                                            </button>
                                        </div>
                                        <div className="cartbtn-wrap ">
                                            <button onClick={() => this.handleActiveButtonClick(2)}
                                                    className={(this.state.current_step == 2) ? "cartbtn-item active blg" : "cartbtn-item blg"}
                                                    disabled={this.state.current_step < 2 ? true : false}>Billing
                                                Details
                                            </button>
                                        </div>
                                        <div className="cartbtn-wrap ">
                                            {/* <a href="/PaymentStripe"><button>Payment</button></a> */}
                                            <button onClick={() => this.handleActiveButtonClick(3)}
                                                    className={(this.state.current_step == 3) ? "cartbtn-item active blg" : "cartbtn-item blg"}
                                                    disabled={this.state.current_step < 3 ? true : false}>Payment
                                                Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <p>{JSON.stringify(this.state.billing_address)}</p>
<p>{JSON.stringify(this.state.payment_info)}</p> */}
                            {this.state.current_step == 1 && (
                                <>
                                    <ShoppingCart handleActiveButtonClick={this.handleActiveButtonClick}
                                                  placeOrder={this.placeOrder}  parentCallback = {this.handleCallback} ></ShoppingCart>
                                </>
                            )}
                            {this.state.current_step == 2 && (
                                <>
                                    <Billing handleActiveButtonClick={this.handleActiveButtonClick}
                                             updateBillingAddress={this.updateBillingAddress}
                                             billingInfo={this.state.billing_address} cartTotal={this.state.cartTotal}/>
                                </>
                            )}
                            {this.state.current_step == 3 && (
                                <>
                                    <PaypalPayment {...this} updatePaymentInfo={this.updatePaymentInfo}
                                                   placeOrder={this.placeOrder} paymentInfo={this.state.payment_info}
                                                   cartTotal={this.state.cartTotal}/>
                                    {/*<Payment  updatePaymentInfo={this.updatePaymentInfo} placeOrder={this.placeOrder} paymentInfo={this.state.payment_info} cartTotal={this.state.cartTotal}  stripekey={this.state.stripekey} stripesecretkey={this.state.stripesecretkey}/>*/}
                                </>
                            )}

                        </div>
                    </section>
                </>
                {/* <ShoppingCart/>
    <Billing/> */}

                <Footer/>
            </>
        );
    }
}

export default withRouter(withToast(Cart));