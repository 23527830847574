import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {

  Link
} from "react-router-dom";
// import { BASE_URL } from '../helper';
import CheckOtp from './CheckOtp';

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import Tooltip from 'react-bootstrap/Tooltip'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Modal,Button } from 'react-bootstrap';
import AddInspire from './AddInspire';
import AddInfotainment from './AddInfotainment';
import { BASE_URL } from '../helper';
import Login from './Login'
import Signup from './Signup';
import ForgotPassword from './ForgotPassword'
import InspireProductDetail from'./InspireProductDetail';
const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
class InspireDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxDisplay:false,
      imagesToShow:"",
      cartItems:null,
      cartTotal:0,
      show_modal:false,
      add_inspire:false,
      add_infotainment:false,
      inspirations:[],
      showModal: false,
      formType:null,
      search_text:"",
      tab_index:0,
      total_items:0,
      next:null,
      previous:null,
      currentPage:1,
      perPage:20,
      pageNumbers:[],
      page:1,
      detail:false,
      selected_item:{},
      showSharePanel:false,
      copied:false,
      popular:this.props.location.state?this.props.location.state.popular:0,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.ToolTip = React.createRef();
  }
  componentDidMount(){
    this.getInspirations();
  }
  handleOpenModal= (form)=> {
    this.setState({formType:form},()=>{this.setState({ showModal: true })});
  }
  
  handleCloseModal = ()=> {
    this.setState({ showModal: false });
  }
 getInspirations(){
  const token = localStorage.getItem('omg_token');
  const user_id = localStorage.getItem('user_id');
  axios({
    method: 'post',
    url: BASE_URL+`api/inspire/inspiration_search?page=${this.state.page}`,
    headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
    data:{ 
      "search_text":this.state.search_text, 
      "popular":this.state.popular
    }
  })
  .then( (response)=> {
    // alert(JSON.stringify(response));
    this.setState({inspirations:response.data.results,total_items:response.data.count?response.data.count:0,next:response.data.next,previous:response.data.previous});
    // this.setState({completed:true});
    if(response.data.results.length >0){
      let pages = [];
      for (let i = 1; i <= Math.ceil(response.data.count / this.state.perPage); i++) {
        pages.push(i);
      }
      this.setState({pageNumbers:pages});

    }
    
  })
  .catch((error)=> {
    console.log('er',error);
  

  });
 }
 handleClick =  (event) => {
  event.preventDefault();
  this.setState({
    page: Number(event.target.id)
  },()=>{
    this.getInspirations();
  });
}
handleNextClick =  (event) => {
  event.preventDefault();
  if(this.state.next != null){

    var page = this.state.next.split('page=')[1];
    this.setState({
    page: page
  },()=>{
    this.getInspirations();
  });
  }
  
}
handlePrevClick =  (event) => {
  event.preventDefault();
  if(this.state.previous != null){

    var page = this.state.previous.split('page=')[1];
    if(!page){
      page=1;
    }
    
   
    this.setState({
    page: page
  },()=>{
    this.getInspirations();
  });
  }
  
}
onKeyUp(event) {
  if (event.charCode === 13) {
    if(event.target.value >0){
     
      this.setState({page:event.target.value},()=>{
        this.getInspirations();
      })
    }
    
    
  }
}
 addInspirationForm(){
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    // this.props.addToast(`Please Login To Continue`, {
    //   appearance: 'error',
    //   autoDismiss: true,
    // })
    this.handleOpenModal('Login');

  // this.props.history.push("/login")
  return Promise.reject();  
  }else{
    this.setState({add_inspire:true});
    
  }
 }
showAddInspire = ()=>{

  this.setState({add_inspire:!this.state.add_inspire},()=>{
    this.getInspirations();
  });
}

showAddInfotainment = ()=>{

  this.setState({add_infotainment:!this.state.add_infotainment},()=>{
    // this.getInspirations();
  });
}
likeProduct = (item)=>{
 
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
     this.props.addToast(`Please Login To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');
  }else{
    axios({
      method: 'post',
      url: BASE_URL+'api/inspire/inspire_like',
      headers: { 'Content-Type': 'application/json' },
      data:{ 
        
        "user":id,
        "inspire":item, 
      }
    })
    .then( (response)=> {
      this.props.addToast(`Inspire liked successfully`, {
        appearance: 'success',
        autoDismiss: true,
      })
      this.getInspirations();
    //  alert(JSON.stringify(response))
    //  this.setState({bookmarks:response.data.bookmark_items})
      // this.setState({completed:true});
  
      
    })
    .catch((error)=> {
      console.log('er',error);
      this.setState({error:true});
  
    });
  }
  
}
hideDetail = ()=>{

  this.setState({detail:!this.state.detail,selected_item:{}});
}
showLightBox = (image) => {
  //set imageToShow to be the one that's been clicked on   
  this.setState({imageToShow:image}) 
  // this.setState({productIDToLink:id})
  // this.setState({activeProductName:product_name})
  // this.setState({activeProductPrice:price})  
  this.setState({lightboxDisplay:true}) 

};
hideLightBox = () => {
  this.setState({lightboxDisplay:false}) 
}
showSharePanel=(item)=>{
  // alert("ok")
  // console.log('ok')
  this.setState({showSharePanel:!this.state.showSharePanel,selected_item:item});

}
  render() {
    return (
 <>
     <Header/>
     <Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>
  {/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
  </Modal.Header> */}
  <Modal.Body>
{this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
} 
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
} 
{this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}        

</Modal.Body>
  {/* <Modal.Footer>
    <Button variant="secondary" onClick={()=>this.setState({showBModal:false})}>
      Close
    </Button>
    <Button variant="primary" onClick={()=>this.setState({showBModal:false})}>
      Save Changes
    </Button>
  </Modal.Footer> */}
</Modal>

{this.state.detail && (
  <>
  <InspireProductDetail item={this.state.selected_item} hideDetail={this.hideDetail} likeProduct={()=>{this.likeProduct(this.state.selected_item.id)}}></InspireProductDetail>
  </>
)}

     { this.state.lightboxDisplay ?
            
              <div id="lightbox" onClick={this.hideLightBox}>
    <div id="lightbox-wrap">
    <span onClick={this.props.hideLightBox} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
    <img id="lightbox-img" alt="box" src={this.state.imageToShow}></img>

    </div>
    
    
  </div>
: '' }
     <>
     <section className="inspire-cont">
          <div className="insp-banner">
            <img src="../assets/images/dreambg.png" width="100%" height="100%" />
            <div className="insp-banner-text">
            <img src="../assets/images/inspire_banner_text.png"  />
            </div>
            <div className="insp-banner-dots">
            <img src="../assets/images/dots_red.png"  />
            </div>
          </div>
        </section>
        {/* Inspire Section */}
        <section className="inspire">
          <div className="container-fluid">
            <div className="row">
              {/* Start col-9 */}
              <div className="col-xl-12 col-md-12 px-4">
                <div className="ibw">
                  {/* Left */}
                  <ul className="nav nav-products products-tab" id="products-tab" role="tablist">
                    <li className="nav-item">
                      <a className={this.state.popular ==0?"nav-link active btn-tabs":"nav-link  btn-tabs"} id="products-home-tab" data-toggle="pill" href="#products-home" role="tab" aria-controls="products-home" aria-selected="true" onClick={()=>{this.setState({tab_index:0,popular:0},()=>{this.getInspirations()})}}>All</a>
                    </li>
                    <li className="nav-item">
                      <a className={this.state.popular ==1?"nav-link active btn-tabs":"nav-link  btn-tabs"} id="products-profile-tab" data-toggle="pill" href="#products-profile" role="tab" aria-controls="products-profile" aria-selected="false" onClick={()=>{this.setState({tab_index:1,popular:1},()=>{this.getInspirations()})}}>Popular</a>
                    </li>
                    <li className="nav-item">
                    < Link exact to="/infotainment" className="nav-link  btn-tabs" id="products-contact-tab" data-toggle="pill" href="#products-contact" role="tab" aria-controls="products-contact" aria-selected="false" >Infotainment</Link>
                    </li>
                  </ul>
                  {/* Right */}
                  <div className="ibwr">
                    <div className="cartbtn-wrap searchw">
                      <input type="text" name="search"  className="searchinp" value={this.state.search_text} onChange={(e)=>{this.setState({search_text:e.target.value})}}/>
                      <button className="searchb"><span className="fa fa-search" onClick={()=>{this.getInspirations()}} /></button>
                    </div>
                    <button className="inspbtn open-popup-link" href="#insp-popup1" onClick={()=>{this.addInspirationForm()}}>Add Inspiration</button>
                  </div>
                </div>
                {/*Product Breadcrump*/}
                {/* <div className="prod_breadcrum tiny_desc">Showing 12 of 1384</div> */}
                <div className="tab-content mt-4 " id="products-tabContent">
                  <div className="tab-pane fade show active" id="products-home" role="tabpanel" aria-labelledby="products-home-tab">
                    {/* Start Wrapper */}
                    <div className="d-flex flex-wrap flex-row ">
                     {this.state.inspirations.length > 0 && this.state.inspirations.map((item)=>{
                       return(
                         <>
                          <div className="card prod-item  m-2">
                        <a className="link"
                        //  onClick={() => this.showLightBox(`http://165.22.212.233:8002${item.image}`)} 
                        onClick={()=>{this.setState({detail:true,selected_item:item})}}
                         ><img src={BASE_URL.slice(0, -1)+`${item.image}`} className="card-img-top" alt="" /></a>
                         
                        {item.is_liked == 0 && (
                          <img src="../assets/images/th.png" className="pbadge1" alt="" onClick={()=>this.likeProduct(item.id)}/>
                        )}
                        {item.is_liked == 1 && (
                          <img src="../assets/images/th1.png" className="pbadge1" alt="" onClick={()=>this.likeProduct(item.id)}/>
                        )}
                        
                        <img src="../assets/images/sh.png" className="pbadge2" alt="" onClick={()=>{this.showSharePanel(item)}}/>
                        <div class={(this.state.showSharePanel && this.state.selected_item==item)? "social-slide-in from-right show":"social-slide-in from-right"}>
  <div class="slide-in-content share-icons">
    <ul>
      <li>  <TwitterShareButton
             openShareDialogOnClick={true}
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
          <i class="fab fa-twitter"></i>
          </TwitterShareButton></li>
      <li>  <FacebookShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
           <i class="fab fa-facebook-f"></i>
          </FacebookShareButton></li>
      <li>  <PinterestShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
         <i class="fab fa-pinterest-p"></i>
          </PinterestShareButton></li>
          <li>  <EmailShareButton
            url={'http://twitter.com/'}
            title={"Test Title"}
            className="Demo__some-network__share-button"
          >
        <i class="far fa-envelope"></i>
          </EmailShareButton></li>
          <li> 
          <CopyToClipboard text={"this.state.value"}
          onCopy={() => this.setState({copied: true})}>

    
            <i class="far fa-copy" ref={this.ToolTip}></i>
         
     
        </CopyToClipboard>
        {/* {this.state.copied &&
        <span style={{backgroundColor:'green',padding:5,color:"#fff",alignSelf:'center'}}>Copied</span>
        } */}
          
          </li>
         
    </ul>
  </div>
</div>
                        <div className="card-body">
                          <div className="category">
                            <div className="c1 ">{item.upload_by} </div>
                            {/* <div className="c2">Category Name</div> */}
                          </div>
                         <a onClick={()=>{this.setState({detail:true,selected_item:item})}}> <h5 className="card-title add-pointer" >{item.title}</h5></a>
                          {/* <div className="desc">{item.description}</div> */}
                          {/* <div className="head1">Elements used</div> */}
                          {/* {item.elements_link.length > 0 && (
                            <>
                              <div className="tags">
                                {item.elements_link.map((x, i)=>{
                                  if(x.element_name && x.element_name != ""){
                                    return(
                                      
                                      <a href={x.element_link !=""?x.element_link:"#"} target="_blank"><div className="tag-item m-1">
   <div className="tag-item1">{x.element_name}</div>
  </div>
  </a>
  
                                    )
                                  }else{
                                    return null
                                  }
                                  
                                })}
                           </div>
                           
                          
                            </>
                          )} */}
                        
                        </div>
                      </div>
                         </>
                       )
                     })}
                     {this.state.inspirations.length ==0 && (
                       <>
                        <div className="col-md-12 info-msg">No content found..</div>
                       </>
                     )}
                     


                   
                    </div>
                    {/* End Wrapper */}
                  </div>
                  <div className="tab-pane fade" id="products-profile" role="tabpanel" aria-labelledby="products-profile-tab">
                  <div className="d-flex flex-wrap justify-content-around flex-row">
        {/* Product */}
        <div className="card prod-item">
          <a className="link" href="../assets/images/220.png"><img src="../assets/images/r76.png" className="card-img-top" alt="" /></a>
          <img src="../assets/images/th.png" className="pbadge1" alt="" />
          <img src="../assets/images/sh.png" className="pbadge2" alt="" />
          <div className="card-body">
            <div className="category">
              <div className="c1">John Wik |</div>
              <div className="c2">Category Name</div>
            </div>
            <h5 className="card-title">Eames style Longue chair &amp; Ottoman Premium, Chair and Ottoman Premium</h5>
            <div className="desc">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </div>
            <div className="head1">Elemets used</div>
            <div className="tags">
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="card prod-item">
          <a className="link" href="../assets/images/220.png"><img src="../assets/images/r76.png" className="card-img-top" alt="" /></a>
          <img src="../assets/images/th.png" className="pbadge1" alt="" />
          <img src="../assets/images/sh.png" className="pbadge2" alt="" />
          <div className="card-body">
            <div className="category">
              <div className="c1">John Wik |</div>
              <div className="c2">Category Name</div>
            </div>
            <h5 className="card-title">Eames style Longue chair &amp; Ottoman Premium, Chair and Ottoman Premium</h5>
            <div className="desc">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </div>
            <div className="head1">Elemets used</div>
            <div className="tags">
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="card prod-item">
          <a className="link" href="../assets/images/220.png"><img src="../assets/images/r76.png" className="card-img-top" alt="" /></a>
          <img src="../assets/images/th.png" className="pbadge1" alt="" />
          <img src="../assets/images/sh.png" className="pbadge2" alt="" />
          <div className="card-body">
            <div className="category">
              <div className="c1">John Wik |</div>
              <div className="c2">Category Name</div>
            </div>
            <h5 className="card-title">Eames style Longue chair &amp; Ottoman Premium, Chair and Ottoman Premium</h5>
            <div className="desc">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </div>
            <div className="head1">Elemets used</div>
            <div className="tags">
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="card prod-item">
          <a className="link" href="../assets/images/220.png"><img src="../assets/images/r76.png" className="card-img-top" alt="" /></a>
          <img src="../assets/images/th.png" className="pbadge1" alt="" />
          <img src="../assets/images/sh.png" className="pbadge2" alt="" />
          <div className="card-body">
            <div className="category">
              <div className="c1">John Wik |</div>
              <div className="c2">Category Name</div>
            </div>
            <h5 className="card-title">Eames style Longue chair &amp; Ottoman Premium, Chair and Ottoman Premium</h5>
            <div className="desc">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </div>
            <div className="head1">Elemets used</div>
            <div className="tags">
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
              <div className="tag-item">
                <div className="tag-item1">Bed Miniotti Reeves(Creed)</div>
              </div>
            </div>
          </div>
        </div>
      
        {/* Product */}
      </div>
                  </div>
                  <div className="tab-pane fade" id="products-contact" role="tabpanel" aria-labelledby="products-contact-tab">
                    <div className="col-md-12 info-msg">No content found..</div>
                  </div>
                  <div className="col-xl-12 col-md-12 col-sm-12 ml-auto  d-flex flex-wrap flex-row justify-content-between pagination-wrapper">
                {/* Page no */}
                <div className="pagination-count goto">
                <span >{`${this.state.total_items} inspires available`}</span>
                  
                  {/* Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp}/> */}
                  </div>
                {/* Pagination */}
                <nav aria-label="pagination">
         
                  <ul className="pagination">
                  <li className="page-item " style={{marginRight:40}}>
                  Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp}/> <span> of {this.state.pageNumbers[this.state.pageNumbers.length-1]}</span> 
                    </li>
                    <li className="page-item ">
                      <a className="page-link prev" href="#" aria-label="Previous" onClick={this.handlePrevClick}>
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {this.state.pageNumbers.map((val)=>
 <li className={this.state.page == val ?"page-item active":"page-item"}><a className="page-link" href="#" id={val} onClick={this.handleClick}>{val}</a></li>
                    )}
                   
                    {/* <li className="page-item "><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                    <li className="page-item ">
                      <a className="page-link next" href="#" aria-label="Next"  onClick={this.handleNextClick} >
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              

                </div>
              </div>
              {/* End col-9*/}
            </div>
            {/* End container*/}
          </div>
          {/* End Row*/}
        </section>
        {/* End Shop Section*/}
        {/* Pagination */}
     </>
     {this.state.add_inspire && (<>
     <AddInspire showAddInspire={this.showAddInspire}/>
     </>)}
     {this.state.add_infotainment && (<>
     <AddInfotainment showAddInfotainment={this.showAddInfotainment}/>
     </>)}
     <Footer/>
 </>
    );
  }
}
export default withRouter(withToast(InspireDetails));