import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import { cartDetails,bookmarkItems,setSearchKey,setSearchUrl } from '../action';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Rating from "react-rating";
// import SlideToggle from "react-slide-toggle";
import {

  Link
} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { BASE_URL } from "../helper";
import { GetAssetList,GetCategories,GetStyles,GetColors,GetForms } from "../utils/common";
import Login from './Login'
// import Modal from 'react-modal';
import { Modal,Button } from 'react-bootstrap';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword'
import LightBox from '../widgets/LightBox';
import CheckOtp from './CheckOtp';

// const customStyles = {
//   content: {
//     top: '10%',
//     left: '10%',
//     right: '10%',
//     bottom: '10%',

//   },
// };
// Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.6';
const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
class Objects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objects:null,
      lightboxDisplay:false,
      imagesToShow:[],
      productIDToLink:"",
      activeProductName:"Product",
      activeProductPrice:"100",
      seller_id: "",
      bookmarks:[],
      searchOption:"#",
      category:[],
      material:[],
      platform:[],
      render:[],
      styles:[],
      style_ids:[],
      sort_ids:[],
      colors:[],
      color_ids:[],
      forms:[],
      form_ids:[],
      category_filter:[],
      asset_list:[],
      asset_ids:[],
      material_ids:[],
      platform_ids:[],
      render_ids:[],
      sub_cat_ids:[],
      category_ids:[],
      showModal: false,
      formType:null,
      showBookmarkModal:false,
      total_items:0,
      next:null,
      previous:null,
      currentPage:1,
      perPage:20,
      pageNumbers:[],
      page:1,
      toggleEvent:false,
      user_id:'',
      favorite:"0",
      search_text:this.props.location.state?this.props.location.state.key:'',
      search_url:this.props.location.state?this.props.location.state.url:'/objects',
      is_favourite:0,

    };
    this.myRef = React.createRef();
    this.bookmarkButtonRef = React.createRef();
    this.category_handler = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleOptionClick=this.handleOptionClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);

  }
  handleOpenModal= (form)=> {
    this.setState({formType:form},()=>{this.setState({ showModal: true })});
  }

  handleCloseModal = ()=> {
    this.setState({ showModal: false });
  }
  searchOptionHandler=(event)=>{
    this.props.setSearchUrl(event.target.value);
  }
  enterKeyPressed=(event)=>{
    // console.log(event.keyCode)
    if (event.keyCode == 13) {
      this.handleOptionClick();
    }
  }


  handleOptionClick = () => {
    // this.props.history.push(this.state.searchOption)
    if(this.props.search.url !="/objects" &&  this.props.search.url !="" && this.props.search.url !="#"){
      this.props.history.push({
        pathname: this.props.search.url,
        state: { key: this.props.search.key,url: this.props.search.url}
    });
    }else{

      this.getProducts();
    }

  };
  showModal=()=>{
    this.myRef.current.classList.add('show')
    this.bookmarkButtonRef.current.classList.remove('show-bookmark-animation')
  }
  hideModal=()=>{
    this.myRef.current.classList.remove('show')
    // this.bookmarkButtonRef.current.classList.add('show-bookmark-animation')
  }
  getFilterItems(){

    let token = localStorage.getItem('omg_token');
  // console.log(token)

    return new Promise((resolve,reject)=>{
      axios({
        method: 'get',
        url: BASE_URL+'api/product/filter_items',
        headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
      })
      .then( (response)=> {
        let assetData = response.data.assets;
        assetData = assetData.filter((item) => item.product_type == "objects").map(({ id, asset_type }) => ({ id, asset_type }));

        this.setState({category:response.data.category,material:response.data.material,styles:response.data.style,platform:response.data.platform,render:response.data.render,asset_list:assetData},()=>{
          resolve(true);
        });
          // alert(JSON.stringify(response));

      })
      .catch((error)=> {
        console.log(error);
        reject(true);

      });
    });

  }
componentDidMount(){
  const self = this;
  // const node = this.myRef.current;
  // this.myRef.current.classList.add('show')
  // this.myRef.current.display='block';
  // console.log(this.myRef.current.classList)

  // this.modal('show');
  // setTimeout(()=>{this.myRef.current.classList.add('show')},3000)

  // let token='e4bc9dfe25328197be7ae7bd230c0a2434e8ca43';
  const token = localStorage.getItem('omg_token');
  let id = localStorage.getItem('user_id');



//   axios({
//     method: 'post',
//     url: 'http://165.22.212.233:8002/api/product/objectlist',
//     headers: { 'Content-Type': 'application/json' },
//     data:{ "filter": { "object_asset_ids":[],
//     "category":[{"category_id":"","sub_category_ids":[]},{"category_id":"","sub_category_ids":[]}
//                 ],
//     "style_ids":[],
//     "color_ids":[],
//     "form_ids":[]

// }}
//   })
//   .then( (response)=> {

//     this.setState({objects:response.data.objects})
//     this.setState({completed:true});


//   })
//   .catch((error)=> {
//     console.log('er',error);
//     this.setState({error:true});

//   });

Promise.all([ this.getFilterItems()]).then((result)=>{
  this.getProducts();
}).catch((err)=>{
  console.log("error",err);
  this.getProducts();
})
this.props.setSearchUrl("/objects");
// this.getBookmarks(token,id);

this.$el = window.jQuery(this.el);
this.$el.select2({
  dropdownCssClass: "custom-dropdown",
  val:self.props.search.url

}).on('change', function(e) {
  //  self.setState({search_url:'dddd'});
  // search_url=e.target.value;
  // this.el.onChange();
   self.props.setSearchUrl(e.target.value);
  // this.props.setSearchUrl("test");
  //  alert(e.target.value)
 });

}

getBookmarks=(token,id)=>{
  axios({
    method: 'post',
    url: BASE_URL+'api/cart/bookmarklist',
    headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
    data:{
      "user_id":id,
    }
  })
  .then( (response)=> {
    console.log("fav",response)
    this.setState({bookmarks:response.data.bookmark_items})
    // this.setState({completed:true});


  })
  .catch((error)=> {
    console.log('er',error);
    this.setState({error:true});

  });

}
getAssetList() {
  GetAssetList()
    .then((res) => {
      let data = res;
      data = data.filter((item) => item.product_type == "objects").map(({ id, asset_type }) => ({ id, asset_type }));
      this.setState({ asset_list: data });
    })
    .catch((err) => {
      this.setState({ error: true });
    });


}
getProducts=()=>{
  const token = localStorage.getItem('omg_token');
  let id = localStorage.getItem('user_id');

  let is3dprintable="";
  if(this.state.sort_ids.includes(4)){
    is3dprintable=1;
  }




  axios({
    method: 'post',
    url: BASE_URL+`api/product/objectlist?page=${this.state.page}`,
    headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
    data:{ "filter": {
    "object_asset_ids":this.state.asset_ids,
    "category":this.state.category_filter,
    "style_ids":this.state.style_ids,
    "material_ids":this.state.material_ids,
    "platform_ids":this.state.platform_ids,
    "render_ids":this.state.render_ids,
    "three_d_printable":is3dprintable,


},
  "search_text":this.props.search.key,
  "user_id":this.state.user_id,
    "favourite":this.state.favorite,
  }
  })
  .then( (response)=> {
    console.log(response.data.results)
    this.setState({objects:response.data.results,total_items:response.data.count?response.data.count:0,completed:true,next:response.data.next,previous:response.data.previous});
    if(response.data.results.length >0){
      let pages = [];
      for (let i = 1; i <= Math.ceil(response.data.count / this.state.perPage); i++) {
        pages.push(i);
      }
      this.setState({pageNumbers:pages});
      this.handleScroll()
    }



  })
  .catch((error)=> {
    console.log('er',error);
    this.setState({error:true});

  });

}
handleScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
}

getCategories = () => {

  GetCategories()
  .then((res) => {
    // console.log("category:",res);
    this.setState({ category: res });
  })
  .catch((err) => {
    this.setState({ error: true });
  });




};



getStyles = () => {
  GetStyles()
  .then((res) => {
    // console.log("style:",res);
    this.setState({ styles: res });
  })
  .catch((err) => {
    this.setState({ error: true });
  });


};
getColors = () => {
  GetColors()
  .then((res) => {
    // console.log("color:",res);
    this.setState({ colors: res });
  })
  .catch((err) => {
    this.setState({ error: true });
  });

};
getForms = (token) => {
  GetForms()
    .then((res) => {
      // console.log("form:",res);
      this.setState({ forms:res });
    })
    .catch((err) => {
      this.setState({ error: true });
    });

};


showLightBox = (images,id,product_name,price,favourite, seller_id) => {
  //set imageToShow to be the one that's been clicked on
  this.setState({imagesToShow:images,productIDToLink:id,activeProductName:product_name,activeProductPrice:price,lightboxDisplay:true,is_favourite:favourite, seller_id: seller_id});


};
setCategoryCheck(category){
  const found = this.state.category_filter.some(el => el.category_id === category);
  if(found){
    return true;
  }else{
    return false;
  }
}
setSubcategoryCheck(category,subcategory){
  if(this.state.category_ids.includes(category)){


  if(this.state.category_filter.length > 0){
    this.state.category_filter.forEach(item =>{
      if(item.category_id === category){
        if(item.sub_category_ids.includes(subcategory)){
          return true;

       }else{
        return false;
       }



      }else{
        return false;
      }

    })
  }
}else{
  return false;
}
}
setStyleCheck(styleid){
  if(this.state.style_ids.includes(styleid)){
    return true;

 }else{
  return false;
 }

}
setSortCheck(id){
  if(this.state.sort_ids.includes(id)){
    return true;

 }else{
  return false;
 }

}
setMaterialCheck(materialid){
  if(this.state.material_ids.includes(materialid)){
    return true;

 }else{
  return false;
 }

}
setPlatformCheck(id){
  if(this.state.platform_ids.includes(id)){
    return true;

 }else{
  return false;
 }

}
setRenderCheck(id){
  if(this.state.render_ids.includes(id)){
    return true;

 }else{
  return false;
 }

}
// deleteBookmarks=async(productID)=>{
//   console.log("done")
//   let id = localStorage.getItem('user_id');
//   let token = localStorage.getItem('omg_token');
//   console.log(token)
//   if(token === "" || token === null)
//   {
//     this.props.addToast(`Please Login To Continue`, {
//       appearance: 'error',
//       autoDismiss: true,
//     })
//     this.handleOpenModal('Login');

//   // this.props.history.push("/login")
//   return Promise.reject();
//   }else{
//     return await axios({
//       method: 'delete',
//       url: `${BASE_URL}api/cart/bookmark/${productID}/`,
//       headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },

//     })
//     .then( (response)=> {
//        this.props.addToast(`${this.state.activeProductName} removed from bookmarks`, {
//       appearance: 'success',
//       autoDismiss: true,
//     })

//     //  console.log(response)
//       this.getBookmarks(token,id)
//       return Promise.resolve(response);


//     })
//     .catch((error)=> {

//       return Promise.reject(error);

//     });
//   }


// }


addToBookMark=async(productIDToLink)=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');

  // this.props.history.push("/login")
  return Promise.reject();
  }else{

    if(this.props.bookmarkItems.length >= 20){
      this.props.addToast(`Sorry,Your limit exceed only 20 product is allowed in bookmark`, {
        appearance: 'warning',
        autoDismiss: true,
      })
    }else{
      return await axios({
        method: 'post',
        url: BASE_URL+'api/cart/bookmark/',
        headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
        data: {
          "product_type":"object",
          "object":productIDToLink,
          "material":"",
          "graphics":"",
          "user_id":id,


        }
      })
      .then( (response)=> {
         this.props.addToast(`${this.state.activeProductName} added to bookmarks`, {
        appearance: 'success',
        autoDismiss: true,
      })

       console.log(response)
      //  this.showModal();
      this.getCategoryState();
      this.props.updateBookmarkItems();
        return Promise.resolve(response);


      })
      .catch((error)=> {
        this.getCategoryState();
      this.props.updateBookmarkItems();
        return Promise.reject(error);

      });
    }

  }


}
addToCart=async(productIDToLink, seller_id)=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');
  // this.props.history.push("/login")
  return Promise.reject();
  }else{
    return await axios({
      method: 'post',
      url: BASE_URL+'api/cart/cartitem/',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },

      data: {cart_items:[{
        "product_type":"object",
        "object":productIDToLink,
        "material":"",
        "graphics":"",
        "user_id":id,
        "seller_id": seller_id,
        "unit_price":this.state.activeProductPrice,
        "quantity":"1"
      }]}

    })
    .then( (response)=> {
       this.props.addToast(`${this.state.activeProductName} added to cart`, {
      appearance: 'success',
      autoDismiss: true,
    })

      this.props.updateCartDetails();
      this.props.updateBookmarkItems();
      return Promise.resolve(response);


    })
    .catch((error)=> {

      return Promise.reject(error);

    });
  }


}
addStyleId=(item)=>{

  if (this.state.style_ids.includes(item.id)) {
    const newArray = this.state.style_ids.filter(e => e !== item.id);
    this.setState({style_ids:newArray},()=>{
      this.getProducts();
    });

} else {
    const  newArray = [...this.state.style_ids, item.id];
    this.setState({style_ids:newArray},()=>{
      this.getProducts();
    });
}



}
addSortId=(id)=>{

  if (this.state.sort_ids.includes(id)) {
    const newArray = this.state.sort_ids.filter(e => e !== id);
    this.setState({sort_ids:newArray},()=>{
      this.getProducts();
    });

} else {
    const  newArray = [...this.state.sort_ids, id];
    this.setState({sort_ids:newArray},()=>{
      this.getProducts();
    });
}



}
addColorId=(item2)=>{

  if (this.state.color_ids.includes(item2.id)) {
    const newArray2 = this.state.color_ids.filter(e => e !== item2.id);
    this.setState({color_ids:newArray2},()=>{
      this.getProducts();
    });

} else {
    const  newArray2 = [...this.state.color_ids, item2.id];
    this.setState({color_ids:newArray2},()=>{
      this.getProducts();
    });
}


// alert(JSON.stringify(this.state.style_ids));
}
addFormId=(item)=>{

  if (this.state.form_ids.includes(item.id)) {
    const newArray3 = this.state.form_ids.filter(e => e !== item.id);
    this.setState({form_ids:newArray3},()=>{
      this.getProducts();
    });

} else {
    const  newArray3 = [...this.state.form_ids, item.id];
    this.setState({form_ids:newArray3},()=>{
      this.getProducts();
    });
}





// alert(JSON.stringify(this.state.style_ids));
}
addMaterialId=(item)=>{

  if (this.state.material_ids.includes(item.id)) {
    const newMatArray = this.state.material_ids.filter(e => e !== item.id);
    this.setState({material_ids:newMatArray},()=>{
      this.getProducts();
    });

} else {
    const  newMatArray = [...this.state.material_ids, item.id];
    this.setState({material_ids:newMatArray},()=>{
      this.getProducts();
    });
}
}
addPlatformId=(item)=>{

  if (this.state.platform_ids.includes(item.id)) {
    const newPlatArray = this.state.platform_ids.filter(e => e !== item.id);
    this.setState({platform_ids:newPlatArray},()=>{
      this.getProducts();
    });

} else {
    const  newPlatArray = [...this.state.platform_ids, item.id];
    this.setState({platform_ids:newPlatArray},()=>{
      this.getProducts();
    });
}
}
addRenderId=(item)=>{

  if (this.state.render_ids.includes(item.id)) {
    const newRenArray = this.state.render_ids.filter(e => e !== item.id);
    this.setState({render_ids:newRenArray},()=>{
      this.getProducts();
    });

} else {
    const  newRenArray = [...this.state.render_ids, item.id];
    this.setState({render_ids:newRenArray},()=>{
      this.getProducts();
    });
}
}
setAsset=(id)=>{
  let assetId=[];
  if(id != 0 ){
    assetId.push(id);
  }


  this.setState({asset_ids:assetId,favorite:"0"},()=>{
    this.getProducts();
  });

  }
  addCategoryFilter=(item)=>{


    const found = this.state.category_filter.some(el => el.category_id === item);
    if (!found){
      this.setState({category_filter:[...this.state.category_filter,{category_id:item,sub_category_ids:[]}],category_ids:[...this.state.category_ids,item]},()=>{
        this.getProducts();
      });
    }else{
      const filteredCategory = this.state.category_filter.filter(category => category.category_id !== item);
      const filteredCategory2 = this.state.category_ids.filter(category1 => category1 !== item);
    this.setState({
      category_filter:filteredCategory,
      category_ids:filteredCategory2
    },()=>{
      this.getProducts();
    });
    }

    }

    addSubcategoryToFilter=(category,subcategory)=>{
      if(this.state.category_filter.length > 0){
        this.state.category_filter.forEach(item =>{
          if(item.category_id === category){
            if(item.sub_category_ids.includes(subcategory)){
              item.sub_category_ids = item.sub_category_ids.filter(item2 => item2 !== subcategory);



           }else{
            item.sub_category_ids.push(subcategory);
           }
           if(this.state.sub_cat_ids.includes(subcategory)){
            let subids=this.state.sub_cat_ids.filter(item22 => item22 !== subcategory);
            this.setState({sub_cat_ids:subids});
           }else{
            this.setState({sub_cat_ids:[...this.state.sub_cat_ids,subcategory]});
           }



            const filteredCategory = this.state.category_filter.filter(category => category.category_id !== category);

    this.setState({
      category_filter:filteredCategory
    },()=>{
      this.getProducts();
    });

          }else{

            if(!this.state.category_ids.includes(category)){
              this.setState({category_ids:[...this.state.category_ids,category]});
            }
            this.setState({category_filter:[...this.state.category_filter,{category_id:category,sub_category_ids:[subcategory]}],sub_cat_ids:[...this.state.sub_cat_ids,subcategory]},()=>{
              this.getProducts();
            });
          }

        })
      }else{
        this.setState({category_filter:[...this.state.category_filter,{category_id:category,sub_category_ids:[subcategory]}],sub_cat_ids:[...this.state.sub_cat_ids,subcategory],category_ids:[...this.state.category_ids,category]},()=>{
          this.getProducts();
        });
      }



      }
hideLightBox = () => {
  this.setState({lightboxDisplay:false})
}
hideBookmark=()=>{
  this.inputNode.classList.remove('show');
}
getCategoryState = () => {
  // console.log(this.inputNode)
  this.inputNode.classList.add('show');
  // return this.category_handler.current.getState();
};
handleClick =  (event) => {
  event.preventDefault();
  this.setState({
    page: Number(event.target.id)
  },()=>{
    this.getProducts();
  });
}
handleNextClick =  (event) => {
  event.preventDefault();
  if(this.state.next != null){

    var page = this.state.next.split('page=')[1];
    this.setState({
    page: page
  },()=>{
    this.getProducts();
  });
  }

}



handlePrevClick =  (event) => {

  event.preventDefault();
  if(this.state.previous != null){

    var page = this.state.previous.split('page=')[1];
    if(!page){
      page=1;
    }


    this.setState({
    page: page
  },()=>{
    this.getProducts();

  });
  }


}
onKeyUp(event) {
  if (event.charCode === 13) {
    if(event.target.value >0){

      this.setState({page:event.target.value},()=>{
        this.getProducts();

      })
    }


  }
}
refresh(){
  this.getProducts();
}
favouriteClick = ()=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');
  // this.props.history.push("/login")
  return Promise.reject();
  }else{
    this.setState({user_id:id,favorite:"1",asset_ids:[]},()=>{
      this.getProducts();
    });
  }
}
handleInputChange = (e) =>{
  this.setState({search_text:e.target.value});

}
  render() {
    const {search_url}=this.state;
    const capitalizeFirst = str => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };


    return (
 <>
     <Header showBookmarkModal={this.state.showBookmarkModal}  hideBookmark={()=>this.hideBookmark()} inputRef={node => this.inputNode = node} ref={this.category_handler} url="/objects" getProducts={this.getProducts}/>
     <>

     <Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>
  {/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
  </Modal.Header> */}
  <Modal.Body>
{this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
{this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}


</Modal.Body>
  {/* <Modal.Footer>
    <Button variant="secondary" onClick={()=>this.setState({showBModal:false})}>
      Close
    </Button>
    <Button variant="primary" onClick={()=>this.setState({showBModal:false})}>
      Save Changes
    </Button>
  </Modal.Footer> */}
</Modal>






     {/* <div onClick={()=>this.showModal()} ref={this.bookmarkButtonRef} className="show-bookmark ">
        <div className="txt">Show Bookmarks</div>
          <span className="show-bookmark-close" ><img className="close" src={`${process.env.PUBLIC_URL}/assets/images/cross.png`} /></span>
      </div> */}



     { this.state.lightboxDisplay ?
             <LightBox hideLightBox={()=>this.hideLightBox()} activeProductName={this.state.activeProductName} imagesToShow={this.state.imagesToShow} productIDToLink={this.state.productIDToLink} addToBookMark={(val)=>this.addToBookMark(val)}  addToCart={(val)=>this.addToCart(val, this.state.seller_id)} product_type={'object'} isFavourite={this.state.is_favourite} getProduct={()=>this.refresh()}/>
: '' }




        {/* Search Section */}

      <section className="search-container">
        <img src={`${process.env.PUBLIC_URL}/assets/images/orange.png`}  width="100%" height="337px" className="common-bg" />
        <div className="container-fluid bgsearch">
          <div className="row">
            <div className="col-sm-12 col-lg-6 mx-auto p-5 mt-5 searchbox">
              <div className="form-group has-search">
              <select className="search-dropdwn" ref={el => this.el = el} >
              <option value="/objects" selected={true} >Objects</option>
                    <option value="/materials" > Materials</option>
                    <option value="/graphics" >Graphics</option>

                  </select>
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="Get Casting..."
                    value={this.props.search.key}
                    onChange={(e)=>{this.props.setSearchKey(e.target.value)}}
                    onKeyDown={(event)=>{this.enterKeyPressed(event)}}
                    />
                  <button onClick={()=>this.handleOptionClick()}><span className="fa fa-search form-control-feedback" /></button>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* Shop Section */}
        <section className="shoparea">
          <div className="container-fluid">
            <div className="row">

            <div className="side-panel">
              {/* Start col-3 */}
              <div className="col-xl-12 col-md-12 px-4 order-2 order-md-1">
                <div className="shop-sidebar card">
                  <div className="card-header py-3 d-flex flex-row justify-content-between">
                    <span className="heading_text1">Filters</span>
                    {/* <button onClick={()=>this.getCategoryState()}>show me</button>  */}
                    <span className="heading_text2" onClick={()=>{this.setState({category_filter:[],style_ids:[],material_ids:[],platform_ids:[],render_ids:[],sort_ids:[],sub_cat_ids:[],category_ids:[]},()=>{
                      this.getProducts();
                    })}}>Clear All</span>
                  </div>
                  {/* Sort Section */}
                  <div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Sort</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.sort_ids.length > 0 ){
                      this.setState({sort_ids:[]},()=>{
                        // this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  <li className={this.setSortCheck(1) ? ("chk-item active") : ("chk-item")} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setSortCheck(1)} onClick={() => this.addSortId(1)} />
                            <label>Most Downloaded</label>

                          </div>
                        </li>
                        <li className={this.setSortCheck(2) ? ("chk-item active") : ("chk-item")} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setSortCheck(2)} onClick={() => this.addSortId(2)}  />
                            <label>Recently Uploaded</label>

                          </div>
                        </li>
                        {/* <li className={this.setImagesizeCheck(item.id) ? ("chk-item active") : ("chk-item")} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setSortCheck(3)} onClick={() => this.addSortId(3)}  />
                            <label>Marvel of Week</label>

                          </div>
                        </li> */}
                        <li className={this.setSortCheck(4) ? ("chk-item active") : ("chk-item")} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setSortCheck(4)} onClick={() => this.addSortId(4)} />
                            <label>3D Printable</label>

                          </div>
                        </li>

                  </ul>
                  {/* Categories Section */}
                  <div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">All Categories</span>
                    <span className="heading_text4" onClick={()=>{this.setState({category_filter:[],sub_cat_ids:[],category_ids:[]},()=>{this.getProducts()})}}>Clear</span>
                  </div>
                  {/* <button data-toggle="collapse" data-target="#demo">Collapsible</button>

<div id="demo" class="collapse">
Lorem ipsum dolor text....
</div> */}
                  <ul className="checkbox-menu">
                  {
      Object.keys(this.state.category).map((cat,index )=> {
        if(this.state.category[cat].sub_category.length > 0){
          return (
            // <li className="chk-item dropdown">
            <li className={this.setCategoryCheck(this.state.category[cat].id) ? ("chk-item active dropdown") : ("chk-item dropdown")}>
              <span className="textspace" />
            <input type="checkbox" checked={this.setCategoryCheck(this.state.category[cat].id)}  onClick={()=>{this.addCategoryFilter(this.state.category[cat].id);}} />  <label className="dropdown-toggle" data-toggle="collapse" data-target={`#cat-${this.state.category[cat].id}`}>{this.state.category[cat].category_name}</label>
              <ul className="checkbox-submenu collapse" id={`cat-${this.state.category[cat].id}`}>
              {
            this.state.category[cat].sub_category.map(item => {
              return(
                <li className="chk-sub-item deactive">
                <div className="checkbox d-flex flex-row">
                <span className="textspace" />
                  <input type="checkbox" checked={this.setSubcategoryCheck(this.state.category[cat].id,item.id)} onClick={()=>{this.addSubcategoryToFilter(this.state.category[cat].id,item.id)}} />
                  <label>{item.subcategory_name}</label>
                  {/* <label className="text-count">(124)</label> */}
                </div>
              </li>
              )
            })
          }
                </ul>
              </li>
          )
        }else{
          return (
            <li className={this.setCategoryCheck(this.state.category[cat].id) ? ("chk-item active") : ("chk-item")}><label><input type="checkbox" checked={this.setCategoryCheck(this.state.category[cat].id)}  onClick={()=>{this.addCategoryFilter(this.state.category[cat].id);}} />{this.state.category[cat].category_name}</label></li>
          )
        }

      })
    }
</ul>
                  {/* Style Section */}
                  {this.state.styles.length > 0 && (
                    <>
<div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Style</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.style_ids.length > 0 ){
                      this.setState({style_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.styles.map((item)=>{
                      return (
                        <li className={this.setStyleCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                            <div className="checkbox d-flex flex-row">
                              <span className="textspace" />
                              <input type="checkbox" checked={this.setStyleCheck(item.id)}  onClick={() => this.addStyleId(item)}  />
                                <label>{capitalizeFirst(item.style_name)}</label>
                                {/* <label className="text-count">(124)</label> */}
                              </div>
                            </li>
                        )


                  })}
                  </ul>
                    </>
                  )}
                  {/* Material Section */}
                  {this.state.material.length > 0 && (
                    <>
                  <div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Material</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.material_ids.length > 0 ){
                      this.setState({material_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.material.map((item)=>{
                     return (
                      <li className={this.setMaterialCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                          <div className="checkbox d-flex flex-row">
                            <span className="textspace" />
                            <input type="checkbox" checked={this.setMaterialCheck(item.id)} onClick={() => this.addMaterialId(item)}  />
                              <label>{item.material_name}</label>
                              {/* <label className="text-count">(124)</label> */}
                            </div>
                          </li>
                      )
                  })}
                  </ul>
                  </>
                  )}
                   {/* Platform Section */}
                   {this.state.platform.length > 0 && (
                    <>
                  <div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Platform</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.platform_ids.length > 0 ){
                      this.setState({platform_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.platform.map((item)=>{


                    return (
                    <li className={this.setPlatformCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setPlatformCheck(item.id)}  onClick={() => this.addPlatformId(item)}  />
                            <label>{item.platform}</label>
                            {/* <label className="text-count">(124)</label> */}
                          </div>

                        </li>
                    )
                  })}
                  </ul>
                  </>
                  )}
               {/* Render Section */}
               {this.state.render.length > 0 && (
                    <>
                  <div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Render</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.render_ids.length > 0 ){
                      this.setState({render_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.render.map((item)=>{
                                         return (
                                          <li className={this.setRenderCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                                              <div className="checkbox d-flex flex-row">
                                                <span className="textspace" />
                                                <input type="checkbox" checked={this.setRenderCheck(item.id)}  onClick={() => this.addRenderId(item)}  />
                                                  <label>{item.render}</label>
                                                  {/* <label className="text-count">(124)</label> */}
                                                </div>
                                              </li>
                                          )


                  })}
                  </ul>
                  </>
                  )}
                </div>
              </div>
              </div>
              {/* End col-3 */}
              <div className="listing">
              {/* Start col-9 */}
              <div className="col-xl-12 col-md-12 px-4 order-1 order-md-2">
                <ul className="nav nav-products mb-3 products-tab" id="products-tab" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active btn-tabs" id="products-home-tab" data-toggle="pill" href="#products-profile" role="tab" aria-controls="products-home" aria-selected="true" onClick={()=>{this.setAsset(0)}}>All</a>
                  </li>
                  {this.state.asset_list.length > 0 && (<>
                    {this.state.asset_list.map((asset)=>{
                        let href = "#products-"+`${asset.id}`;
                    return  <li className="nav-item">
                    <a className="nav-link btn-tabs" id="products-profile-tab" data-toggle="pill" href={href} role="tab" aria-controls="products-profile" aria-selected="false" data-asset_id={asset.id} onClick={()=>{this.setAsset(asset.id)}}>{asset.asset_type}</a>
                  </li>;
                    })}
                  </>)}
                  <li className="nav-item">
                    <a className="nav-link  btn-tabs" id="products-home-tab" data-toggle="pill" href="#products-profile" role="tab" aria-controls="products-home" aria-selected="true" onClick={this.favouriteClick}>Favourites</a>
                  </li>
                </ul>
                {/*Product Heading*/}
                <div className="prod_heading1">
                  <h1>Objects</h1>


                  {/* <span className="tiny_desc">{this.state.total_items} Resources</span>
                   */}
                </div>
                {/*Product Breadcrump*/}
                {/* <div className="prod_breadcrum tiny_desc">Showing {this.state.total_items} of {this.state.total_items}</div> */}
                <div className="tab-content mt-4" id="products-tabContent">
                  <div className="tab-pane fade show active" id="products-home" role="tabpanel" aria-labelledby="products-home-tab">
                    {/* Start Wrapper */}
                    <div className="d-flex flex-wrap justify-content-around flex-row">



                {this.state.objects&&

  /* {this.state.objects.map((val)=> */
                <>
                {

                (this.state.sort_ids.includes(1)  ?
                // Most downloaded condition
                    (this.state.objects.sort((a, b) => b.download_count > a.download_count ? 1:-1))
                    :

                    //
                    // recently uploaded
                    this.state.sort_ids.includes(2)  ?
                    (this.state.objects.sort((a, b) => b.uploaded_on > a.uploaded_on ? 1:-1))

                    // this.state.objects.filter((item) => item.three_d_printable == "1")
                    // (this.state.objects.sort((a, b) => b.three_d_printable < a.three_d_printable ? 1:-1))
                    :

                    (this.state.objects))


                  .map((val)=>

                  <div className="card prod-item">

                    <a class=""  onClick={() => this.showLightBox(val.images,val.id,val.object_name,val.price,val.is_favourite, val.seller_id)} ><img src={BASE_URL.slice(0, -1)+`${val.thumbnail_image}`} className="card-img-top" alt=""/></a>
                    <span  onClick={()=>{this.addToBookMark(val.id);}}  className="right-bookmark-button"><img className="right-bookmark-icon" style={{width:11}} src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} /></span>

                    {/* <img onClick={()=>{this.addToBookMark(val.id)}} src="../assets/images/badge.png" className="pbadge" alt="" /> */}
                    <div className="card-body">
                      <h5 className="card-title"><Link to={{
                          pathname: `/productDetails/${'object'}/${val.id}`,
                          // search: '?id=modus-create',
                          state: { id: val.id,product_type:'object' }
                        }}
                        >{val.object_name.toUpperCase()}</Link></h5>
                      <p className="card-text">{val.avg_rating}
                      <span style={{marginLeft:8}}><Rating
                      readonly
                      initialRating={val.avg_rating?val.avg_rating:0}
                      emptySymbol={<i className="fa fa-star " style={{color:'gray',fontSize:10}}></i>}
                      fullSymbol={<i className="fa fa-star checked" style={{fontSize:10}}></i>}
                        />
                        </span>
                      <span className="float-right badge badge-success">
                        {val.asset}
                      </span>
                        {/* <span className="fa fa-star checked" />
                        <span className="fa fa-star checked" />
                        <span className="fa fa-star checked" />
                        <span className="fa fa-star checked" />
                        <span className="fa fa-star checked" /> */}
                      </p>

                    </div>
                  </div>
                )}

                </>
                }

{this.state.objects && this.state.objects.length == 0 && (
                  <>
                   <div className="col-md-12 info-msg">No content found..</div>
                  </>
                )}


                    </div>
                    {/* End Wrapper */}
                  </div>
                  {/* <div className="tab-pane fade" id="products-profile" role="tabpanel" aria-labelledby="products-profile-tab">
                    <div className="col-md-12 info-msg">No content found..</div>
                  </div>
                  <div className="tab-pane fade" id="products-contact" role="tabpanel" aria-labelledby="products-contact-tab">
                    <div className="col-md-12 info-msg">No content found..</div>
                  </div>
                  <div className="tab-pane fade" id="products-favorite" role="tabpanel" aria-labelledby="products-favorite-tab">
                    <div className="col-md-12 info-msg">Favorites</div>
                  </div> */}
                </div>

                <div className="col-xl-12 col-md-12 col-sm-12 ml-auto  d-flex flex-wrap flex-row justify-content-between pagination-wrapper">
                {/* Page no */}
                <div className="pagination-count goto">
                <span >{`${this.state.total_items} objects available`}</span>

                  {/* Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp}/> */}
                  </div>
                {/* Pagination */}
                <nav aria-label="pagination">

                  <ul className="pagination">
                  <li className="page-item " style={{marginRight:40}}>
                  Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp}/> <span> of {this.state.pageNumbers[this.state.pageNumbers.length-1]}</span>
                    </li>
                    <li className="page-item ">
                      <a className="page-link prev" href="#" aria-label="Previous" onClick={this.handlePrevClick}>
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {this.state.pageNumbers.map((val)=>
 <li className={this.state.page == val ?"page-item active":"page-item"}><a className="page-link" href="#" id={val} onClick={this.handleClick}>{val}</a></li>
                    )}

                    {/* <li className="page-item "><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                    <li className="page-item ">
                      <a className="page-link next" href="#" aria-label="Next"  onClick={this.handleNextClick} >
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              </div>
              </div>
              {/* End col-9*/}
            </div>
            {/* End container*/}
          </div>
          {/* End Row*/}
        </section>
        {/* End Shop Section*/}
        {/* Bread Crumbs */}

        {/* <section className="breadcrump">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-9 col-md-9 col-sm-12 ml-auto px-5 d-flex flex-wrap flex-row justify-content-between pagination-wrapper">

                <div className="pagination-count goto">
                <span >{`${this.state.total_items} objects available`}</span>


                  </div>

                <nav aria-label="pagination">

                  <ul className="pagination">
                  <li className="page-item ">
                  Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp}/>
                    </li>
                    <li className="page-item ">
                      <a className="page-link prev" href="#" aria-label="Previous" onClick={this.handlePrevClick}>
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {this.state.pageNumbers.map((val)=>
 <li className={this.state.page == val ?"page-item active":"page-item"}><a className="page-link" href="#" id={val} onClick={this.handleClick}>{val}</a></li>
                    )}


                    <li className="page-item ">
                      <a className="page-link next" href="#" aria-label="Next"  onClick={this.handleNextClick} >
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section> */}

        <div>

      </div>
      </>

     <Footer/>
 </>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
	return {
		cartDetails: state.cartDetails.data,
    bookmarkItems:state.cartDetails.bookmark_items,
    search:{key:state.cartDetails.search_key,url:state.cartDetails.search_url}
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: (token,id) => dispatch(cartDetails(token,id)),
    updateBookmarkItems: () => dispatch(bookmarkItems()),
    setSearchKey: data => dispatch(setSearchKey(data)),
    setSearchUrl: data => dispatch(setSearchUrl(data)),
	};
};

export default withRouter(withToast(connect(mapStateToProps, mapDispatchToProps)(Objects)));
