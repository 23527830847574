import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import { withRouter } from 'react-router-dom';
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      name:"Lorem Ipsum"
    };
  }

  componentDidMount=()=>{
    const self = this;
    // window.scrollTo(0, 0);
    // console.log(this.props.location.search)
    // if(this.props.location.state !== undefined ){
    //   if(this.props.location.state.plans !== undefined && this.props.location.state.plans ===true)
    //   this.planSection.current.scrollIntoView( {behavior: 'smooth'})   
    // }
    this.$el = window.jQuery(this.el);
    // this.$el.rwdImageMaps();
  }
  render(){
    return (
    
       <footer className="footer">
       <div className="container-fluid">
         <div className="row flex-row flex-wrap justify-content-between pt-50">
           {/* Footer Logo */}
           <div className="col-6 col-md-2 mb-4">
             <figure><img src={`${process.env.PUBLIC_URL}/assets/images/logofooter.png`} alt="footerlogo" className="img-fluid footer-logo" /></figure>
           </div>
           {/* Site map */}
           <div className="col-6 col-md-1 mb-4">
             <h3 className="fhead">Site Map</h3>
             <ul className="fblock">
               <li className="list-item pb-1"><Link exact to="/">Home</Link></li>
               <li className="list-item pb-1"><Link exact to="/objects">Objects</Link></li>
               <li className="list-item pb-1"><Link exact to="/materials">Materials</Link></li>
               <li className="list-item pb-1"><Link exact to="/graphics">Graphics</Link></li>
               <li className="list-item pb-1"><Link exact to="/login">Sign-in</Link></li>
               <li className="list-item pb-1"><Link exact to="/signup">Sign-up</Link></li>
               <li className="list-item pb-1"><a href="/#section6">Pricing</a></li>
             </ul>
           </div>
           {/* Company */}
           <div className="col-6 col-md-1 mb-4">
             <h3 className="fhead">Company</h3>
             <ul className="fblock">
               <li className="list-item pb-1"><a href="#">About us</a></li>
               <li className="list-item pb-1"><a href="#">Contact us</a></li>
               <li className="list-item pb-1"><a href="#">Follow us on Twitter</a></li>
               <li className="list-item pb-1"><a href="#">Follow us on Instagram</a></li>
               <li className="list-item pb-1"><a href="#">Follow us on Pinrest</a></li>
               <li className="list-item pb-1"><a href="#">Follow us on Facebook</a></li>
             </ul>
           </div>
           {/* Services */}
           <div className="col-6 col-md-1 mb-4">
             <h3 className="fhead">Services</h3>
             <ul className="fblock">
               <li className="list-item pb-1"><Link exact to="/objects">Buy 3D Objects</Link></li>
               <li className="list-item pb-1"><Link exact to="/materials">Buy 3D Materials</Link></li>
               <li className="list-item pb-1"><Link exact to="/graphics">Buy Graphics</Link></li>
               <li className="list-item pb-1"><a href="/#section6">Buy Subscription Plans</a></li>
               <li className="list-item pb-1"><a href="#">Promotions and Offers</a></li>
             </ul>
           </div>
           {/* Get Help */}
           <div className="col-6 col-md-1 mb-4 p-0">
             <h3 className="fhead">Get Help</h3>
             <ul className="fblock">
               <li className="list-item pb-1"><Link exact to="/faq">FAQs</Link></li>
               <li className="list-item pb-1"><Link exact to="/terms">Terms &amp; Conditions</Link></li>
               {/* <li className="list-item pb-1"><a href="#">Support</a></li> */}
             </ul>
           </div>
           <div className="col-6 col-md-4 mb-4">
             <img src={`${process.env.PUBLIC_URL}/assets/images/social.png`} alt="footerlogo" ref={el => this.el = el} className="img-social"  usemap="#imagemap"/>
             {/* Image Map Generated by http://www.image-map.net/ */}
             <map name="imagemap">
                <area target="_blank" alt="Instagram" title="Instagram" href="https://www.instagram.com/user?username=@3domg.com4u" coords="457,130,74" shape="circle"/>
              
                <area target="_blank" alt="Twitter" title="Twitter" href="https://twitter.com/intent/tweet?url=www.3domg4u/" coords="62,531,236,524,227,411,395,378,372,613,311,652,276,663,317,676,235,681,177,630,120,608,106,576,89,557" shape="poly"/>
                <area target="_blank" alt="Pinterest" title="Pinterest" href="https://www.pinterest.com/3Domg4u/" coords="529,390,110" shape="circle"/>
                <area target="_blank" alt="Facebook" title="Facebook" href="https://www.facebook.com/" coords="343,726,449,564,626,549,648,710,487,702,386,738" shape="poly"/>
            </map>
                  {/* <map name="imagemap">
                     <area target="_blank" alt="Instagram" title="Instagram" href="https://www.instagram.com/" coords="101,42,135,86,151,86,195,43,152,0,121,22" shape="poly"/>
                     <area target="_blank" alt="Twitter" title="Twitter" href="https://www.twitter.com/" coords="101,100,70,122,65,138,73,162,11,147,9,173,28,210,52,222,62,229,56,246,64,259,81,265,101,260,113,252,110,225,136,196,141,159,136,123" shape="poly"/>
                     <area target="_blank" alt="Pinterest" title="Pinterest" href="https://www.pinterest.com/" coords="121,92,125,110,142,132,143,165,144,172,232,165,214,70" shape="poly"/>
                     <area target="_blank" alt="Facebook" title="Facebook" href="https://www.facebook.com/" coords="146,182,125,220,133,248,195,252,222,227,218,177" shape="poly"/>
                  </map> */}
           </div>
         </div>
       </div>
     </footer>
    );
}
}
export default withRouter(Footer);