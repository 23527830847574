import React, { Component } from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { BASE_URL } from '../helper';
import { Formik } from "formik";
import * as yup from "yup";
import {

    Link,
    withRouter,
  } from "react-router-dom";
import { reject } from 'lodash';
const validationSchema = yup.object().shape({
//   name:yup.string().required().label('Full Name'),
//   email:yup.string().required().label('Email'),
  address:yup.string().required().label('Address'),
  phone_number:yup.number().required().label('Phone Number'),
  country:yup.string().required().label('Country'),
  city:yup.string().required().label('City'),
  zipcode:yup.number().required().label('Zip Code'),
//profile_pic: yup.mixed().required('Please upload a profile image'),
  });
const axios = require('axios');
class UpdateProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token:null,
         user_info:null,
         loading:true,
         profile_img:null,
        };
      }
      setProfile(file){
        if(file.length !== 0){
          this.setState({profile_img:URL.createObjectURL(file)});
        }

      }
      getProfileData(){
        const token = localStorage.getItem('omg_token');
        const user_id = localStorage.getItem('user_id');

        return new Promise((resolve,reject)=>{
            axios({
                method: 'post',
                url: BASE_URL+'api/profile/view',
                headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
                data:{
                  "user":user_id,
                }
              })
              .then( (response)=> {
                // alert(JSON.stringify(response));
                this.setState({user_info:response.data.profile_details,profile_img:BASE_URL.slice(0, -1)+`${response.data.profile_details.image}`},()=>{
                    resolve(true);
                });
                // this.setState({completed:true});


              })
              .catch((error)=> {
                console.log('er',error);
                this.setState({error:true},()=>{
                    reject(true);
                });

              });
        });

      }
      removeProfileImg(){
        var result = window.confirm("Are you sure you want to remove this?");
        if(result) {
          const token = localStorage.getItem('omg_token');
          const user_id = localStorage.getItem('user_id');
          axios({
            method: 'post',
            url: BASE_URL+'api/profile/remove_profile_image',
            headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
            data:{
              "user_id":user_id,
            }
          })
          .then( (response)=> {
            this.getProfileData().then((res)=>{
              this.setState({loading:false});
          }).catch((err)=>{
            console.log('er',err);
          });

            // this.setState({completed:true});


          })
          .catch((error)=> {
            console.log('er',error);
            // this.setState({error:true},()=>{
            //     reject(true);
            // });

          });
        }

      }
      updateProfile = (name,email,address,phone_number,country,city,zipcode,profile_pic, paypal_id)=>{

        const token = localStorage.getItem('omg_token');
        const user_id = localStorage.getItem('user_id');
        const formdata = new FormData();
        formdata.append('image', profile_pic);
        formdata.append('phone', phone_number);
        formdata.append('address', address);
        formdata.append('country', country);
        formdata.append('city', city);
        formdata.append('zipcode', zipcode);
        formdata.append('user', user_id);
        formdata.append('paypal_id', paypal_id)
        axios({
            method: 'post',
            url: BASE_URL+'api/profile/update',
            headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
            data:formdata
          })
          .then( (response)=> {
              if(response){
                this.props.history.push('/profile');
              }
            // alert(JSON.stringify(response));

            // this.setState({completed:true});


          })
          .catch((error)=> {
            console.log('er',error);


          });

      }
      componentDidMount(){
          this.getProfileData().then((res)=>{
              this.setState({loading:false});
          }).catch((err)=>{
            console.log('er',err);
          });
      }
    render() {
        return (
            <>
             <Header/>
             <section className="info profile-wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="info-wrap">
            <h3>User Info</h3>
                {!this.state.loading && (
                    <>
                    <Formik
      initialValues={{
          name:this.state.user_info?this.state.user_info.name:'',
          profile_pic:'',email:this.state.user_info?this.state.user_info.email:'',
          phone_number:this.state.user_info?this.state.user_info.phone:'',
          address:this.state.user_info?this.state.user_info.address:'',
          country:this.state.user_info?this.state.user_info.country:'',
          city:this.state.user_info?this.state.user_info.city:'',
          zipcode:this.state.user_info?this.state.user_info.zipcode:'',
          paypal_id: this.state.user_info?this.state.user_info.paypal_id: ''
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {

      let{name,email,address,phone_number,country,city,zipcode,profile_pic, paypal_id}=values;

        this.updateProfile(name,email,address,phone_number,country,city,zipcode,profile_pic, paypal_id);
        // resetForm();
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
          <form  onSubmit={formikProps.handleSubmit}>

          {/* Row 1 */}
          <div className="ifccont">
            <div className="ifc">
              <label htmlFor="fname">Profile Pic</label>

              <img src={this.state.profile_img} className="thumbnail" alt="" style={{width:100,height:75}}/>
              <span className="fa fa-trash"   onClick={()=>{this.removeProfileImg()}}  />
              <input className="form-control" type="file" id="profile_pic" name="profile_pic" onChange={(event) => {
           formikProps.setFieldValue("profile_pic", event.currentTarget.files[0],this.setProfile(event.currentTarget.files[0]));
           }} />
           {formikProps.errors.profile_pic && formikProps.touched.profile_pic &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.profile_pic}</small>
        }
            </div>
            <div className="ifc">
              <label htmlFor="destination">Name</label>
              <input className="form-control" type="text" id="phone" name="phone" placeholder  value={formikProps.values.name} readOnly/>
            </div>
            <div className="ifc">
              <label htmlFor="phone">Email</label>
              <input className="form-control" type="text" id="address" name="address"  value={formikProps.values.email} readOnly/>
            </div>

          </div>
          <div className="ifccont">

            <div className="ifc">
              <label htmlFor="destination">Contact Number</label>
              <input className="form-control" type="text" id="phone_number" name="phone_number"  onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.phone_number}  />
                  {formikProps.errors.phone_number && formikProps.touched.phone_number &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.phone_number}</small>
              }
            </div>
            <div className="ifc">
              <label htmlFor="phone">Address</label>
              <input className="form-control" type="text" id="address" name="address" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.address}  />
                   {formikProps.errors.address && formikProps.touched.address &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.address}</small>
              }
            </div>
            <div className="ifc">
              <label htmlFor="country">Country</label>
              <input className="form-control" type="text" id="country" name="country" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.country}  />
                   {formikProps.errors.country && formikProps.touched.country &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.country}</small>
              }
              {/* <select className="form-control">
                <option>Select</option>
                <option>1</option>
                <option>1</option>
              </select> */}
            </div>
          </div>
          {/* Row 2 */}
          <div className="ifccont">

            <div className="ifc">
              <label htmlFor="city">City</label>
              <input className="form-control" type="text" id="city" name="city" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.city}  />
                   {formikProps.errors.city && formikProps.touched.city &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.city}</small>
              }
              {/* <select className="form-control">
                <option>Select</option>
                <option>1</option>
                <option>1</option>
              </select> */}
            </div>
            <div className="ifc">
              <label htmlFor="zipcode">Zipcode</label>
              <input className="form-control" type="text" id="zipcode" name="zipcode" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.zipcode}  />
                   {formikProps.errors.zipcode && formikProps.touched.zipcode &&
               <small id="emailHelp" className="form-text text-warning">{formikProps.errors.zipcode}</small>
              }
              {/* <select className="form-control">
                <option>Select</option>
                <option>1</option>
                <option>1</option>
              </select> */}
            </div>
          </div>
          <div className="bline" />
          <h3>Payment Info</h3>
          {/* Row 4 */}
          <div className="ifccont">
            <div className="ifc">
              <label htmlFor="paypal_id">Paypal ID</label>
              <input
                  className="form-control"
                  type="text"
                  id="paypal_id"
                  name="paypal_id"
                  placeholder
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.paypal_id}
              />

            </div>
            <div className="ifc">
            <span>Note : If you do not have a Paypal account <a target="_blank" href="https://www.paypal.com/in/webapps/mpp/account-selection" style={{color:"#e06526",fontWeight:"bold"}}>Click Here</a> to create</span>

            </div>
          </div>
          <div className="bline" />
          <h3>Billing Address</h3>
          {/* Row 1 */}
          <div className="ifccont">
            <div className="ifc">
              <label htmlFor="fname">Full Name</label>
              <input className="form-control" type="text" id="fname" name="fname" placeholder />
            </div>
            <div className="ifc">
              <label htmlFor="destination">Destination</label>
              <input className="form-control" type="text" id="destination" name="destination" placeholder />
            </div>
            <div className="ifc">
              <label htmlFor="email">Email</label>
              <input className="form-control" type="text" id="email" name="email" placeholder />
            </div>
          </div>
          {/* Row 2 */}
          <div className="ifccont">
            <div className="ifc">
              <label htmlFor="phone">Phone Number</label>
              <input className="form-control" type="text" id="phone" name="phone" placeholder />
            </div>
            <div className="ifc addr">
              <label htmlFor="address">Address</label>
              <input className="form-control" type="text" id="address" name="address" placeholder />
            </div>
          </div>
          {/* Row 3 */}
          <div className="ifccont">
            <div className="ifc">
              <label htmlFor="country">Country</label>
              <select className="form-control">
                <option>Select</option>
                <option>1</option>
                <option>1</option>
              </select>
            </div>
            <div className="ifc">
              <label htmlFor="city">City</label>
              <select className="form-control">
                <option>Select</option>
                <option>1</option>
                <option>1</option>
              </select>
            </div>
            <div className="ifc">
              <label htmlFor="zipcode">Zipcode</label>
              <select className="form-control">
                <option>Select</option>
                <option>1</option>
                <option>1</option>
              </select>
            </div>
          </div>
          {/*Button*/}
          <div className="ifccont"> <button className=" btn-confirm">Confirm</button></div>

        </form>
      )}
      </Formik>
                    </>
                )}


            </div>
          </div>
          {/* End Row */}
        </div>
      </section>
            <Footer/>
            </>
        )
    }
}

export default withRouter(UpdateProfile);
