import React, { Component } from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import DownloadPage from './DownloadPage';
import Favourites from './Favourites';
import Uploads from './Uploads';
import { ToastProvider, useToasts } from 'react-toast-notifications';

import {

    Link,
    withRouter,
  } from "react-router-dom";
  import { NavHashLink } from 'react-router-hash-link';
  import {BASE_URL} from '../helper';
  const axios = require('axios');

  function withToast(Component) {
    return function WrappedComponent(props) {
      const toastFuncs = useToasts()
      return <Component {...props} {...toastFuncs} />;
    }
  }


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token:null,
          downloads:[],
         user_id:24,
         loading:true,
         user_info:null,
         tab_index:0,
         show_section:0,
         userplan_info:null,
         vector_user_stage:null,
         object_material_user_stage:null,
         plan_expiry_date:null,
         request_payout:0,

         
        };
      }
      
      randomDate(separator='/'){

        let newDate = new Date()
        newDate.setDate(newDate.getDate() + 60);
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
        }
      componentDidMount(){
        this.getProfileData().then((res)=>{
            this.setState({loading:false});
        }).catch((err)=>{
          console.log('er',err);  
        });
      }
      getProfileData(){
        const token = localStorage.getItem('omg_token');
        const user_id = localStorage.getItem('user_id');

        return new Promise((resolve,reject)=>{
            axios({
                method: 'post',
                url: BASE_URL+'api/profile/view',
                headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
                data:{ 
                  "user":user_id, 
                }
              })
              .then( (response)=> {
                // alert(JSON.stringify(response));
                this.setState({user_info:response.data.profile_details,profile_img:BASE_URL.slice(0, -1)+`${response.data.profile_details.image}`},()=>{
                    resolve(true);
                });

                this.setState({userplan_info:response.data.user_plan});
                this.setState({vector_user_stage:response.data.vector_user_stage})
                this.setState({object_material_user_stage:response.data.object_material_user_stage})

                let date = new Date(response.data.user_plan.plan_expiry_date);
                /* Date format you have */
                let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                this.setState({plan_expiry_date:dateMDY});


                this.setState({request_payout:response.data.request_payout});
            
                
              })
              .catch((error)=> {
                console.log('er',error);
                this.setState({error:true},()=>{
                    reject(true);
                });
            
              });
        });
        
      }

      withdrawPayout(){

        const token = localStorage.getItem('omg_token');
        const user_id = localStorage.getItem('user_id');

        return new Promise((resolve,reject)=>{
            axios({
                method: 'post',
                url: BASE_URL+'api/checkout/withdraw_payout',
                headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
                data:{ 
                  "seller_id":user_id, 
                }
              })
              .then( (response)=> {

                // console.log(response);
                this.props.addToast(response.data.message, {
                  appearance: 'success',
                  autoDismiss: true,
                });
                this.getProfileData();
                
              })
              .catch((error)=> {
                console.log('er',error);
                this.setState({error:true},()=>{
                    reject(true);
                });
            
              });
        });

      }
     
    render() {
        const{tab_index}=this.state;
        return (
            <>
            <Header/>
            <section className="profile-wrap">        
        <div className="container-fluid px-0 inner-page">


        {!this.state.loading && (
                <> 
          <div className="row mx-0">
            <div className="col-sm-3">
              <div className="card card-layout-1">
                <div className="card-header" style={{padding: '10px 0'}}>
                  <div className="row mx-0">
                    <div className="col-sm-4">
                    
                    {this.state.user_info && this.state.user_info.image !="" && (
                            <>
                             <img src={BASE_URL.slice(0, -1)+`${this.state.user_info.image}`} style={{width:60,borderRadius:30,}} alt="" />
                            </>
                        )}
                        {!this.state.user_info && this.state.user_info.image =="" && (
                            <>
                             <img src={`${process.env.PUBLIC_URL}/assets/images/profile/avtar.png`} style={{width:60,borderRadius:30,}} alt="" />
                            </>
                        )}
                    </div>
                    <div className="col-sm-8">
                      <p className="text-right" style={{marginBottom: 0}}><Link to={{
  pathname: `/update_profile`,
  
  }}
  title="User info and Payment Details"
 ><img src={`${process.env.PUBLIC_URL}/assets/images/profile/edit.png`} alt="" width="16px" /></Link></p>
                      <p className="user-name">{this.state.user_info?this.state.user_info.name:''}</p>
                      {/* <p className="user-amt"><span>$10</span> <a href="#">Withdraw</a></p> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <table cellPadding={0} cellSpacing={0} border={0} className="table">
                  <tbody>
                      <tr>
                        <td width="40%">Email :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.email:''}</strong></td>
                      </tr>
                      <tr>
                        <td>Phone :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.phone:''}</strong></td>
                      </tr>
                      {/* <tr>
                        <td>Address :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.address:''}</strong></td>
                      </tr> */}
                      <tr>
                        <td>Country :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.country:''}</strong></td>
                      </tr>
                      <tr>
                        <td>City :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.city:''}</strong></td>
                      </tr>
                      <tr>
                        <td>Zipcode :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.zipcode:''}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card card-layout-1">
                <div className="card-header">
                  <h3>My Plan</h3>
                </div>
                <div className="card-body">
                  <table cellapadding={0} cellSpacing={0} border={0} className="table">
                    <tbody>
                      <tr>
                        <td><big className="pack-name">{this.state.userplan_info.plan_name}</big></td>
                        <td>
                          
                           <NavHashLink
          smooth={true}
  to="/#section6"
  activeClassName="active" className="nav-link"
  style={{padding:"0px"}}
  // activeStyle={{ padding: '0px' }}
  // etc...
> <big className="pack-name" style={{fontSize:"21px",color: "blue"}}> Add New Plans </big></NavHashLink>
                          {/* <a href="#" style={{marginTop: '10px'}}>Add New Plans</a> */}
                          </td>
                      </tr>
                      <tr>
                        <td>Pro Items Remaining</td>
                        <td>{this.state.userplan_info.available_pro_items}{this.state.userplan_info.total_pro_items ? '/ '+ this.state.userplan_info.total_pro_items : ""}</td>
                      </tr>
                      <tr>
                        <td>Bonus Items Remaining</td>
                        <td>{this.state.userplan_info.available_bonus_items}{this.state.userplan_info.total_bonus_items ? '/ '+this.state.userplan_info.total_bonus_items : ""}</td>
                      </tr>
                      <tr>
                        <td>Plan Expiry Date</td>
                      

                        <td> { this.state.plan_expiry_date=='NaN-NaN-NaN' ? "" : this.state.plan_expiry_date } </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div className={this.state.show_section == 0 ? "seller " +this.state.object_material_user_stage.profile_stage : "seller " +this.state.vector_user_stage.profile_stage } style={{marginBottom: '30px'}}>

              {/* <div className={this.state.show_section == 0 ?"card seller bronze":"card seller  newbie"} style={{marginBottom: '30px'}}> */}
                <div className="card-header card-tab">
                  <ul>
                    <li className={this.state.show_section == 0 ?"active":""}><a href="javascript:void(0)" onClick={()=>{this.setState({show_section:0})}}>3D Object &amp; Materials</a></li>
                    <li className={this.state.show_section == 1 ?"active":""}><a href="javascript:void(0)" onClick={()=>{this.setState({show_section:1})}}>Vectors</a></li>
                  </ul>
                </div>
                <div className="card-body">
                  {this.state.show_section == 0 && (
                    <>
                      <div className="3dobject">
                        <table cellPadding={0} cellSpacing={0} border={0} className="table">
                          <tbody>
                            <tr>
                              <td>Total royalty earned</td>
                              <td>{this.state.object_material_user_stage.total_royality_earned ? this.state.object_material_user_stage.total_royality_earned : 0}</td>
                              
                            </tr>
                            <tr>
                              <td>No.of objects/materials uploaded </td>
                              <td>{this.state.object_material_user_stage.object_material_uploaded ? this.state.object_material_user_stage.object_material_uploaded : 0}</td>
                            </tr>
                            <tr>
                              <td>Total no. of downloads</td>
                              <td>{this.state.object_material_user_stage.total_downloaded ? this.state.object_material_user_stage.total_downloaded : 0}</td>
                            </tr>
                            <tr>
                              <td colSpan="2"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      

                    </>
                  )}
                {this.state.show_section == 1 && (
                    <>
                     <div className="vector" >
                        <table cellPadding={0} cellSpacing={0} border={0} className="table">
                          <tbody>
                            <tr>
                              <td>Total royalty earned</td>
                              <td>{this.state.vector_user_stage.total_royality_earned ? this.state.vector_user_stage.total_royality_earned : 0}</td>
                            </tr>
                            <tr>
                              <td>No.of vectors/photos uploaded </td>
                              <td>{this.state.vector_user_stage.vector_uploaded ? this.state.vector_user_stage.vector_uploaded : 0}</td>
                            </tr>
                            <tr>
                              <td>Total no. of downloads</td>
                              <td>{this.state.vector_user_stage.total_downloaded ? this.state.vector_user_stage.total_downloaded : 0}</td>
                            </tr>
                            <tr>
                              <td colSpan="2"></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                      </>
                  )}
                 
                </div>
                <br />
                {this.state.request_payout != 1 &&(
                <div class="signup_1">
                  <p>Minimum $50 required to activate WITHDRAW link</p>
                </div>
                )}
                {this.state.request_payout == 1 &&
                (
                  <>
                 

                  
                    <div className="tab-container">
                      <ul className="cl">
                        <li><a href="#" className='active' onClick={()=>{
                            this.withdrawPayout();
                        }}>WITHDRAW</a></li>
                      </ul>
                    </div>
                  </>
                )}

              </div>
            </div>
            <div className="col-sm-9">
              <div className="tab-container">
                <ul className="cl">
                  <li><a href="#" className={tab_index == 0 ? 'active':'' } onClick={()=>{
                      this.setState({tab_index:0});
                  }}>UPLOADS</a></li>
                  <li><a href="#" className={tab_index == 1 ? 'active':'' } onClick={()=>{
                      this.setState({tab_index:1});
                  }}>PURCHASED</a></li>
                  <li><a href="#" className={tab_index == 2 ? 'active':'' } onClick={()=>{
                      this.setState({tab_index:2});
                  }}>FAVOURITES</a></li>
                   {/* {this.state.request_payout != 1 &&
                (
                  <li><a href="#" className={tab_index == 2 ? 'active':'' } onClick={()=>{
                      this.withdrawPayout();
                  }}>WITHDRAW</a></li>
                 
                )} */}
                
                </ul>
              </div>
              <div className="listing" style={{marginLeft:0}}>

              {tab_index == 0 && (
                    <>
            <Uploads/>
                    </> 
                  )}
                  {tab_index == 1 && (
                      <>
                      <DownloadPage/>
                 </>
                  )}
                  {tab_index == 2 && (
                      <>
                     <Favourites/>
                 </>
                  )}
             
              </div>
            </div>      
          </div>
          </>)}
        </div>
      </section>
           
            <Footer/>
            </>
        )
    }
}
export default withRouter(withToast(Profile));
