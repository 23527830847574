import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';

import {Link} from "react-router-dom";
import { Button } from 'react-bootstrap';
import { BASE_URL } from '../helper';

const axios = require('axios');

class DownloadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      downloads:[],
     
     
    };
  }

componentDidMount(){
  this.getDownloads();
}
getDownloads(){
    const token = localStorage.getItem('omg_token');
    let id = localStorage.getItem('user_id');
    
    axios({
      method: 'post',
      url: BASE_URL+'api/checkout/downloads',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data:{ 
  
        "user":id,      
      }
    })
    .then( (response)=> {
      this.setState({downloads:response.data.downloads})
     
      // console.log(response)
  
      
    })
    .catch((error)=> {
      console.log('er-downloads',error);
      // this.setState({error:true});
  
    });
}


  render() {

    return (
 <>
     
     <>
     {/*  Section */}
    
     <div id="download" className="col">
     {this.state.downloads.length > 0 && (
         <>
         <table cellPadding={0} cellSpacing={0} border={0} className="table">
                    <thead>
                      <tr>
                        <th colSpan={2}>Product Name</th>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Downloads</th>
                      </tr>
                    </thead>  
                    <tbody>
                    {this.state.downloads.map((val)=>
                     <tr>
{(val.product_type =='object') && (
                         <>
                          <td>
                          <Link to={{
  pathname: `/productDetails/${'object'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'object' }
 }}
 >  
                            <div className="product-img"><img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} alt=""  style={{width:150,height:100}}/></div>
                            </Link>

                            </td>
                         <td><span className="list-product-name">{val.product.object_name.toUpperCase()}</span></td>
                         </>
                     )}


{(val.product_type =='material') && (
                         <>
                          <td>
                          <Link to={{
  pathname: `/productDetails/${'material'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'material' }
 }}
 >  
                            <div className="product-img"><img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} alt=""  style={{width:150,height:100}}/></div>
                            </Link>

                            </td>
                         <td><span className="list-product-name">{val.product.material_name.toUpperCase()}</span></td>
                         </>
                     )}


{(val.product_type =='graphics') && (
                         <>
                          <td>
                          <Link to={{
  pathname: `/productDetails/${'graphics'}/${val.product.id}`,
  // search: '?id=modus-create',
  state: { id: val.product.id,product_type:'graphics' }
 }}
 >  
                            <div className="product-img"><img src={`http://165.22.212.233:8002${val.product.thumbnail_image}`} alt=""  style={{width:150,height:100}}/></div>
                            </Link>

                            </td>
                         <td><span className="list-product-name">{val.product.graphics_name.toUpperCase()}</span></td>
                         </>
                     )}


                     <td>{val.ordered_on}</td>
                     <td>{val.product_type}</td>
                     
                     <td><a href={val.download_link}  ><Button className="download-btn btn-success" disabled={val.is_downloaded == 0 ? false:true}>Download</Button></a></td>
                   </tr>
                    )}
                     
                        
                    </tbody>
                  </table>
                  <section className="pagination">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12 d-flex justify-content-between">
                          {/* Page no */}
                          <div className="mb-4 py-2 goto">Go to page <input className="binput" type="text" name defaultValue={1} size={1} /></div>
                          {/* Pagination */}
                          <nav aria-label="pagination">
                            <ul className="pagination" style={{marginTop: 0}}>
                              <li className="page-item">
                                <a className="page-link prev" href="#" aria-label="Previous">
                                  <span aria-hidden="true">&lt;</span>
                                  <span className="sr-only">Previous</span>
                                </a>
                              </li>
                              <li className="page-item active"><a className="page-link" href="#">1</a></li>
                              <li className="page-item "><a className="page-link" href="#">2</a></li>
                              <li className="page-item"><a className="page-link" href="#">3</a></li>
                              <li className="page-item">
                                <a className="page-link next" href="#" aria-label="Next">
                                  <span aria-hidden="true">&gt;</span>
                                  <span className="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </section>
         </>)}
                  
                </div>
      
     </>
     {this.state.downloads.length == 0 && (
         <>
         <div className="col">
        <p>No content found..</p>
         </div>
         </>
     )}
    
 </>
    );
  }
}
export default withRouter(DownloadPage);