import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";
import {Link} from "react-router-dom";
import ReactTags from 'react-tag-autocomplete'
import ImageUploading from "react-images-uploading";
import { BASE_URL } from '../helper';

import ReactLoader from '../widgets/Loader'
import LightBox from '../widgets/LightBox';



const axios = require('axios');
const validationSchema = yup.object().shape({
  // model:yup.string().required().label('Model'),
  title:yup.string().required().label('Title'),
  category:yup.string().required().label('Category'),
  image_type:yup.string().required().label('Image Type'),
  image_size:yup.string().required().label('Image Size'),
  orientation:yup.string().required().label('Orientation'),
  people:yup.string().required().label('People'),
  // gender:yup.string().required().label('Gender'),
  // tags:yup.string().required().label('Tags'),
  description:yup.string().required().label('Description'),
  // imageFiles: yup.mixed().required('Please upload images'),
  document: yup.mixed().required('Please upload a document'),
  
  });
class CreateGraphics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxDisplay:false,
      imageToShow:"",
      token:null,
      complete:false,
      error:false,
      category:[],
      image_types:[],
      image_sizes:[],
      orientation:[],
      peoples:[],
      tags: [],
      files:[],
      document:{},
      images:[],
      loading:false,
      image_types_asset:[]
    };
    this.reactTags = React.createRef();
    this.deleteFile=this.deleteFile.bind(this);
  }
  onDelete (i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition (tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }
componentDidMount(){
  const token = localStorage.getItem('omg_token');
  this.setState({token})
  this.getDropdownData();
}
getDropdownData(){
  axios({
    method: 'get',
    url: BASE_URL+'api/product/graphics_filter_items',
    headers: { 'Content-Type': 'application/json' },
  })
  .then( (response)=> {
    this.setState({category:response.data.category,image_types:response.data.imagetype,image_sizes:response.data.imagesize,orientation:response.data.orientation,peoples:response.data.people,image_types_asset:response.data.assets});
      // alert(JSON.stringify(response));
    
  })
  .catch((error)=> {
    console.log(error)        
  });
}
  createApiCall=(title,imageFiles,document,category,image_type,image_size,description,orientation,people,gender,tags,min_width,min_height)=>{
    // console.log(this.state.token)
    const user_id = localStorage.getItem('user_id');
    var tag=[];

    if(this.state.tags.length > 0){
      


       this.state.tags.map(({ name }) => {
          tag.push(name);
      });
      
    }
   try{
    const formdata = new FormData();
    formdata.append('data',JSON.stringify({
     
      "product_name":title,
      "description":description,
      "asset": image_type,
      "image_size": image_size,
      "orientation": orientation,
      "people": people,
      "gender": gender,
      "tags":tag,
      "category": category,
      // "asset": "1",
      "seller_id": user_id,
      "min_width":min_width,
      "min_height":min_height,
    }));


    if(this.state.images != null){
      for (const key of Object.keys(this.state.images)) {
       formdata.append('image', this.state.images[key]['file'])
      }
    }
  // if(this.state.files.length > 0){
  //   for (var i=0;i< this.state.files.length;i++) {
  //    formdata.append('image', this.state.files[i]);
  //   }
  // }
  if(document != null){
 
      formdata.append('product_file', document);

  }
  this.setState({loading:true});
  axios({
        method: 'post',
        url: BASE_URL+'api/product/upload_graphics',
        data: formdata,
        headers: { 'content-type': 'application/json',"Authorization" : `Token ${this.state.token}` }, 
       
      })
      .then( (response)=> {
        console.log(response);
        
       this.setState({completed:true,images:[],document:{},tags:[],images:[]});
       this.setState({loading:false});
      })
      .catch((error)=> {
        console.log('er',error);
        this.setState({error:true,images:[]});
        this.setState({loading:false});
      });
    }
    catch(e){
      this.setState({error:true,images:[]});
      this.setState({loading:false});
    }
    }
    setImages(images){
      
      var imageFile=[];
      for(var i=0;i < images.length;i++){
        imageFile.push(images[i]);
        
        
      }
      this.setState({files:imageFile},()=>{
        // console.log(this.state.files);
      });
      
    }
    setDoc(file){
      if(file.length !== 0){
        this.setState({document:{...this.state.document,url:URL.createObjectURL(file),name:file['name']}},()=>{
          // console.log(this.state.document);
        });
      }
      
    }
    deleteFile(e) {
      const s = this.state.files.filter((item, index) => index !== e);
      this.setState({files:s});
    }
    onChange = (imageList, addUpdateIndex) => {
      // data for submit
      console.log(imageList, addUpdateIndex);
      this.setState({images:imageList})
    };

    showLightBox = (image) => {
      //set imageToShow to be the one that's been clicked on   
      this.setState({imageToShow:image}) 
      this.setState({lightboxDisplay:true}) 
    
    };

    hideLightBox = () => {
      this.setState({lightboxDisplay:false}) 
    }

    
  
  render() {

    return (
      <div className="row">
      {/* col left */}
      {this.state.loading == true ? <ReactLoader/> : '' }
     
      <div className="col-sm-12 col-md-6">

     

      <Formik
initialValues={{ title: '',imageFiles: null, document:null, category:'',image_type:'',image_size:'',description:'',orientation:'',people:'',gender:'',tags:'',min_width:'',min_height:''}}
onSubmit={(values, { setSubmitting, resetForm }) => {
// console.log(values);
let{title,imageFiles,document,category,image_type,image_size,description,orientation,people,gender,tags,min_width,min_height}=values;
  
  this.createApiCall(title,imageFiles,document,category,image_type,image_size,description,orientation,people,gender,tags,min_width,min_height);
  resetForm();
}}
validationSchema={validationSchema}
>
{(formikProps) => (
  <>
       <form onSubmit={formikProps.handleSubmit} >
        {/* <div className="form-group">
          <h2>Model Upload</h2>
        </div> */}
          
        <div className="form-group">
          <label htmlFor="title">Model Title</label>
          <input type="text" 
           name="title"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.title} 
          className="form-control"
           id="title"
           placeholder="Enter Title"
            />
              {formikProps.errors.title && formikProps.touched.title &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.title}</small>
        }
        </div>


        <div className="form-row">
        <div className="col">
            <label htmlFor="image_type">Image Type</label>
            <select name="image_type" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.image_type}>
              <option value="" selected disabled hidden>Select Image Type</option>
              {/* {this.state.image_types_asset.map((type)=>
               <option value={type.id}>{type.type}</option>
              )} */}

              {this.state.image_types_asset.filter((item, index) => item.product_type == 'graphics').map((type)=>
              <option value={type.id}>{type.asset_type}</option>
              )
              }
     
            </select>
            {formikProps.errors.image_type && formikProps.touched.image_type &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.image_type}</small>
        }
          </div>
          <div className="col">
            <label htmlFor="category">Category</label>
            <select name="category" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.category}>
              <option value="" selected disabled hidden>Select Category</option>
              {this.state.category.map((category)=>
               <option value={category.id}>{category.category_name}</option>
              )}
            
              
            </select>
            {formikProps.errors.category && formikProps.touched.category &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.category}</small>
        }
          </div>
       
        </div>



        <div className="form-row">
          <div className="col">
            <label htmlFor="image_size">Image Size</label>
            <select name="image_size" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.image_size}>
              <option value="" selected disabled hidden>Select Image Size</option>
              {this.state.image_sizes.map((size)=>
               <option value={size.id}>{size.size}</option>
              )}
            </select>
            {formikProps.errors.image_size && formikProps.touched.image_size &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.image_size}</small>
        }
          </div>
          <div className="col">
            <label htmlFor="orientation">Orientation</label>
            <select name="orientation" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.orientation}>
              <option value="" selected disabled hidden>Select Orientation</option>
              {this.state.orientation.map((item)=>
               <option value={item.id}>{item.orientation_name}</option>
              )}
     
            </select>
            {formikProps.errors.orientation && formikProps.touched.orientation &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.orientation}</small>
        }
          </div>
        </div>
        
        <div className="form-row">
          <div className="col">
            <label htmlFor="people">People</label>
            <select name="people" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.people}>
              <option value="" selected disabled hidden>Select People</option>
              {this.state.peoples.map((item)=>
               <option value={item.id}>{item.people}</option>
              )}
            </select>
            {formikProps.errors.people && formikProps.touched.people &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.people}</small>
        }
          </div>
          <div className="col">
            <label htmlFor="gender">Gender</label>
            <select name="gender" onBlur={formikProps.handleBlur}
                 onChange={formikProps.handleChange}
                 value={formikProps.values.gender}>
              <option value="" selected disabled hidden>Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
     
            </select>
            {formikProps.errors.gender && formikProps.touched.gender &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.gender}</small>
        }
          </div>
        </div>


        <div className="form-row">
          <div className="col">
            <label htmlFor="min_width">Min Width</label>
            <input type="text" 
           name="min_width"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.min_width} 
          className="form-control"
           id="title"
           placeholder="Enter Min Width"
            />
              {formikProps.errors.min_width && formikProps.touched.min_width &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.min_width}</small>
        }
          </div>
          <div className="col">
            <label htmlFor="min_height">Min Height</label>
            <input type="text" 
           name="min_height"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.min_height} 
          className="form-control"
           id="title"
           placeholder="Enter Min Height"
            />
              {formikProps.errors.min_height && formikProps.touched.min_height &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.min_height}</small>
        }
          </div>
        </div>
        {/* <div className="form-group">
          <label htmlFor="title">Render</label>
          <input type="text" 
           name="render"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.render} 
          className="form-control"
           id="render"
          //  placeholder="Enter Title"
            />
              {formikProps.errors.render && formikProps.touched.render &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.render}</small>
        }
        </div> */}
        
        <div className="form-group">
          <label htmlFor="3d" className="fulabl">Thumbnail Images(Max Upload 4 Images)</label>
          <div className="row preview">
        {this.state.files.length > 0 &&
          this.state.files.map((item, index) => {
            return (
              <div key={item} className="col" style={{minWidth:150}}>
                <img src={URL.createObjectURL(item)} alt="" style={{width:100,height:100}} className="img-thumbnail"/>
                <h6>{item.name.split('.').slice(0, -1).join('.')}</h6>
                <button type="button" onClick={() => this.deleteFile(index)} 
                style={{position: "absolute",
    left: 78,
    top: 10,
    color: "#fff",
    backgroundColor: "#00000091",
    display: "flex",
    padding: 5,
    zIndex:1000}}
     >
                 <ion-icon name="trash-outline"></ion-icon>
                </button>
              </div>
            );
          })}
      </div>

      <ImageUploading
        multiple
        value={this.state.images}
        onChange={this.onChange}
        maxNumber={4}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">

            <div  onClick={onImageUpload}
              {...dragProps} style={{backgroundColor:'#fff',display:'flex',borderWidth:2,borderColor:"#c9c9c9",borderStyle:'dotted',minHeight:100,alignItems:'center',justifyContent:'center',flexDirection:"row"}}>
<ion-icon style={{marginBottom:0,marginLeft:10}} name="images-outline"></ion-icon>
<p style={{marginBottom:0,marginLeft:10}}>Click or Drop here</p>
            </div>
            {/* <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button> */}
           
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div style={{display:'flex',flexDirection:'row',flexWrap:'nowrap'}}>
            {imageList.map((image, index) => (
              <div key={index} className="image-item" style={{backgroundImage: `url(${image['data_url']})`}}>
                {/* <img src={image['data_url']} alt="" width="100%" /> */}
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}><ion-icon name="create-outline"></ion-icon></button>
                  <button onClick={() => onImageRemove(index)}><ion-icon name="trash-outline"></ion-icon></button>
                </div>
              </div>
            ))}

</div>
          </div>
        )}
      </ImageUploading>
   
      {/* <label htmlFor="3d" className="fulabl">Max Upload 4 Images</label> */}
          {/* <div class="file-upload-wrapper" data-text="Select your file!">
          <input type="file"  multiple className="fuinp file-upload-field" name="imageFiles"
    
            onChange={(event) => {

              if(event.currentTarget.files.length <=4){
                formikProps.setFieldValue("imageFiles", event.currentTarget.files,this.setImages(event.currentTarget.files));
              }else{
                alert("Sorry,Your limit exceed only 4 image is allowed for upload");
              }
              
              }}
            />
            </div> */}
         {formikProps.errors.imageFiles && formikProps.touched.imageFiles &&
        <small id="emailHelp" className="form-text text-warning">{formikProps.errors.imageFiles}</small>
         }
        </div>
        <div className="form-group">
          <label htmlFor="3d" className="fulabl">Vector/Photos</label>

      {Object.keys(this.state.document).length === 0 && (
          <div class="file-upload-wrapper" data-text="Select your file!">
          <input type="file" className="fuinp file-upload-field" onChange={(event) => {
           formikProps.setFieldValue("document", event.currentTarget.files[0],this.setDoc(event.currentTarget.files[0]));
           }}
       />
        </div>
                )
              }


<div className="preview-model">
        {Object.keys(this.state.document).length !=0 && (
          <div className="mt-3 image-preview">
          <img src={`${process.env.PUBLIC_URL}/assets/images/file.jpg`} alt="" style={{width:50,height:50}} className="img-thumbnail mr-2"/>
          <h6 className="mb-0">{this.state.document.name}</h6>
          <ion-icon name="close" onClick={()=>{this.setState({document:{}})}}></ion-icon>
          {/* <button type="button" >
            delete
          </button> */}
        </div>
          )}
      </div>      
        {formikProps.errors.document && formikProps.touched.document &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.document}</small>
        }
       {/* onChange={(e)=>this.onDataFileChange(e)} */}
          </div>
    
          <div className="form-group">
          <label htmlFor="title">Tags</label>
          <ReactTags
           
        ref={this.reactTags}
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        onDelete={this.onDelete.bind(this)}
        allowNew={this.state.tags.length <=6?true:false}
        placeholderText=" Add Tag "
        onAddition={this.onAddition.bind(this)}
        
        minQueryLength={1}
       
         />
          {/* <input type="text" 
           name="tags"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.tags} 
          className="form-control"
           id="tags"
           
            />
              {formikProps.errors.tags && formikProps.touched.tags &&
         <small id="emailHelp" className="form-text text-warning">{formikProps.errors.tags}</small>
        } */}
        </div>


        {/* <div className="form-group">
          <label htmlFor="tags">Price</label>
          <input type="number"
           name="price"
           onBlur={formikProps.handleBlur}
           onChange={formikProps.handleChange}
           value={formikProps.values.price} 
           className="form-control"
           placeholder="0" 
            />
            {formikProps.errors.price && formikProps.touched.price &&
            <small id="emailHelp" className="form-text text-warning">{formikProps.errors.price}</small>
            }
        </div> */}
        <div className="form-group">
          <label htmlFor="tags">Description</label>
          <textarea className="form-control"
            name="description"
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            value={formikProps.values.description} 
           
             />
              {formikProps.errors.description && formikProps.touched.description &&
            <small id="emailHelp" className="form-text text-warning">{formikProps.errors.description}</small>
            }
        </div>
        <div className="form-group">
          <button type="submit" className="fubut">Done!</button>
        </div> 
        {this.state.completed &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-success" role="alert">
Created successfully.Waiting for approval...
</div>
</div>
}
{this.state.error &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-danger" role="alert">
Something went wrong. Please try again!
</div>
</div>
} 
        </form> 
        </>
  )}
</Formik>
      </div>
      {/* col right */}
      <div className="col-sm-12 col-md-6">
        <div className="rule-cont">
          <div className="form-group">
            <h2>Rules</h2>
          </div>
          <ul className="rules">
            <li className="rule-item">{this.props.rules}</li>
          </ul>
          <div class="row">
              
              <a   className="uploadimages" onClick={() => this.showLightBox("../assets/images/upload-1.jpeg")} >
                <img  className=" upload-imgs m-2"  src="../assets/images/upload-1.jpeg" alt="" />
              </a>
              <a  className="uploadimages" onClick={() => this.showLightBox("../assets/images/upload-2.jpeg")} >
                <img className=" upload-imgs m-2" src="../assets/images/upload-2.jpeg" alt="" />
              </a>
              <a className="uploadimages"  onClick={() => this.showLightBox("../assets/images/upload-3.jpeg")} >
                <img className=" upload-imgs m-2" src="../assets/images/upload-3.jpeg" alt="" />
              </a>

          </div>
        </div>

        <div className="rule-cont" style={{marginTop:"20px"}}>
        <div class="row">
              
              <a className="uploadimages"   onClick={() => this.showLightBox("../assets/images/upload-4.jpg")} >
                <img  className=" upload-imgs m-2"  src="../assets/images/upload-4.jpg" alt="" />
              </a>
              <a   className="uploadimages" onClick={() => this.showLightBox("../assets/images/upload-5.jpg")} >
                <img className=" upload-imgs m-2" src="../assets/images/upload-5.jpg" alt="" />
              </a>
              </div>
        </div>
        <div style={{marginTop:"20px"}}></div>
        <div className="rule-cont" style={{padding: "1em 0 1em 1em"}}>
          <div className="row">
          <Link  to={{ pathname: BASE_URL+`sample_asset`}} target='_blank'  style={{margin:'auto'}} className="nav-link signup">Download link for sample Render Scene and Material Slot</Link>
          {/* <Link exact to="/create"  style={{margin:'auto'}} className="nav-link signup">Download link for sample Render Scene and Material Slot</Link> */}
              
          </div>
        </div>
      </div>

          { this.state.lightboxDisplay ?
            
            <div id="lightbox" onClick={this.hideLightBox}>
              <div id="lightbox-wrap">
              <span onClick={this.hideLightBox} className="close-button"><ion-icon name="close-outline"></ion-icon></span>
              <img id="lightbox-img" style={{height:"85vh"}} alt="box" src={this.state.imageToShow}></img>

              </div>
              
              
            </div>
            
              : '' 
          }
       
    </div>
    );
  }
}
export default withRouter(CreateGraphics);
