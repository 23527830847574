import usePaypal from "./usePaypal";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import React from "react";


const PaypalPayment = ({...props}) => {
    const paypal = usePaypal(props);
    return(
        <form className="col-md-6 m-auto card m-b-10">
            <div className="card-body">
                { paypal.message && <div id="payment-message">{paypal.message}</div> }
                <br/>
                <PayPalScriptProvider options={paypal.initialOptions}>
                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={paypal.createOrder}
                        onApprove={paypal.onApprove}
                        onError={paypal.onError}
                    />
                </PayPalScriptProvider>
            </div>
        </form>
    )
}

export default PaypalPayment;