
import { createStore, combineReducers, applyMiddleware  } from 'redux'

import thunk from 'redux-thunk';

// import logger from 'redux-logger'
import rootReducer from '../reducers';


function configureStore(initialState) {


    const enhancer = applyMiddleware(thunk)

    return createStore(
        rootReducer,
        enhancer
    );
}


export const store = configureStore();

