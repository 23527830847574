import React, { Component } from 'react';
import { BASE_URL } from '../helper';

import Slider from "react-slick";

const axios = require('axios');


   


// const newjson={
//     "response_code": 200,
//     "status": "Ok",
//     "home_page": [
//         {
//             "home_page_slider_id": 1,
//             "media_file": "/media/images/home/videoplayback_2.mp4",
//             "media_type": 1
//         },
//         {
//             "home_page_slider_id": 2,
//             "media_file": "/media/images/home/2-men-packages-the-professor-barber-shop.jpg",
//             "media_type": 2
//         },
//         {
//             "home_page_slider_id": 3,
//             "media_file": "/media/images/home/photo-1560066984-138dadb4c035.jpg",
//             "media_type": 2
//         }
//     ]
// }


const data=[{id:1,type:"video",link:"intro.mp4"},{id:2,type:"image",link:"slide2.png"},{id:3,type:"image",link:"slide3.png"}];

class HomeSlider extends Component {
    constructor(props) {
        super(props);
          this.state = {
            currentIndex:1,
            itemToShow :'',
            datass :[],
            // itemToShow:data[0]
            autoplaySpeed:20000,
            
        
          };
          this.interval = null;
          
      
    }


    componentDidMount() {

        this.getImageSliders(); 
        // this.interval = setInterval(this.transitionToNextSlide.bind(this), 10000);  
    }

    // transitionToNextSlide() {
    //   // alert();
    //    this.state.datass.map((slider, index) => (
    //     alert(index),
    //     this.setState({newIndex :this.state.currentIndex+1}),
    //     this.setState({itemToShow:slider})
    //   )
    //    )
    // }
  

            getImageSliders(){
                  axios({
                    method: 'post',
                    url: BASE_URL+'api/inspire/home_page_slider_view',
                    // headers: { "Access-Control-Allow-Origin": "*",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" ,'Access-Control-Allow-Headers':"Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,Authorization","Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS" }, 
                    headers: { 'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" }, 
                    data: ""
                    
                  }).then( (response)=> {
                  
                    // console.log(response.data);
                    const newjson=response.data;
                    const datass=newjson.home_page;
                    // const count = Object.keys(datass).length;
                    this.setState({datass:datass})
                    this.setState({itemToShow:datass[0]})
              
                    
                  }).catch((error)=> {
                    console.log('er',error);
                  
                  });
            }

         
        
    



    render() {

      const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: this.state.autoplaySpeed,
        pauseOnHover:true,
        beforeChange: (current, next) =>{handleChange(next)}
        // afterChange: (current, next) =>console.log(next),
      };

      const handleChange = (next) => {
        let media_type=this.state.datass[next].media_type;
        if(media_type == 1){

          this.setState({autoplaySpeed:20000})
        }else{
          this.setState({autoplaySpeed:5000})
        }
     
      };
      return (

        <>
         <div className="blackbx homeslider">
            <Slider 
              ref={slider => (this.slider = slider)} 
              {...settings}
              
              
              >
            { this.state.datass.map((slider, index) => (
             <div >
              { slider.media_type == 1 &&
              
                <>
               
                  <video autoPlay muted loop width="100%" height="100%" class="fill-available">
                    <source src={BASE_URL+`${slider.media_file}`} type="video/mp4" />
                  Your browser does not support the video tag.
                  </video>
                  {/* {this.setState({autoplaySpeed:1000})} */}
              
                </>
              
              
              }
              
              { slider.media_type ==2 &&
               
                
                  <img src={BASE_URL+`${slider.media_file}`} alt="" class="fill-available img-responsive" />
                
                
              
              }
              </div>
             
            ))
            }
          </Slider>
        </div>
        </>
      )


              return (
                  <>
                    <div className="blackbx">

                      { this.state.itemToShow.media_type == 1 &&
                      <div>
                      <video autoPlay muted loop width="100%" height="100%" class="fill-available">
                      <source src={BASE_URL+`${this.state.itemToShow.media_file}`} type="video/mp4" />
                      Your browser does not support the video tag.
                      </video>
                      </div>

                      }
                      { this.state.itemToShow.media_type == 2 &&
                      <div>
                      <img src={BASE_URL+`${this.state.itemToShow.media_file}`} alt="" class="fill-available img-responsive" />
                      </div>

                      }

                    </div>
                    {/* Dots */}

                    <div className="dots">

                        { this.state.datass.map((slider, index) => (
                          //  this.transitionToNextSlide(slider),
                          // console.log(slider,'slider'),console.log(index,"index"),
                              <span className={this.state.itemToShow.home_page_slider_id === slider.home_page_slider_id?"dot":"dot dotblack" } key={index}
                              onClick={()=>this.setState({itemToShow:slider})}
                              >
                              
                                
                              </span>
                          ))
                        }

                        {/* {this.state.datass.map((key)=>

                          <span key={home_page_slider_id}  className={key.home_page_slider_id===1?"dot":"dot dotblack"} onClick={()=>{this.setState({itemToShow:this.state.val})}} />

                        )} */}

                      {/* <span className={this.state.itemToShow.home_page_slider_id===1?"dot":"dot dotblack"} onClick={()=>{this.setState({itemToShow:this.state.datass[0]})}} />
                      <span className={this.state.itemToShow.home_page_slider_id===2?"dot":"dot dotblack"} onClick={()=>{this.setState({itemToShow:this.state.datass[1]})}}/>
                      <span className={this.state.itemToShow.home_page_slider_id===3?"dot":"dot dotblack"} onClick={()=>{this.setState({itemToShow:this.state.datass[2]})}}/> */}
                    </div>

                  </>


              );


     
       
    }
}



export default HomeSlider;