import React, { Component } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import {

  Link
} from "react-router-dom";
import { BASE_URL } from '../helper';

const axios = require('axios');

const validationSchema = yup.object().shape({
//   userName: yup.string().required().label('User Name'),
  // lastName: yup.string().required().label('Last Name'),
  // email: yup.string().required().email().label('Email'),
  newPassword: yup.string().required().label('Password'),
  confirmPassword: yup.string().required().label('Confirm Password')
  .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  // password: yup.string().required().label('Password').matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase,(A-Z) One Lowercase(a-z), One Number(0-9) and one special case Character"
  // ),
});
class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
      }



    SignupApiCall=(newPassword,confirmPassword)=>{
        console.log();
        //   const params = {
        
        // UserName,Email,Password
            
        //     };
          // axios.post('https://qbankapi.azurewebsites.net/api/Auth/RegisterUser/', params,{
          //   headers: { 'Content-Type': 'application/json' }
          // })
          axios({
            method: 'post',
            url: BASE_URL+'api/accounts/login',
            headers: { "Access-Control-Allow-Origin": "*",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json' }, 
            data: {
                newPassword,confirmPassword
            }
          })
          .then( (response)=> {
            console.log(response);
            // this.setState({loader:false});
            // this.setState({errorMessage:""});
            // if(response.data.status == 1){
            //   this.setState({registrationCompleted:true});
            // }else{
            //   this.setState({errorMessage:"Something went wrong"});
            // }
            
          })
          .catch((error)=> {
            console.log('er',error);
            // this.setState({errorMessage:error.response.data.result})
            // this.setState({loader:false});
          });
        }
    render() {
        return (
            <section className="signin">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-xs-12 col-md-6 login-image2" />
                <div className="col-sm-12 col-xs-12 col-md-6 space-top">
                  <div className="col-sm-12 col-md-8 ml-3">
                  <Link to="/" className="logo-signin"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/signin2.png`} alt="logo" /></Link>
                  </div>
                  <Formik
            initialValues={{ newPassword:'',confirmPassword:''}}
            onSubmit={(values, { setSubmitting, resetForm }) => {
            
            //  this.setState({loader:true});
              // setTimeout(() => {
              //   actions.setSubmitting(false);
              // }, 1000);
              let { newPassword,confirmPassword}=values;
              this.SignupApiCall( newPassword,confirmPassword);
      
            }}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <>
              <form onSubmit={formikProps.handleSubmit}>
                    <div className="signupform">
                      <h2>Reset Password</h2>
                      <div className="form-group col-sm-12 col-md-8">
                        <label htmlFor="uname">Enter new Password</label>
                        <input type="password"
                     name="newPassword"
                     onBlur={formikProps.handleBlur}
                     onChange={formikProps.handleChange}
                     value={formikProps.values.newPassword}
                      className="form-control" id="npass" placeholder="Enter New Password" />
                       {formikProps.errors.newPassword && formikProps.touched.newPassword &&
                 <small id="emailHelp" className="form-text text-warning">{formikProps.errors.newPassword}</small>
                }
                      </div>
                      <div className="form-group col-sm-12 col-md-8">
                        <label htmlFor="uname">Confirm new Password</label>
                        <input type="password"
                     name="confirmPassword"
                     onBlur={formikProps.handleBlur}
                     onChange={formikProps.handleChange}
                     value={formikProps.values.confirmPassword}
                      className="form-control" id="cpass" placeholder="Confirm Password" />
                       {formikProps.errors.confirmPassword && formikProps.touched.confirmPassword &&
                 <small id="emailHelp" className="form-text text-warning">{formikProps.errors.confirmPassword}</small>
                }
                      </div>
                      <div className="col-sm-12 col-md-8">
                        <button type="submit" className="form-control btn">Submit</button>
                      </div>
                    </div>
                  </form>
 </>
              )}
          </Formik>
                </div>
              </div>
            </div>
          </section>
        );
    }
}

export default ResetPassword;