import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
// import { XMasonry, XBlock } from "react-xmasonry"; // Imports precompiled bundle
import { XMasonry, XBlock } from "react-xmasonry/dist/index.js";
// import Masonry from 'react-masonry-component';
import Masonry from 'react-masonry-css'
import { cartDetails,bookmarkItems,setSearchKey,setSearchUrl  } from '../action';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {

  Link
} from "react-router-dom";
import Rating from "react-rating";
import { SRLWrapper } from "simple-react-lightbox";
import { BASE_URL } from "../helper";
import { GetAssetList,GetCategories,GetStyles,GetColors,GetForms } from "../utils/common";
import Login from './Login'
// import Modal from 'react-modal';
import { Modal,Button } from 'react-bootstrap';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword'
import LightBox from '../widgets/LightBox';
import CheckOtp from '../pages/CheckOtp';

// const customStyles = {
//   content: {
//     top: '10%',
//     left: '10%',
//     right: '10%',
//     bottom: '10%',

//   },
// };
// Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.6';
const axios = require('axios');
const breakpoints = {
  default: 3,
  1100: 2,
  700: 1
};
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
class GraphicTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objects:null,
      lightboxDisplay:false,
      imagesToShow:[],
      productIDToLink:"",
      activeProductName:"Product",
      activeProductPrice:"100",
      seller_id: "",
      bookmarks:[],
      searchOption:"#",
      category:[],
      styles:[],
      style_ids:[],
      sort_ids:[],
      colors:[],
      color_ids:[],
      forms:[],
      form_ids:[],
      category_filter:[],
      asset_list:[],
      asset_ids:[],
     imagetype:[],
     imagetype_ids:[],
     imagesize:[],
     imagesize_ids:[],
     orientation:[],
     orientation_ids:[],
     people:[],
     people_ids:[],
     gender_ids:[],
     gender:[],
      showModal: false,
      formType:null,
      showBookmarkModal:false,
      total_items:0,
      next:null,
      previous:null,
      currentPage:1,
      perPage:20,
      pageNumbers:[],
      page:1,
      user_id:'',
      favorite:"0",
      search_text:this.props.location.state?this.props.location.state.key:this.props.search.key,
      search_url:this.props.location.state?this.props.location.state.url:this.props.search.url,

    };
    this.myRef = React.createRef();
    this.bookmarkButtonRef = React.createRef();
    this.category_handler = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleOptionClick=this.handleOptionClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  handleOpenModal= (form)=> {
    this.setState({formType:form},()=>{this.setState({ showModal: true })});
  }

  handleCloseModal = ()=> {
    this.setState({ showModal: false });
  }
  searchOptionHandler=(event)=>{
    this.props.setSearchUrl(event.target.value);
    // this.setState({searchOption: event.target.value});
  }

  enterKeyPressed=(event)=>{
    // console.log(event.keyCode)
    if (event.keyCode == 13) {
      this.handleOptionClick();
    }
  }
  handleOptionClick = () => {

    // this.props.history.push(this.state.searchOption)
    if(this.props.search.url !="/graphics" &&  this.props.search.url !="" && this.props.search.url !="#"){
      this.props.history.push({
        pathname: this.props.search.url,
        state: { key: this.props.search.key,url: this.props.search.url}
    });
    }else{

      this.getProducts();
    }

  };
  showModal=()=>{
    this.myRef.current.classList.add('show')
    this.bookmarkButtonRef.current.classList.remove('show-bookmark-animation')
  }
  hideModal=()=>{
    this.myRef.current.classList.remove('show')
    // this.bookmarkButtonRef.current.classList.add('show-bookmark-animation')
  }
  getFilterItems(){
    return new Promise((resolve,reject)=>{
      axios({
        method: 'get',
        url: BASE_URL+'api/product/graphics_filter_items',
        headers: { 'Content-Type': 'application/json' },
      })
      .then( (response)=> {
        let assetData = response.data.assets;
        assetData = assetData.filter((item) => item.product_type == "graphics").map(({ id, asset_type }) => ({ id, asset_type }));

        this.setState({category:response.data.category,imagetype:response.data.imagetype,imagesize:response.data.imagesize,orientation:response.data.orientation,people:response.data.people,asset_list:assetData},()=>{
          resolve(true);
        });
          // alert(JSON.stringify(response));

      })
      .catch((error)=> {
        console.log(error);
        reject(true);

      });
    });

  }
componentDidMount(){
  const self = this;
  // const node = this.myRef.current;
  // this.myRef.current.classList.add('show')
  // this.myRef.current.display='block';
  // console.log(this.myRef.current.classList)

  // this.modal('show');
  // setTimeout(()=>{this.myRef.current.classList.add('show')},3000)

  // let token='e4bc9dfe25328197be7ae7bd230c0a2434e8ca43';
  const token = localStorage.getItem('omg_token');
  let id = localStorage.getItem('user_id');



//   axios({
//     method: 'post',
//     url: 'http://165.22.212.233:8002/api/product/objectlist',
//     headers: { 'Content-Type': 'application/json' },
//     data:{ "filter": { "object_asset_ids":[],
//     "category":[{"category_id":"","sub_category_ids":[]},{"category_id":"","sub_category_ids":[]}
//                 ],
//     "style_ids":[],
//     "color_ids":[],
//     "form_ids":[]

// }}
//   })
//   .then( (response)=> {

//     this.setState({objects:response.data.objects})
//     this.setState({completed:true});


//   })
//   .catch((error)=> {
//     console.log('er',error);
//     this.setState({error:true});

//   });

Promise.all([ this.getFilterItems()]).then((result)=>{
  this.getProducts();
}).catch((err)=>{
  console.log("error",err);
  this.getProducts();
})
this.props.setSearchUrl("/graphics");
// this.getBookmarks(token,id);

this.$el = window.jQuery(this.el);
this.$el.select2({
  dropdownCssClass: "custom-dropdown",


});
// this.$ell = window.jQuery(this.ell);
// this.$ell.flexImages({ rowHeight: 300 });
}

getBookmarks=(token,id)=>{
  axios({
    method: 'post',
    url: BASE_URL+'api/cart/bookmarklist',
    headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
    data:{
      "user_id":id,
    }
  })
  .then( (response)=> {
    console.log("fav",response)
    this.setState({bookmarks:response.data.bookmark_items})
    // this.setState({completed:true});


  })
  .catch((error)=> {
    console.log('er',error);
    this.setState({error:true});

  });

}
getAssetList() {
  GetAssetList()
    .then((res) => {
      let data = res;
      data = data.filter((item) => item.product_type == "graphics").map(({ id, asset_type }) => ({ id, asset_type }));
      this.setState({ asset_list: data });
    })
    .catch((err) => {
      this.setState({ error: true });
    });


}

handleScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
}

getProducts=()=>{
  const token = localStorage.getItem('omg_token');
  let id = localStorage.getItem('user_id');
  axios({
    method: 'post',
    url: BASE_URL+`api/product/graphics_results?page=${this.state.page}`,
    headers: { 'Content-Type': 'application/json' },
    data:{ "filter": { "asset_ids":this.state.asset_ids,
     "category_ids":this.state.category_filter,
    "imagetype_ids":this.state.imagetype_ids,
    "imagesize_ids":this.state.imagesize_ids,
    "orientation_ids":this.state.orientation_ids,
    "people_ids":this.state.people_ids,
    "gender":this.state.gender_ids,
},
"search_text":this.props.search.key,
"user_id":this.state.user_id,
    "favourite":this.state.favorite,
}
  })
  .then( (response)=> {

    this.setState({objects:response.data.results,total_items:response.data.count?response.data.count:0,completed:true,next:response.data.next,previous:response.data.previous});
    if(response.data.results.length >0){
      let pages = [];
      for (let i = 1; i <= Math.ceil(response.data.count / this.state.perPage); i++) {
        pages.push(i);
      }
      this.setState({pageNumbers:pages});
      this.handleScroll()
    }


  })
  .catch((error)=> {
    console.log('er',error);
    this.setState({error:true});

  });

}
getCategories = () => {

  GetCategories()
  .then((res) => {
    // console.log("category:",res);
    this.setState({ category: res });
  })
  .catch((err) => {
    this.setState({ error: true });
  });




};
getStyles = () => {
  GetStyles()
  .then((res) => {
    // console.log("style:",res);
    this.setState({ styles: res });
  })
  .catch((err) => {
    this.setState({ error: true });
  });


};
getColors = () => {
  GetColors()
  .then((res) => {
    // console.log("color:",res);
    this.setState({ colors: res });
  })
  .catch((err) => {
    this.setState({ error: true });
  });

};
getForms = (token) => {
  GetForms()
    .then((res) => {
      // console.log("form:",res);
      this.setState({ forms:res });
    })
    .catch((err) => {
      this.setState({ error: true });
    });

};
handleClick =  (event) => {
  event.preventDefault();
  this.setState({
    page: Number(event.target.id)
  },()=>{
    this.getProducts();
  });
}
handleNextClick =  (event) => {
  event.preventDefault();
  if(this.state.next != null){

    var page = this.state.next.split('page=')[1];
    this.setState({
    page: page
  },()=>{
    this.getProducts();
  });
  }

}
handlePrevClick =  (event) => {
  event.preventDefault();
  if(this.state.previous != null){

    var page = this.state.previous.split('page=')[1];
    if(!page){
      page=1;
    }


    this.setState({
    page: page
  },()=>{
    this.getProducts();
  });
  }

}


showLightBox = (images,id,product_name,price, seller_id) => {
  //set imageToShow to be the one that's been clicked on
  this.setState({imagesToShow:images})
  this.setState({productIDToLink:id})
  this.setState({activeProductName:product_name})
  this.setState({activeProductPrice:price})
  this.setState({lightboxDisplay:true})
  this.setState({ seller_id: seller_id });

};
setCategoryCheck(id){

  if(this.state.category_filter.includes(id)){

    return true;

 }else{
  return false;
 }
}
setImagetypeCheck(id){
  if(this.state.imagetype_ids.includes(id)){
    return true;

 }else{
  return false;
 }

}
setImagesizeCheck(id){
  if(this.state.imagesize_ids.includes(id)){
    return true;

 }else{
  return false;
 }
}

setOrientationCheck(id){
  if(this.state.orientation_ids.includes(id)){
    return true;

 }else{
  return false;
 }
}
setPeopleCheck(id){
  if(this.state.people_ids.includes(id)){
    return true;

 }else{
  return false;
 }
}
setGenderCheck(id){
  if(this.state.gender_ids.includes(id)){
    return true;

 }else{
  return false;
 }
}
setSortCheck(id){
  if(this.state.sort_ids.includes(id)){
    return true;

 }else{
  return false;
 }

}
setSubcategoryCheck(category,subcategory){
  if(this.state.category_filter.length > 0){
    this.state.category_filter.forEach(item =>{
      if(item.category_id === category){
        if(item.sub_category_ids.includes(subcategory)){
          return true;

       }else{
        return false;
       }



      }else{
        return false;
      }

    })
  }
}
addToBookMark=async(productIDToLink)=>{
  // alert(productIDToLink)
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');

  // this.props.history.push("/login")
  return Promise.reject();
  }else{

    if(this.props.bookmarkItems.length >= 20){
      this.props.addToast(`Sorry,Your limit exceed only 20 product is allowed in bookmark`, {
        appearance: 'warning',
        autoDismiss: true,
      })
    }else{
      return await axios({
        method: 'post',
        url: BASE_URL+'api/cart/bookmark/',
        headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
        data: {
          "product_type":"graphics",
          "object":"",
          "material":"",
          "graphics":productIDToLink,
          "user_id":id,


        }
      })
      .then( (response)=> {
         this.props.addToast(`${this.state.activeProductName} added to bookmarks`, {
        appearance: 'success',
        autoDismiss: true,
      })

       console.log(response)
      //  this.showModal();
      this.getCategoryState();
      this.props.updateBookmarkItems();
        return Promise.resolve(response);


      })
      .catch((error)=> {
        this.getCategoryState();
        this.props.updateBookmarkItems();
        return Promise.reject(error);

      });
    }

  }


}
addToCart=async(productIDToLink, seller_id)=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');
  // this.props.history.push("/login")
  return Promise.reject();
  }else{
    return await axios({
      method: 'post',
      url: BASE_URL+'api/cart/cartitem/',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },

      data: {cart_items:[{
        "product_type":"graphics",
        "object":"",
        "material":"",
        "graphics":productIDToLink,
        "user_id":id,
        "seller_id": seller_id,
        "unit_price":this.state.activeProductPrice,
        "quantity":"1"
      }]}

    })
    .then( (response)=> {
       this.props.addToast(`${this.state.activeProductName} added to cart`, {
      appearance: 'success',
      autoDismiss: true,
    })

      this.props.updateCartDetails();
      this.props.updateBookmarkItems();
      return Promise.resolve(response);


    })
    .catch((error)=> {

      return Promise.reject(error);

    });
  }


}
addImagetypeId=(item)=>{

  if (this.state.imagetype_ids.includes(item.id)) {
    const newArray = this.state.imagetype_ids.filter(e => e !== item.id);
    this.setState({imagetype_ids:newArray},()=>{
      this.getProducts();
    });

} else {
    const  newArray = [...this.state.imagetype_ids, item.id];
    this.setState({imagetype_ids:newArray},()=>{
      this.getProducts();
    });
}


// alert(JSON.stringify(this.state.style_ids));
}
addSortId=(id)=>{

  if (this.state.sort_ids.includes(id)) {
    const newArray = this.state.sort_ids.filter(e => e !== id);
    this.setState({sort_ids:newArray},()=>{
      // this.getProducts();
    });

} else {
    const  newArray = [...this.state.sort_ids, id];
    this.setState({sort_ids:newArray},()=>{
      // this.getProducts();
    });
}



}
addImagesizeId=(item)=>{

  if (this.state.imagesize_ids.includes(item.id)) {
    const newArray = this.state.imagesize_ids.filter(e => e !== item.id);
    this.setState({imagesize_ids:newArray},()=>{
      this.getProducts();
    });

} else {
    const  newArray = [...this.state.imagesize_ids, item.id];
    this.setState({imagesize_ids:newArray},()=>{
      this.getProducts();
    });
}


// alert(JSON.stringify(this.state.style_ids));
}
addColorId=(item2)=>{

  if (this.state.color_ids.includes(item2.id)) {
    const newArray2 = this.state.color_ids.filter(e => e !== item2.id);
    this.setState({color_ids:newArray2},()=>{
      this.getProducts();
    });

} else {
    const  newArray2 = [...this.state.color_ids, item2.id];
    this.setState({color_ids:newArray2},()=>{
      this.getProducts();
    });
}


// alert(JSON.stringify(this.state.style_ids));
}
addFormId=(item)=>{

  if (this.state.form_ids.includes(item.id)) {
    const newArray3 = this.state.form_ids.filter(e => e !== item.id);
    this.setState({form_ids:newArray3},()=>{
      this.getProducts();
    });

} else {
    const  newArray3 = [...this.state.form_ids, item.id];
    this.setState({form_ids:newArray3},()=>{
      this.getProducts();
    });
}



// alert(JSON.stringify(this.state.style_ids));
}
addOrientationId=(item)=>{

  if (this.state.orientation_ids.includes(item.id)) {
    const newArray = this.state.orientation_ids.filter(e => e !== item.id);
    this.setState({orientation_ids:newArray},()=>{
      this.getProducts();
    });

} else {
    const  newArray = [...this.state.orientation_ids, item.id];
    this.setState({orientation_ids:newArray},()=>{
      this.getProducts();
    });
}


// alert(JSON.stringify(this.state.style_ids));
}
addPeopleId=(item)=>{

  if (this.state.people_ids.includes(item.id)) {
    const newArray = this.state.people_ids.filter(e => e !== item.id);
    this.setState({people_ids:newArray},()=>{
      this.getProducts();
    });

} else {
    const  newArray = [...this.state.people_ids, item.id];
    this.setState({people_ids:newArray},()=>{
      this.getProducts();
    });
}


// alert(JSON.stringify(this.state.style_ids));
}
addGenderId=(item)=>{

  if (this.state.gender_ids.includes(item)) {
    const newArray = this.state.gender_ids.filter(e => e !== item);
    this.setState({gender_ids:newArray},()=>{
      this.getProducts();
    });

} else {
    const  newArray = [...this.state.gender_ids, item];
    this.setState({gender_ids:newArray},()=>{
      this.getProducts();
    });
}


// alert(JSON.stringify(this.state.style_ids));
}
setAsset=(id)=>{
  let assetId=[];
  if(id != 0){
    assetId.push(id);
  }

  this.setState({asset_ids:assetId,favorite:"0"},()=>{
    this.getProducts();
  });

  }
  addCategoryFilter=(item)=>{


    if (this.state.category_filter.includes(item.id)) {
      const newArray5 = this.state.category_filter.filter(e => e !== item.id);
      this.setState({category_filter:newArray5},()=>{
        this.getProducts();
      });

  } else {
      const  newArray5 = [...this.state.category_filter, item.id];
      this.setState({category_filter:newArray5},()=>{
        this.getProducts();
      });
  }

    }

    addSubcategoryToFilter=(category,subcategory)=>{
      if(this.state.category_filter.length > 0){
        this.state.category_filter.forEach(item =>{
          if(item.category_id === category){
            if(item.sub_category_ids.includes(subcategory)){
              item.sub_category_ids = item.sub_category_ids.filter(item2 => item2 !== subcategory);

           }else{
            item.sub_category_ids.push(subcategory);
           }

            const filteredCategory = this.state.category_filter.filter(category => category.category_id !== category);

    this.setState({
      category_filter:filteredCategory
    },()=>{
      this.getProducts();
    });

          }else{
            this.setState({category_filter:[...this.state.category_filter,{category_id:category,sub_category_ids:[subcategory]}]},()=>{
              this.getProducts();
            });
          }

        })
      }else{
        this.setState({category_filter:[...this.state.category_filter,{category_id:category,sub_category_ids:[subcategory]}]},()=>{
          this.getProducts();
        });
      }



      }
hideLightBox = () => {
  this.setState({lightboxDisplay:false})
}
hideBookmark=()=>{
  this.inputNode.classList.remove('show');
}
getCategoryState = () => {
  // console.log(this.inputNode)
  this.inputNode.classList.add('show');
  // return this.category_handler.current.getState();
};
onKeyUp(event) {
  if (event.charCode === 13) {
    if(event.target.value >0){

      this.setState({page:event.target.value},()=>{
        this.getProducts();
      })
    }


  }
}
favouriteClick = ()=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');
  // this.props.history.push("/login")
  return Promise.reject();
  }else{
    this.setState({user_id:id,favorite:"1",asset_ids:[]},()=>{
      this.getProducts();
    });
  }
}
handleInputChange = (e) =>{
  this.setState({search_text:e.target.value});

}
generateTiles=(data)=>{

let tiles=  data.map((val)=>{
  console.log(val,'val');
    return(<div className="masonry-card"  key={val.id}>
                                     <a class=""  onClick={() => this.showLightBox(val.images,val.id,val.graphics_name,val.price,val.is_favourite, val.seller_id)} >
                                    <img src={BASE_URL.slice(0, -1)+`${val.thumbnail_image}`} alt="" />
                                    </a>
                                    <span  onClick={()=>{console.log({val});this.addToBookMark(val.id);}}  className="right-bookmark-button"><img className="right-bookmark-icon" style={{width:11}} src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} /></span>
                                    <div className="masonry-card-footer">
                                        <h5 class="card-title">
                                            <Link to={{
                                            pathname: `/productDetails/${'graphics'}/${val.id}`,
                                            // search: '?id=modus-create',
                                            state: { id: val.id,product_type:'graphics' }
                                            }}
                                            >{val.graphics_name.toUpperCase()}
                                            </Link>
                                        </h5>


                                        <p className="card-text">{val.avg_rating?val.avg_rating:0}
                                        <span style={{marginLeft:8}}> <Rating
                                        readonly
                                        initialRating={val.avg_rating?val.avg_rating:0}
                                        emptySymbol={<i className="fa fa-star " style={{color:'gray',fontSize:10}}></i>}
                                        fullSymbol={<i className="fa fa-star checked" style={{fontSize:10}}></i>}
                                            />
                                            </span>
                                        </p>

                                    </div>

                                </div>
    )
  })
return tiles;
}
  render() {
    const settings = {
      dots: false,
      arrow:true,
      infinite: true,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll:2,
      centerMode: true,
     centerPadding: '0',
    //  adaptiveHeight: true

    };
    const {search_url}=this.state;
    // console.log(this.state.objects);
    return (
 <>
    <Header showBookmarkModal={this.state.showBookmarkModal}  hideBookmark={()=>this.hideBookmark()} inputRef={node => this.inputNode = node} ref={this.category_handler} url="/graphics" getProducts={this.getProducts}/>
     <>
     <Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>
  {/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
  </Modal.Header> */}
  <Modal.Body>
{this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
{this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}


</Modal.Body>
  {/* <Modal.Footer>
    <Button variant="secondary" onClick={()=>this.setState({showBModal:false})}>
      Close
    </Button>
    <Button variant="primary" onClick={()=>this.setState({showBModal:false})}>
      Save Changes
    </Button>
  </Modal.Footer> */}
</Modal>



     {/* <button onClick={()=>this.showModal()}>show me</button> */}


     {/* <div onClick={()=>this.showModal()} ref={this.bookmarkButtonRef} className="show-bookmark ">
        <div className="txt">Show Bookmarks</div>
          <span className="show-bookmark-close" ><img className="close" src={`${process.env.PUBLIC_URL}/assets/images/cross.png`} /></span>
      </div> */}



     { this.state.lightboxDisplay ?

             <LightBox hideLightBox={()=>this.hideLightBox()} activeProductName={this.state.activeProductName} imagesToShow={this.state.imagesToShow} productIDToLink={this.state.productIDToLink} addToBookMark={(val)=>this.addToBookMark(val)}  addToCart={(val)=>this.addToCart(val, this.state.seller_id)} product_type={'graphics'} isFavourite={this.state.is_favourite} getProduct={()=>this.getProducts()}/>

: '' }





        {/* Search Section */}

      <section className="search-container">
        <img src={`${process.env.PUBLIC_URL}/assets/images/blue.png`}  width="100%" height="337px" className="common-bg" />
        <div className="container-fluid bgsearch">
          <div className="row">
            <div className="col-sm-12 col-lg-6 mx-auto p-5 mt-5 searchbox">
              <div className="form-group has-search">
              <select className="search-dropdwn" ref={el => this.el = el} >
              <option value="/objects" >Objects</option>
                    <option value="/materials" > Materials</option>
                    <option value="/graphics" selected={true}>Graphics</option>

                  </select>
                  <input type="text" className="form-control search" placeholder="Get Casting..." value={this.props.search.key} onChange={(e)=>{this.props.setSearchKey(e.target.value)}} onKeyDown={(event)=>{this.enterKeyPressed(event)}}/>
                  <button onClick={()=>this.handleOptionClick()}><span className="fa fa-search form-control-feedback" /></button>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* Shop Section */}
        <section className="shoparea">
          <div className="container-fluid">
            <div className="row">

            <div className="side-panel">
              {/* Start col-3 */}
              <div className="col-xl-12 col-md-12 px-4 order-2 order-md-1">
                <div className="shop-sidebar card">
                  <div className="card-header py-3 d-flex flex-row justify-content-between">
                    <span className="heading_text1">Filters</span>
                    <span className="heading_text2" onClick={()=>{this.setState({category_filter:[],imagetype_ids:[],imagesize_ids:[],orientation_ids:[],people_ids:[],gender_ids:[],sort_ids:[]},()=>{
                      this.getProducts();
                    })}}>Clear All</span>
                  </div>
                  {/* Sort Section */}
                  <div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Sort</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.sort_ids.length > 0 ){
                      this.setState({sort_ids:[]},()=>{
                        // this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                        <li className={this.setSortCheck(1) ? ("chk-item active") : ("chk-item")} >
                          <div className="checkbox d-flex flex-row">
                            <span className="textspace" />
                            <input type="checkbox" checked={this.setSortCheck(1)} onClick={() => this.addSortId(1)}  />
                              <label>Most Downloaded</label>

                          </div>
                        </li>


                        <li className={this.setSortCheck(2) ? ("chk-item active") : ("chk-item")} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setSortCheck(2)} onClick={() => this.addSortId(2)}  />
                            <label>Recently Uploaded</label>

                          </div>
                        </li>
                        {/* <li className="chk-item" >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setSortCheck(3)} onClick={() => this.addSortId(3)}  />
                            <label>Marvel of Week</label>

                          </div>
                        </li> */}
                  </ul>
                  {/* Categories Section */}
                  <div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">All Categories</span>
                    <span className="heading_text4 "  onClick={()=>{if(this.state.category_filter.length > 0 ){
                      this.setState({category_filter:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.category.map((item)=>{
                      return (
                        <li className={this.setCategoryCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                            <div className="checkbox d-flex flex-row">
                              <span className="textspace" />
                              <input type="checkbox" checked={this.setCategoryCheck(item.id)}  onClick={()=>{this.addCategoryFilter(item)}}  />
                                <label>{item.category_name}</label>
                                {/* <label className="text-count">(124)</label> */}
                              </div>
                            </li>
                        )


                 })}
</ul>
                  {/* Image TypeSection */}
                  {this.state.imagetype.length > 0 && (
                    <>
<div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Image Type</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.imagetype_ids.length > 0 ){
                      this.setState({imagetype_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.imagetype.map((item)=>{

                   return (
                    <li className={this.setImagetypeCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setImagetypeCheck(item.id)} onClick={() => this.addImagetypeId(item)} />
                            <label>{item.type}</label>
                            {/* <label className="text-count">(124)</label> */}
                          </div>
                        </li>
                    )

                  })}
                  </ul>
                    </>
                  )}
               {/* Image Size Section */}
               {this.state.imagesize.length > 0 && (
                    <>
<div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Image Size</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.imagesize_ids.length > 0 ){
                      this.setState({imagesize_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.imagesize.map((item)=>{
                     return (
                      <li className={this.setImagesizeCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                          <div className="checkbox d-flex flex-row">
                            <span className="textspace" />
                            <input type="checkbox" checked={this.setImagesizeCheck(item.id)} onClick={() => this.addImagesizeId(item)}  />
                              <label>{item.size}</label>
                              {/* <label className="text-count">(124)</label> */}
                            </div>
                          </li>
                      )


                  })}
                  </ul>
                    </>
                  )}
                 {/* Orientation Section */}
                 {this.state.orientation.length > 0 && (
                    <>
<div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Orientation</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.orientation_ids.length > 0 ){
                      this.setState({orientation_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.orientation.map((item)=>{

                   return (
                    <li className={this.setOrientationCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setOrientationCheck(item.id)} onClick={() => this.addOrientationId(item)} />
                            <label>{item.orientation_name}</label>
                            {/* <label className="text-count">(124)</label> */}
                          </div>
                        </li>
                    )


                  })}
                  </ul>
                    </>
                  )}
                {/* Orientation Section */}
                {this.state.people.length > 0 && (
                    <>
<div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">People</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.people_ids.length > 0 ){
                      this.setState({people_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  {this.state.people.map((item)=>{

                   return (
                    <li className={this.setPeopleCheck(item.id) ? ("chk-item active") : ("chk-item")} dataId={item.id} >
                        <div className="checkbox d-flex flex-row">
                          <span className="textspace" />
                          <input type="checkbox" checked={this.setPeopleCheck(item.id)} onClick={() => this.addPeopleId(item)} />
                            <label>{item.people}</label>
                            {/* <label className="text-count">(124)</label> */}
                          </div>
                        </li>
                    )


                  })}
                  </ul>
                    </>
                  )}
                  {/* Gender Section */}

<div className="p-3 d-flex flex-row justify-content-between">
                    <span className="heading_text3">Gender</span>
                    <span className="heading_text4" onClick={()=>{if(this.state.gender_ids.length > 0 ){
                      this.setState({gender_ids:[]},()=>{
                        this.getProducts();
                      });
                    }}}>Clear</span>
                  </div>
                  <ul className="checkbox-menu">
                  <li className={this.setGenderCheck('male') ? ("chk-item active") : ("chk-item")}  ><label><input type="checkbox"  checked={this.setGenderCheck('male')} onClick={() => this.addGenderId('male')}  /><span className="textspace" />Male</label></li>
                  <li className={this.setGenderCheck('female') ? ("chk-item active") : ("chk-item")}  ><label><input type="checkbox"  checked={this.setGenderCheck('female')} onClick={() => this.addGenderId('female')}  /><span className="textspace" />Female</label></li>
                  </ul>

                </div>
              </div>
              </div>
              {/* End col-3 */}
              <div className="listing">
              {/* Start col-9 */}
              <div className="col-xl-12 col-md-12 px-4 order-1 order-md-2">
                <ul className="nav nav-products mb-3 products-tab" id="products-tab" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active btn-tabs" id="products-home-tab" data-toggle="pill" href="#products-profile" role="tab" aria-controls="products-home" aria-selected="true" onClick={()=>{this.setAsset(0)}}>All</a>
                  </li>
                  {this.state.asset_list.length > 0 && (<>
                    {this.state.asset_list.map((asset)=>{
                        let href = "#products-"+`${asset.id}`;
                    return  <li className="nav-item">
                    <a className="nav-link btn-tabs" id="products-profile-tab" data-toggle="pill" href={href} role="tab" aria-controls="products-profile" aria-selected="false" data-asset_id={asset.id} onClick={()=>{this.setAsset(asset.id)}}>{asset.asset_type}</a>
                  </li>;
                    })}
                  </>)}
                  <li className="nav-item">
                    <a className="nav-link  btn-tabs" id="products-home-tab" data-toggle="pill" href="#products-profile" role="tab" aria-controls="products-home" aria-selected="true" onClick={this.favouriteClick}>Favourites</a>
                  </li>
                </ul>
                {/*Product Heading*/}
                <div className="prod_heading1">
                  <h1>Graphics</h1>


                  {/* <span className="tiny_desc">{this.state.total_items} Resources</span> */}

                </div>
                {/*Product Breadcrump*/}
                {/* <div className="prod_breadcrum tiny_desc">Showing {this.state.total_items} of {this.state.total_items}</div> */}
                <div className="tab-content mt-4" id="products-tabContent">
                  <div className="tab-pane fade show active" id="products-home" role="tabpanel" aria-labelledby="products-home-tab">
                    {/* Start Wrapper */}





                    <div className="d-flex flex-wrap justify-content-around flex-row">


                    {console.log(this.state.objects)}
 {this.state.objects &&
                    <Masonry

                        breakpointCols={breakpoints}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">

                     {/* {this.generateTiles(this.state.objects)} */}
                     {this.generateTiles((this.state.sort_ids.includes(1)  ?  (this.state.objects.sort((a, b) => b.download_count > a.download_count ? 1:-1))
                    :  (this.state.objects)))}
                            {/* {this.state.objects.map((val)=>


                                <><div className="masonry-card">
                                     <a class=""  onClick={() => this.showLightBox(val.images,val.id,val.graphics_name,val.price,val.is_favourite)} >
                                    <img src={`http://165.22.212.233:8002${val.thumbnail_image}`} alt="" />
                                    </a>
                                    <span  onClick={()=>{this.addToBookMark(val.id);}}  className="right-bookmark-button"><img className="right-bookmark-icon" style={{width:11}} src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} /></span>
                                    <div className="masonry-card-footer">
                                        <h5 class="card-title">
                                            <Link to={{
                                            pathname: `/productDetails/${'graphics'}/${val.id}`,
                                            // search: '?id=modus-create',
                                            state: { id: val.id,product_type:'graphics' }
                                            }}
                                            >{val.graphics_name.toUpperCase()}
                                            </Link>
                                        </h5>


                                        <p className="card-text">{val.avg_rating?val.avg_rating:0}
                                        <span style={{marginLeft:8}}> <Rating
                                        readonly
                                        initialRating={val.avg_rating?val.avg_rating:0}
                                        emptySymbol={<i className="fa fa-star " style={{color:'gray',fontSize:10}}></i>}
                                        fullSymbol={<i className="fa fa-star checked" style={{fontSize:10}}></i>}
                                            />
                                            </span>
                                        </p>

                                    </div>

                                </div></>
                            )} */}









 </Masonry>
                                          }

                    {/* <XMasonry>
        <XBlock>
            <div className="card">
                <h1>Simple Card</h1>
                <p>Any text!</p>
            </div>
        </XBlock>
        <XBlock width={ 2 }>
            <div className="card">
                <h1>Wider card</h1>
                <p>Any text!</p>
            </div>
        </XBlock>
    </XMasonry> */}
                {/* {this.state.objects&&

                <>
                {this.state.objects.map((val)=>
                  <div className="card prod-item">

                 <a class=""  onClick={() => this.showLightBox(val.images,val.id,val.graphics_name,val.price)} ><img src={`http://165.22.212.233:8002${val.thumbnail_image}`} className="card-img-top" alt=""/></a>
                 <span  onClick={()=>{this.addToBookMark(val.id);}}  className="right-bookmark-button"><img className="right-bookmark-icon" style={{width:11}} src={`${process.env.PUBLIC_URL}/assets/images/v1.png`} /></span>


                 <div className="card-body">
                   <h5 className="card-title"><Link to={{
  pathname: `/productDetails/${'graphics'}/${val.id}`,

  state: { id: val.id,product_type:'graphics' }
 }}
 >{val.graphics_name.toUpperCase()}</Link></h5>
                 <p className="card-text">{val.avg_rating?val.avg_rating:0}
                 <span style={{marginLeft:8}}> <Rating
                   readonly
                   initialRating={val.avg_rating?val.avg_rating:0}
                   emptySymbol={<i className="fa fa-star " style={{color:'gray',fontSize:10}}></i>}
                   fullSymbol={<i className="fa fa-star checked" style={{fontSize:10}}></i>}
                    />
                    </span>
                    </p>
                 </div>
               </div>
                )}

                </>
                } */}

{this.state.objects && this.state.objects.length == 0 && (
                  <>
                   <div className="col-md-12 info-msg">No content found..</div>
                  </>
                )}


                    </div>
                    {/* End Wrapper */}
                  </div>
                  {/* <div className="tab-pane fade" id="products-profile" role="tabpanel" aria-labelledby="products-profile-tab">
                    <div className="col-md-12 info-msg">No content found..</div>
                  </div>
                  <div className="tab-pane fade" id="products-contact" role="tabpanel" aria-labelledby="products-contact-tab">
                    <div className="col-md-12 info-msg">No content found..</div>
                  </div>
                  <div className="tab-pane fade" id="products-favorite" role="tabpanel" aria-labelledby="products-favorite-tab">
                    <div className="col-md-12 info-msg">Favorites</div>
                  </div> */}
                </div>

                <div className="col-xl-12 col-md-12 col-sm-12 ml-auto  d-flex flex-wrap flex-row justify-content-between pagination-wrapper">
                {/* Page no */}
                <div className="pagination-count goto">
                <span >{`${this.state.total_items} graphics available`}</span>

                  {/* Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp}/> */}
                  </div>
                {/* Pagination */}
                <nav aria-label="pagination">

                  <ul className="pagination">
                  <li className="page-item " style={{marginRight:40}}>
                  Go to page <input className="binput" type="text" name defaultValue={1} size={1} onKeyPress={this.onKeyUp}/> <span> of {this.state.pageNumbers[this.state.pageNumbers.length-1]}</span>
                    </li>
                    <li className="page-item ">
                      <a className="page-link prev" href="#" aria-label="Previous" onClick={this.handlePrevClick}>
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {this.state.pageNumbers.map((val)=>
 <li className={this.state.page == val ?"page-item active":"page-item"}><a className="page-link" href="#" id={val} onClick={this.handleClick}>{val}</a></li>
                    )}

                    {/* <li className="page-item "><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                    <li className="page-item ">
                      <a className="page-link next" href="#" aria-label="Next"  onClick={this.handleNextClick} >
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              </div>
              </div>
              {/* End col-9*/}
            </div>
            {/* End container*/}
          </div>
          {/* End Row*/}
        </section>
        {/* End Shop Section*/}


      </>

     <Footer/>
 </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.cartDetails.data);
	return {
		cartDetails: state.cartDetails.data,
    bookmarkItems:state.cartDetails.bookmark_items,
    search:{key:state.cartDetails.search_key,url:state.cartDetails.search_url}
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: (token,id) => dispatch(cartDetails(token,id)),
    updateBookmarkItems: () => dispatch(bookmarkItems()),
    setSearchKey: data => dispatch(setSearchKey(data)),
    setSearchUrl: data => dispatch(setSearchUrl(data)),
	};
};

export default withRouter(withToast(connect(mapStateToProps, mapDispatchToProps)(GraphicTest)));
