import React, { Component } from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import DownloadPage from './DownloadPage';
import Favourites from './Favourites';
import Uploads from './Uploads';

import {

    Link,
    withRouter
  } from "react-router-dom";
  import { NavHashLink } from 'react-router-hash-link';
  import {BASE_URL} from '../helper';
  const axios = require('axios');

  
class UserProfile extends Component {

 
    constructor(props) {
        super(props);
        this.state = {
          token:null,
          downloads:[],
         user_id:this.props.location.state?this.props.location.state.user_id:'',
         loading:true,
         user_info:null,
         tab_index:0,
         show_section:0,
         paramss:this.props.params_test,

         
        };
        console.log(props,'propsss');
        
      }
      
      randomDate(separator='/'){

        let newDate = new Date()
        newDate.setDate(newDate.getDate() + 60);
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
        }
      componentDidMount(){
        // alert(this.props.match.params.id);
        // alert(this.state.user_id);
        this.getProfileData().then((res)=>{
            this.setState({loading:false});
            // alert(this.state.user_id);
        }).catch((err)=>{
          console.log('er',err);  
        });
      }
      getProfileData(){
      
        const token = localStorage.getItem('omg_token');
        const user_id = localStorage.getItem('user_id');

        return new Promise((resolve,reject)=>{
            axios({
                method: 'post',
                url: BASE_URL+'api/profile/view',
                headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
                data:{ 
                  "user":this.props.match.params.id, 
                }
              })
              .then( (response)=> {
                // alert(JSON.stringify(response));
                this.setState({user_info:response.data.profile_details,profile_img:BASE_URL.slice(0, -1)+`${response.data.profile_details.image}`},()=>{
                    resolve(true);
                });
                // this.setState({completed:true});
            
                
              })
              .catch((error)=> {
                console.log('er',error);
                this.setState({error:true},()=>{
                    reject(true);
                });
            
              });
        });
        
      }
    render() {
      // alert();

     
        const{tab_index}=this.state;
        return (
            <>
            <Header/>
            <section className="profile-wrap">        
        <div className="container-fluid px-0 inner-page">


        {!this.state.loading && (
                <> 
          <div className="row mx-0">
            <div className="col-sm-3">
              <div className="card card-layout-1">
                <div className="card-header" style={{padding: '10px 0'}}>
                  <div className="row mx-0">
                    <div className="col-sm-4">
                    {this.state.user_info && this.state.user_info.image !="" && (
                            <>
                             <img src={BASE_URL.slice(0, -1)+`${this.state.user_info.image}`} style={{width:60,borderRadius:30,}} alt="" />
                            </>
                        )}
                        {!this.state.user_info && this.state.user_info.image =="" && (
                            <>
                             <img src={`${process.env.PUBLIC_URL}/assets/images/profile/avtar.png`} style={{width:60,borderRadius:30,}} alt="" />
                            </>
                        )}
                    </div>
                    <div className="col-sm-8">
                    
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <table cellPadding={0} cellSpacing={0} border={0} className="table">
                  <tbody>
                      <tr>
                        <td width="40%">Email{this.state.user_id} :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.email:''}</strong></td>
                      </tr>
                      <tr>
                        <td>Phone :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.phone:''}</strong></td>
                      </tr>
                      {/* <tr>
                        <td>Address :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.address:''}</strong></td>
                      </tr> */}
                      <tr>
                        <td>Country :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.country:''}</strong></td>
                      </tr>
                      <tr>
                        <td>City :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.city:''}</strong></td>
                      </tr>
                      <tr>
                        <td>Zipcode :</td>
                        <td><strong>{this.state.user_info?this.state.user_info.zipcode:''}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
       
              
            </div>
            <div className="col-sm-9">
              <div className="tab-container">
                <ul className="cl">
                  <li><a href="#" className={tab_index == 0 ? 'active':'' } onClick={()=>{
                      this.setState({tab_index:0});
                  }}>UPLOADS</a></li>
                  
                </ul>
              </div>
              <div className="listing" style={{marginLeft:0}}>

              {tab_index == 0 && (
                    <>
            <Uploads/>
                    </> 
                  )}
                 
             
              </div>
            </div>      
          </div>
          </>)}
        </div>
      </section>
           
            <Footer/>
            </>
        )
    }
}
export default withRouter((UserProfile));
