import React, { Component } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import {

  Link
} from "react-router-dom";
import { BASE_URL } from '../helper';
// import CheckOtp from './CheckOtp';

const axios = require('axios');

const validationSchema = yup.object().shape({
  userName: yup.string().required().label('User Name'),
  // lastName: yup.string().required().label('Last Name'),
  // email: yup.string().required().email().label('Email'),
//   password: yup.string().required().label('Password')
  // password: yup.string().required().label('Password').matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase,(A-Z) One Lowercase(a-z), One Number(0-9) and one special case Character"
  // ),
});
class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
          otpModal:false,
          email:'',
        };
      }
    componentDidMount=()=>{
    
    }
    SignupApiCall=(email)=>{
      //alert(email);
      this.setState({email:email});
      localStorage.setItem('omg_forgotemail',email);
        // console.log();
        //   const params = {
        
        // UserName,Email,Password
            
        //     };
          // axios.post('https://qbankapi.azurewebsites.net/api/Auth/RegisterUser/', params,{
          //   headers: { 'Content-Type': 'application/json' }
          // })
          
          this.props.handleOpenModal('CheckOtp');
          // return false;
          axios({
            method: 'post',
            url: BASE_URL+'api/accounts/getotp',
            headers: { "Access-Control-Allow-Origin": "*",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json' }, 
            data: {
              email
            }
          })
          .then( (response)=> {
            console.log(response);
            // this.setState({loader:false});
            // this.setState({errorMessage:""});
            if(response.data.response_code == 200){
              this.setState({'otpModal':true});
              // this.handleOpenModal('Login');
              this.props.handleOpenModal('CheckOtp')
            }
         
            
          })
          .catch((error)=> {
            console.log('er',error);
            
          });
        }
    render() {
    
        return (
            <section className="signin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-6 login-image2" />
            <div className="col-sm-12 col-xs-12 col-md-6  Modal-Overlay-Right">
              <div className="col-sm-12 col-md-8 ml-3">
              <Link to="/" className="logo-signin"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/signin2.png`} alt="logo" /></Link>
              </div>
              <Formik
            initialValues={{ userName:''}}
            onSubmit={(values, { setSubmitting, resetForm }) => {
            
            //  this.setState({loader:true});
              // setTimeout(() => {
              //   actions.setSubmitting(false);
              // }, 1000);
              let {userName}=values;
              this.SignupApiCall(userName);
      
            }}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <>
              <form onSubmit={formikProps.handleSubmit}>
                <div className="signupform">
                  
                  <div className="form-group form-group col-sm-12 col-md-8">
                  <h2>Forgot Password</h2>
                  <p className="mt-2">Don't worry, we got you! Just enter your email id and we will send you a link to reset your password.</p>

                    <label htmlFor="uname">Email</label>
                    <input  type="text"
                  
                  
                  name="userName"
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.userName} 
                  className="form-control" id="uname" placeholder="Enter email" />
                   {formikProps.errors.userName && formikProps.touched.userName &&
                 <small id="emailHelp" className="form-text text-warning">{formikProps.errors.userName}</small>
                }
                  </div>
                  <div className="form-group col-sm-12 col-md-8 mt-3">
                    <button type="submit" className="form-control btn">Send</button>
                  </div>
                </div>
              </form>
              </>
              )}
          </Formik>
            </div>
          </div>
        </div>
      </section>
        );
    }
}

export default ForgotPassword;