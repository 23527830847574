import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {

  Link
} from "react-router-dom";
import ReactLoader from '../widgets/Loader'
import { Formik } from "formik";
import * as yup from "yup";
import {connect} from "react-redux";
import { cartDetails } from '../action';
import { BASE_URL } from "../helper";

const axios = require('axios');


const validationSchema = yup.object().shape({
  userName: yup.string().required().label('User Name'),
  // lastName: yup.string().required().label('Last Name'),
  // email: yup.string().required().email().label('Email'),
  password: yup.string().required().label('Password')
  // password: yup.string().required().label('Password').matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase,(A-Z) One Lowercase(a-z), One Number(0-9) and one special case Character"
  // ),
});


class Login extends Component {


  constructor(props) {
    super(props);
    this.state = {
      errorMessage:null,
    };
  }
componentDidMount=()=>{

}



SignupApiCall=async(email, password)=>{
  this.setState({errorMessage:""});
  // return await axios({
    return await axios({
      method: 'post',
      url: BASE_URL+'api/accounts/login/',
      // mode: 'no-cors',
      // headers: { 'Content-Type': 'application/json' }, 
      headers: {'Access-Control-Allow-Origin': "https://3domg.com/",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json' }, 
      // headers: { 'Content-Type': 'application/json' }, 
      data: {
        email, password
      }
    })
    .then( (response)=> {
      
      return Promise.resolve(response);
      
    })
    .catch((error)=> {
      

      this.setState({errorMessage:error.response.data.message});
      return Promise.reject(error);
      
    });
  }


  SignupApiCall_1=(email, password)=>{

    axios({
      method: 'post',
      url: BASE_URL+'api/accounts/login',
      headers: { 'Content-Type': 'application/json' }, 
      data: {
        email, password
      }
    })
    .then( (response)=> {
      console.log(response);
    
      return Promise.resolve(response);
      
    })
    .catch((error)=> {
      return Promise.reject(error);

    });
  }


  reload = ()=>{
    const current = this.props.location.pathname;
    this.props.history.replace(`/reload`);
       setTimeout(() => {
         this.props.history.replace(current);
       });
   }
render() {
    return (
      <>
      {false && <ReactLoader/>}
      
      <section className="signin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-6 login-image" />
            <div className="col-sm-12 col-xs-12 col-md-6 Modal-Overlay-Right">
              <div className="col-sm-12 col-md-8 ml-3">
              <Link to="/" className="logo-signin"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/signin2.png`} alt="logo" /></Link>
              </div>
              <Formik
            initialValues={{ userName:'', password:'',usertype:"customer/designer" }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
            
            //  this.setState({loader:true});
              // setTimeout(() => {
              //   actions.setSubmitting(false);
              // }, 1000);
              let {userName, password}=values;
              this.SignupApiCall(userName, password).then((response)=>{
                console.log(response);
                localStorage.setItem('omg_token',response.data.token);
                localStorage.setItem('user_id',response.data.user.id);
                let user_name=JSON.stringify({first_name:response.data.user.first_name,last_name:response.data.user.last_name});
                localStorage.setItem('user_name',user_name);
                this.props.updateCartDetails();
                this.props.handleCloseModal();
                resetForm();
                this.reload();
                // this.props.history.push("/")
           
              }).catch((error)=>{
                console.log(error);
              })
      
            }}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <>
              <form onSubmit={formikProps.handleSubmit}>
             
                <div className="signupform">
                 
                  <div className="form-group col-sm-12 col-md-8">
                  <h2>Sign In</h2>
                  <p>Welcome back! We missed you!</p>
                    <label htmlFor="uname">Email</label>
                    <input type="text"
                  
                  
                     name="userName"
                     onBlur={formikProps.handleBlur}
                     onChange={formikProps.handleChange}
                     value={formikProps.values.userName} 
                      className="form-control" id="uname" placeholder="Enter email" />
                     
                {formikProps.errors.userName && formikProps.touched.userName &&
                 <small id="emailHelp" className="form-text text-warning">{formikProps.errors.userName}</small>
                }
                  </div>
                  <div className="form-group col-sm-12 col-md-8">
                    <label htmlFor="pwd">Password</label>
                    <input type="password"
                     name="password"
                     onBlur={formikProps.handleBlur}
                     onChange={formikProps.handleChange}
                     value={formikProps.values.password}
                      className="form-control" id="pwd" placeholder="Enter password"/>
                      {formikProps.errors.password && formikProps.touched.password &&
                 <small id="emailHelp" className="form-text text-warning">{formikProps.errors.password}</small>
                }
                  </div>
                  <div className="form-group form-forgot col-sm-12 col-md-8">
                    <label className="form-check-label"><a href="#" onClick={()=>this.props.handleOpenModal('ForgotPassword')} style={{color:"#000"}}>Forgot Password ?</a></label>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <button type="submit" className="form-control btn">SIGN IN</button>
                  </div>

                  {this.state.errorMessage &&
<div className="col-sm-12 col-md-8 mt-3">
<div className="alert alert-danger" role="alert">
{this.state.errorMessage}
</div>
</div>
} 

                  <p className="mt-3">Don't have an account ? <a href="#" onClick={()=>this.props.handleOpenModal('Signup')} className="logo-signin" style={{color:"#000"}}><b>SIGN UP</b></a></p>
                </div>
              </form>

              </>
              )}
          </Formik>
            </div>
          </div>
        </div>
      </section>
            </>
    );
}
}



// const mapStateToProps = (state) => {
//   console.log(state.cartDetails.data);  
// 	return {
// 		cartDetails: state.cartDetails.data,
   
// 	};
// };
const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: (token,id) => dispatch(cartDetails(token,id)),
	};
};

export default withRouter(connect(null, mapDispatchToProps)(Login));
