import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import HomeSlider from '../widgets/HomeSlider'
import { withRouter } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
// import styles from './styles.module.css'
import { Modal,Button } from 'react-bootstrap';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import { setSearchKey,setSearchUrl,cartDetails } from '../action';
import ForgotPassword from '../pages/ForgotPassword';
import { BASE_URL } from '../helper';
import { ToastProvider, useToasts } from 'react-toast-notifications';

import {

  Link
} from "react-router-dom";
import {connect} from "react-redux";
import LightBox from '../widgets/LightBox';
import CheckOtp from '../pages/CheckOtp';

const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOption:"/objects",
      showModal: false,
      search_text:'',
      search_url:'/objects',
      objectstoshow:[],
      graphicstoshow:[],
      materialtoshow:[],
      inspiretoshow:[],
      planList:[],

      objects:null,
      lightboxDisplay:false,
      imagesToShow:'/media/images/objects/thumb_bBDqxwg.jpg',
      productIDToLink:"",
      activeProductName:"Product",
      activeProductPrice:"100",
      bookmarks:[],
      is_favourite:0,
      activePlanPrice:"1",

    };
    this.planSection=React.createRef();
    this.handleOptionClick=this.handleOptionClick.bind(this);
  }
searchOptionHandler=(event)=>{
  this.setState({searchOption: event.target.value});
}
handleOptionClick = () => {

    this.props.history.push({
      pathname: this.props.search.url,
      state: { key: this.props.search.key,url: this.props.search.url}
  });


};
handleOpenModal= (form)=> {
  this.setState({formType:form},()=>{this.setState({ showModal: true })});
}
_doLogin(){
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');

  if(token === "" || token === null)
  {
    this.handleOpenModal('Login');
  }

}
handleCloseModal = ()=> {
  this.setState({ showModal: false });
}
scrollToPlans= ()=>{
  // console.log('fun');
  // this.props.history.push('inspire');
  //  this.planSection.current.scrollIntoView( {behavior: 'smooth'})
}
componentDidMount=()=>{
  const self = this;
  this.props.setSearchUrl("/objects");
  this.props.setSearchKey("");

  this.getImagesMarvel();
  this.getPlanDetails();
  // window.scrollTo(0, 0);
  // console.log(this.props.location.search)
  // if(this.props.location.state !== undefined ){
  //   if(this.props.location.state.plans !== undefined && this.props.location.state.plans ===true)
  //   this.planSection.current.scrollIntoView( {behavior: 'smooth'})
  // }




  this.$el = window.jQuery(this.el);
  this.$el.select2({
    dropdownCssClass: "custom-dropdown",
    val:self.props.search.url

 }).on('change', function(e) {
  //  self.setState({search_url:'dddd'});
  // search_url=e.target.value;
  // this.el.onChange();
   self.props.setSearchUrl(e.target.value);
  // this.props.setSearchUrl("test");
  //  alert(e.target.value)
 });

}
handleInputChange = (e) =>{
  this.setState({search_text:e.target.value});

}

showLightBox = (images,id,product_name,price,favourite) => {



  console.log(images);
  //set imageToShow to be the one that's been clicked on
  this.setState({imagesToShow:images,productIDToLink:id,activeProductName:product_name,activeProductPrice:price,lightboxDisplay:true,is_favourite:favourite});
console.log(this.state,'imagesToShow');

};
hideLightBox = () => {
  this.setState({lightboxDisplay:false})
}



getImagesMarvel(){
  axios({
    method: 'post',

    url: BASE_URL+`api/product/marvel_of_the_week_view`,
    // headers: { "Access-Control-Allow-Origin": "*",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" ,'Access-Control-Allow-Headers':"Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,Authorization","Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS" },
    headers: { 'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" },
    data: ""

  }).then( (response)=> {

    if(response.data.response_code ==200){
      this.setState({objectstoshow:response.data.object});
      this.setState({graphicstoshow:response.data.graphics});
      this.setState({materialtoshow:response.data.material});
      this.setState({inspiretoshow:response.data.inspire_offer});
      // console.log(response.data.object,'Marvel');


    }

  }).catch((error)=> {
    console.log('er',error);

  });
}


getPlanDetails(){
  axios({
    method: 'get',

    url: BASE_URL+`api/plans/plan`,
    // headers: { "Access-Control-Allow-Origin": "*",'Access-Control-Allow-Credentials':true,'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" ,'Access-Control-Allow-Headers':"Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,Authorization","Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS" },
    headers: { 'Content-Type': 'application/json',"Authorization":"Token dbb2f09ec75e0af0fa9df0330c4143141dc93f78" },
    data: ""

  }).then( (response)=> {

    if(response.data.response_code ==200){

      this.setState({planList:response.data.plans});
      // this.setState({graphicstoshow:response.data.graphics});
      // this.setState({materialtoshow:response.data.material});
      // this.setState({inspiretoshow:response.data.inspire_offer});
      // console.log(response.data,'Plans');


    }

  }).catch((error)=> {
    console.log('er',error);

  });
}





addToCartObject=async(productIDToLink,pricePerModel)=>{
  let id = localStorage.getItem('user_id');
  let token = localStorage.getItem('omg_token');
  console.log(token)
  if(token === "" || token === null)
  {
    this.props.addToast(`Please Sign In To Continue`, {
      appearance: 'error',
      autoDismiss: true,
    })
    this.handleOpenModal('Login');
  // this.props.history.push("/login")
  return Promise.reject();
  }else{
    return await axios({
      method: 'post',
      url: BASE_URL+'api/cart/cartitem/',
      headers: { 'Access-Control-Allow-Origin': "*",'Content-Type': 'application/json',"Authorization" : `Token ${token}` },

      data: {cart_items:[{
        "product_type":"plan",
        "object":"",
        "material":"",
        "graphics":"",
        "user_id":id,
        "seller_id":"1",
        "unit_price":pricePerModel,
        "quantity":"1",
        "plan":productIDToLink
      }]}

    })
    .then( (response)=> {
       this.props.addToast(`${this.state.activeProductName} added to cart`, {
      appearance: 'success',
      autoDismiss: true,
    })

      this.props.updateCartDetails();
      // this.props.updateBookmarkItems();
      return Promise.resolve(response);


    })
    .catch((error)=> {

      return Promise.reject(error);

    });
  }


}


  render() {
    const {search_url}=this.state;
    const planLists= this.state.planList;

    // console.log(this.state.objectstoshow,'objectstoshow');
    return (
      <>
   <Modal className="BModal" show={this.state.showModal}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered

onHide={()=>this.setState({showModal:false})}>
  {/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
  </Modal.Header> */}
  <Modal.Body>

  <span onClick={()=>this.setState({showModal:false})} className="close-button"><ion-icon name="close-outline"></ion-icon></span>

  {this.state.formType === "Login" &&
<Login handleOpenModal={(val)=>this.handleOpenModal(val)} handleCloseModal={()=>this.handleCloseModal()}/>
}

{this.state.formType === "Signup" &&
<Signup handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
{this.state.formType === "ForgotPassword" &&
<ForgotPassword handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}
{this.state.formType === "CheckOtp" &&
<CheckOtp handleOpenModal={(val)=>this.handleOpenModal(val)}  handleCloseModal={()=>this.handleCloseModal()}/>
}




  </Modal.Body>
  {/* <Modal.Footer>
    <Button variant="secondary" onClick={()=>this.setState({showBModal:false})}>
      Close
    </Button>
    <Button variant="primary" onClick={()=>this.setState({showBModal:false})}>
      Save Changes
    </Button>
  </Modal.Footer> */}
</Modal>





{ this.state.lightboxDisplay ?
             <LightBox hideLightBox={()=>this.hideLightBox()} activeProductName={this.state.activeProductName} imagesToShow={this.state.imagesToShow} productIDToLink={this.state.productIDToLink} addToBookMark={(val)=>this.addToBookMark(val)}  addToCart={(val)=>this.addToCart(val)} product_type={'object'} isFavourite={this.state.is_favourite} getProduct={()=>this.refresh()}/>
: '' }



 <div className="main-home" id="home-page">
     <Header background="#000"
    //  ref={this.testRef}
    scrollToPlans={()=>this.scrollToPlans()}/>
     <>

     <div className="container-fluid px-0 maincont">
        {/* Arrows */}
        <div className="arrow1">
          <img src="../assets/images/home1/ar1.png" />
        </div>
        <div className="arrow2">
          <img src="../assets/images/home1/ar2.png" />
        </div>
        <div className="arrow3"><img src="../assets/images/home1/ar3.png" /></div>
        <div className="arrow4"><img src="../assets/images/home1/ar4.png" /></div>
        {/* Background image */}
        <img src="../assets/images/home1/bg4.png" width="100%" height="5000px" className="bgimg" />
        <div className="posrel">
          {/* Section 1 */}
          <section id="section1">
            <div className="row mx-0 section1">
              <div className="col-sm-6">
                {/* serach box */}
                <div className="searchbox">
                  <div className="searchbg"><img src="../assets/images/home1/creative.png" width="100%" /></div>
                  <div className="form-group has-search">
                  <select className="search-dropdwn" ref={el => this.el = el} >
              {/* <option value="" selected={ search_url ==""?true:false}>Select</option> */}
                    <option value="/objects" >Objects</option>
                    <option value="/materials" > Materials</option>
                    <option value="/graphics" >Graphics</option>

                  </select>
                  <input type="text" className="form-control search" placeholder="Get Casting..." value={this.props.search.key} onChange={(e)=>{this.props.setSearchKey(e.target.value)}}/>
                  <button onClick={()=>this.handleOptionClick()}><span className="fa fa-search form-control-feedback" /></button>
                  </div>
                </div>
                {/* Girl */}
                <div className="girl">
                  <img src="../assets/images/home/sec-1-omg.png" className="translate-bg omg1" alt="" />
                  <img src="../assets/images/home1/girl-3.png" height="100%" className="girl" />
                </div>
              </div>
              <div className="col-sm-6">
                {/* Box */}
             <HomeSlider/>
                {/* Box1 */}
                <div className="box1">Hi...see what we have to offer</div>
              </div>
              {/* Caption */}
              <div className="caption1 parallax_scroll">

                <img src="../assets/images/home1/stuff.png" width="15px" className="stuff" />
                <span style={{marginRight: 75}}>
                <NavHashLink
                smooth={true}
                 to="/#section21"
                >
                <img src="../assets/images/home1/arrow-3.gif" width="100%" className="down1 down11" />
                </NavHashLink>
                </span>
              </div>
            </div>
          </section>
          {/* Section 2 */}
          <section id="section2">
            <div className="row mx-0 section2">
              <div className="col-sm-8 pos-rel">
                <img className="img11" src="../assets/images/home1/stfound-1.png" height="600px" />
                <img src="../assets/images/home1/stfoundtxt.png" className="st-found-text translate-bg" alt="" />
              </div>
              <div className="col-sm-4">
                <div className="box1">Go ahead..choose <br />your content <br />and design</div>
              </div>
            </div>
            <div className="row mx-0 section21" id="section21">


              <Link exact to="/objects" className="col-sm-2 section211 object ">{/* <img src="../assets/images/home1/object.png" height="220px"> */}</Link>
              <Link exact to="/materials" className="col-sm-2 section211 material">{/* <img src="../assets/images/home1/materials.png" height="220px"> */}</Link>
              <Link exact to="/graphics" className="col-sm-2 section211 graphics">{/* <img src="../assets/images/home1/graphics.png" height="220px"> */}</Link>
              <Link exact to="/inspire" className="col-sm-2 section211 inspire">{/* <img src="../assets/images/home1/inspire.png" height="220px"> */}</Link>
              {/* Caption */}
              <div className="caption1 parallax_scroll">
                <img src="../assets/images/home1/maytheforce.png" width="17px" className="stuff" />
                <span style={{marginRight: 75}}>
                <NavHashLink
                smooth={true}
                 to="/#section3"
                >
               <img src="../assets/images/home1/bolt.png" width="100%" className="down1 down12" />
                </NavHashLink>
                </span>

              </div>
            </div>
          </section>
          {/* Section 3  Done changes By Sushant */}
          <section id="section3">
            <div className="row mx-0 section3">
              <div className="col-sm-12">
                <div className="marv1">
                  <div className="marimg">
                    <img src="../assets/images/home1/wow.png" className="wowimg translate-bg" alt="" />
                    <img src="../assets/images/home1/mofw1.png" height="400px" className="marimg1" /></div>
                  {/* Box1 */}
                  <div className="box1">checkout the awesome work by these creators</div>
                </div>
              </div>
            </div>
            <div className="row mx-0 section31">

              <div className="col-sm-3 marvelbk-object">
                <Link exact to={`/productDetails/object/${this.state.objectstoshow.id}`} className="col-sm-2 section211 object ">
                </Link>
                {/* <img src="../assets/images/home1/b11.png" className="img-fluid" /> */}

                   <img src={BASE_URL.slice(0, -1)+`${this.state.objectstoshow.image}`} className="img-fluid marvel-inner-img"/>


              </div>
              <div className="col-sm-3 marvelbk-material">
                <Link exact to={`/productDetails/material/${this.state.materialtoshow.id}`} className="col-sm-2 section211 material">
                </Link>

                {/* <img src="../assets/images/home1/b21.png" className="img-fluid" /> */}
                <img src={BASE_URL.slice(0, -1)+`${this.state.materialtoshow.image}`} className="img-fluid marvel-inner-img"/>
                </div>
              <div className="col-sm-3 marvelbk-graphics">
                <Link exact  to={`/productDetails/graphics/${this.state.graphicstoshow.id}`} className="col-sm-2 section211 graphics">
                </Link>
                {/* <img src="../assets/images/home1/b31.png" className="img-fluid" /> */}
                <img src={BASE_URL.slice(0, -1)+`${this.state.graphicstoshow.image}`} className="img-fluid marvel-inner-img"/>
                </div>
              {/* Caption */}
              <div className="caption1 parallax_scroll">
                <img src="../assets/images/home1/ifyoubuild.png" width="19px" className="stuff" />
                <span style={{marginRight: 75}}>
                <NavHashLink
                smooth={true}
                 to="/#section4"
                >
                <img src="../assets/images/home1/paintbrush.png" width="100%" className="down1 down13" />
                </NavHashLink>
                </span>



              </div>
            </div>
          </section>
          {/* Section 4 */}
          <section id="section4">
            <div className="row mx-0 section4">
              <div className="col-sm-6 insp1">
                {/* <img src="../assets/images/home1/img1.png" alt="" /> */}
                <img src={BASE_URL.slice(0, -1)+`${this.state.inspiretoshow.offer_image}`}/>
                </div>
              <div className="col-sm-6 insp2">
                <img src="../assets/images/home1/hey.png" alt="" className="heyimg translate-bg" />
                <div className="insp-btw">
                  <button className="insp-bt1 modal-click" href="#signin" onClick={(e)=>{e.preventDefault();this._doLogin()}}><span className="btxt">OMG Dream IT</span><span className="arwb" /></button>
                  <button className="insp-bt2 modal-click" href="#signup" onClick={(e)=>{e.preventDefault();this.handleOpenModal('Signup');}}><span className="btxt">OMG Deal IT</span><span className="arww" /></button>
                </div>
                <div className="insp3">
                  <div className="insp4">Sign up..Dream It design it! deal it!</div>
                  <span className="bg" />
                </div>
                {/* Girl2 */}
                <div className="girl2"><img src="../assets/images/home1/wasda-1a.png" height={400} /></div>
              </div>
              {/* Caption */}
              <div className="caption1 parallax_scroll">
                <img src="../assets/images/home1/imgoingto.png" width="17px" className="stuff" />
                <span style={{marginRight: 110}}>
                <NavHashLink
                smooth={true}
                 to="/#section5"
                >
                <img src="../assets/images/home1/moneybag.png" width="100%" className="down1 down14" />
                </NavHashLink>
                </span>

              </div>
            </div>
          </section>
          {/* Section 5 */}
          <section id="section5">
            <div className="row mx-0 section5">
              <div className="col-sm-5"><div className="plan">
                  <img src="../assets/images/home1/plans.png" className="translate-bg planstxt" alt="" />
                  <img src="../assets/images/home1/plan-1.png" height="550px" className="planimg" /></div></div>
              <div className="col-sm-7"><div className="box1">save money...plans<br />for everyone!</div></div>
            </div>
          </section>
          {/* Section 6 */}
          <section id="section6" ref={this.planSection}>
            <div className="row mx-0 section6">
              <div className="col-sm-12">
                <ul className="nav nav-tabs">
                  <li className><a data-toggle="tab" href="#menu1" className="active">3D Objects &amp; Materials</a></li>
                  <li><a data-toggle="tab" href="#menu2">Graphics</a></li>
                </ul>
                <div className="tab-content">
                  <div id="menu1" className="tab-pane active menu1">
                  <div className="row bxw">

                    {/* box1 */}
                    {


                    planLists.filter(list => list.plan_category == 1).map((slider,index) => {

                      // console.log(plans)
                       const newindex=index+1;

                       return  <div className={`col-sm-4 box`+newindex}  key={index}>
                        <div className="price">${slider.price}</div>
                        <div className="txt">{slider.validity_months} Months Validity</div>
                        <ul className="items">
                          <li><span className="red">{slider.pro_models}</span> Pro Models</li>
                          <li><span className="red">{slider.free_models}</span> Bonus Models</li>
                          <li><span className="red">{slider.price_per_model} USD</span> Per Models</li>
                        </ul>
                        <button className= {`pack-bt1 bt`+newindex} onClick={()=>this.addToCartObject(slider.id,slider.price_per_model)}>
                          <span className="btxt">Get Started</span>
                          <span className="arwb" />
                        </button>
                        </div>
                    }
                    )
                  }


                    </div>

                  </div>{/* End row */}
                  <div id="menu2" className="tab-pane fade">
                    {/* box1 */}


                    <div className="row bxw">

                    {/* box1 */}
                    {


                    planLists.filter(list => list.plan_category == 2).map((slider,index) => {

                      // console.log(plans)
                       const newindex=index+1;

                       return  <div className={`col-sm-4 box`+newindex}  key={index}>
                        <div className="price">${slider.price}</div>
                        <div className="txt">{slider.validity_months} Months Validity</div>
                        <ul className="items">
                          <li><span className="red"></span>Images</li>
                          <li><span className="red"></span>Vectors</li>
                          <li><span className="red">{slider.price_per_model} USD</span> Per Models</li>
                        </ul>
                        <button className={`pack-bt1 bt`+newindex}  onClick={()=>this.addToCartObject(slider.id,slider.price_per_model)}>
                          <span className="btxt">Get Started</span>
                          <span className="arwb" />
                        </button>
                        </div>
                    }
                    )
                  }


                    </div>



                  </div>
                </div>
              </div>
              {/* Caption */}
              <div className="caption2">

              <span style={{marginRight: 85}}>
                <NavHashLink
                smooth={true}
                 to="/#home-page"
                >
                <img src="../assets/images/home1/rocket.png" width="100%" className="down2" />
                </NavHashLink>
                </span>

                <img src="../assets/images/beam.png" width="15px" className="stuff" />
              </div>
            </div>
          </section>
        </div>
      </div>
      </>

     <Footer/>
 </div>
 </>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state.cartDetails.data);
	return {

    search:{key:state.cartDetails.search_key,url:state.cartDetails.search_url}
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: (token,id) => dispatch(cartDetails(token,id)),
    setSearchKey: data => dispatch(setSearchKey(data)),
    setSearchUrl: data => dispatch(setSearchUrl(data)),
	};
};


export default withRouter(withToast(connect(mapStateToProps, mapDispatchToProps)(Home)));
