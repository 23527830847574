import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {

  Link
} from "react-router-dom";
import {connect} from "react-redux";
import { cartDetails,bookmarkItems } from '../action';
import { BASE_URL } from '../helper';

const axios = require('axios');
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}
class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems:null,
      cartTotal:0,
      grandTotal:0,
      userplan_info:'',
      confirm_order:"",
      item_outside_plan:"0",
      userValue:"",
      offer_id:0,
      isvalidcoupon_message:"",
      // isvalidcoupon:"",
    };
  }
 
  handleBillingClick = () => {
    if(this.state.cartItems.length > 0 && this.state.cartTotal!=0){
      this.props.handleActiveButtonClick(2); 

      let newarray={
        offer_id:this.state.offer_id,
        cartTotal:this.state.grandTotal,
      }
      this.props.parentCallback(newarray);


    }else if(this.state.cartTotal ==0){
      this.props.placeOrder();
    }
   
    
    
  };

  


  checkifcouponValid=()=>{

   
    const token = localStorage.getItem('omg_token');
    let id = localStorage.getItem('user_id');


    axios({
      method: 'post',
      url: BASE_URL+'api/cart/coupon_check',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data:{ 
  
        "user_id":id, 
        "coupon_code"   :  this.state.userValue,
        "amount":this.state.cartTotal,
      }
    })
    .then( (response)=> {

      if(response.data.is_coupon_valid==1){
        this.setState({isvalidcoupon:true,grandTotal:response.data.total_amount,offer_id:response.data.offer_id,isvalidcoupon_message:response.data.message});
        
      }else{
        this.setState({isvalidcoupon:false,grandTotal:0,offer_id:0,isvalidcoupon_message:response.data.message});
        this.getCartItems();

      }
    
    })
    .catch((error)=> {
      console.log('er-cartlist',error);
      // this.setState({error:true});
  
    });

  }
  handlePaymentClick = () => {
    this.props.history.push("/payment")
    
  };
  componentWillUnmount(){
    console.log("cart page unmounted");
  }
  getCartItems(){
    const token = localStorage.getItem('omg_token');
    let id = localStorage.getItem('user_id');

   

    axios({
      method: 'post',
      url: BASE_URL+'api/cart/cartlist',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data:{ 
  
        "user_id":id,      
      }
    })
    .then( (response)=> {
      this.setState({cartItems:response.data.cart_items})
      this.setState({cartTotal:response.data.total_amount});
      this.setState({grandTotal:response.data.total_amount});
      this.setState({confirm_order:response.data.confirm_order});
      this.setState({item_outside_plan:response.data.item_outside_plan})
      console.log(response)
  
      
    })
    .catch((error)=> {
      console.log('er-cartlist',error);
      // this.setState({error:true});
  
    });
  }
  componentDidMount(){
    this.getCartItems();
    this.getProfileData().then((res)=>{
      this.setState({loading:false});
  }).catch((err)=>{
    console.log('er',err);  
  });
  }

  getProfileData(){
    const token = localStorage.getItem('omg_token');
    const user_id = localStorage.getItem('user_id');

    return new Promise((resolve,reject)=>{
        axios({
            method: 'post',
            url: BASE_URL+'api/profile/view',
            headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` },
            data:{ 
              "user":user_id, 
            }
          })
          .then( (response)=> {
            // alert(JSON.stringify(response));
           

            this.setState({userplan_info:response.data.user_plan});
            // this.setState({completed:true});
        
            
          })
          .catch((error)=> {
            console.log('er',error);
            this.setState({error:true},()=>{
                reject(true);
            });
        
          });
    });
    
  }
  removeItem(id){
    const token = localStorage.getItem('omg_token');
    axios({
      method: 'delete',
      url: BASE_URL+'api/cart/cartitem/'+id,
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
     
    })
    .then( (response)=> {
      this.props.addToast(`item removed from cart succcessfully`, {
        appearance: 'success',
        autoDismiss: true,
      })
      console.log(response)
      this.getCartItems();
      this.props.updateCartDetails();
    })
    .catch((error)=> {
      console.log('er-cartlist',error);
      // this.setState({error:true});
  
    });
  }
  render() {

    const getInputValue = (event)=>{
      const userValue = event.target.value;

      this.setState({userValue:userValue});
  };



    return (
 <>
    
     <>
       {/* Cart Section */}
       
         
          <div className="row">
            <div className="col-sm-12 col-md-8">
              {/* My Cart */}
              <div className="card cdl">
                <div className="card-header">
                  Cart
                </div>
                <div className="card-body">
                  {/* Items */}

{
  this.state.cartItems &&
  <>
  {this.state.cartItems.map((val)=>
  <div className="item-wrap">
  <div className="iteml">
   {
   
   val.product_type != "plan" ?
     <img src={BASE_URL.slice(0, -1)+`${val.product.images[0]}`} alt="" className="iteml1" width={128} height={115} />
   :''
  }

  {(val.product_type =='object') && (
      <p className="iteml2-txt1">{val.product.object_name.toUpperCase()}</p>  
   )
  }
  {(val.product_type =='material') && (
    <p className="iteml2-txt1">{val.product.material_name.toUpperCase()}</p>
  )
  }
  {(val.product_type =='graphics') && (
    <p className="iteml2-txt1">{val.product.graphics_name.toUpperCase()}</p>
  )
  }

  {(val.product_type =='plan') && (
    <p className="iteml2-txt1">{val.product.plan_name}</p>
  )
  }
    <div className="iteml2">
      {(val.product_type =='object') && (
       <>
        
      <p className="iteml2-txt2">Category :{val.product.category}</p>
       </>
      )}
       {(val.product_type =='material') && (
       <>
       
      <p className="iteml2-txt2">Category :{val.product.category}</p>
       </>
      )}
      {(val.product_type =='graphics') && (
       <>
        {/* <p className="iteml2-txt1">{val.product.graphics_name}</p> */}
      <p className="iteml2-txt2">Category :{val.product.category}</p>
       </>
      )}
       {(val.product_type =='plan') && (
       <>
       
      <p className="iteml2-txt2">Category :{val.product.plan_category == '1' ? '3D Objects & Materials' : 'Graphics'}</p>
       </>
      )}
      
    </div>
  </div>
  <div className="itemr">
    <div className="itemr1">{val.product_type}</div>
    <div className="itemr2" onClick={()=>{this.removeItem(val.id);}}>Remove</div>
  </div>
</div>
  )

  }
  </>
}

                  

                  {/* Items */}
                  {/* <div className="item-wrap">
                    <div className="iteml">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/cart4.png`} alt="" className="iteml1" width={128} height={115} />
                      <div className="iteml2">
                        <p className="iteml2-txt1">Set of Indoor Plants</p>
                        <p className="iteml2-txt2">Category :Plants</p>
                      </div>
                    </div>
                    <div className="itemr">
                      <div className="itemr1">Bonus Model</div>
                      <div className="itemr2">Remove</div>
                    </div>
                  </div> */}
                </div>{/* End Card Body */}
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              {/* My Cart */}
              <div className="card cdr">
                <div className="card-header">
                  My Plan
                </div>
                <div className="card-body">
                  <h3 className="pckhead">{this.state.userplan_info.plan_name}</h3>
                  {/* Item 1 */}
                  <div className="pack-wrap">
                    <div className="packcont">
                      <div className="packcont-item">Pro Items Remaining</div>
                      <div className="packcont-item">{this.state.userplan_info.available_pro_items}{this.state.userplan_info.total_pro_items ? '/ '+ this.state.userplan_info.total_pro_items : ""}</div>
                    </div>
                  </div>
                  {/* Item 2 */}
                  <div className="pack-wrap">
                    <div className="packcont">
                      <div className="packcont-item">Bonus Items Remaining</div>
                      <div className="packcont-item">{this.state.userplan_info.available_bonus_items}{this.state.userplan_info.total_bonus_items ? '/ '+this.state.userplan_info.total_bonus_items : ""}</div>
                    </div>
                  </div>
                  {/* Item 3 */}
                  <div className="pack-wrap">
                    <div className="packcont">
                      <div className="packcont-item">Item Outside Plan</div>
                      <div className="packcont-item">{this.state.item_outside_plan}</div>
                    </div>
                  </div>
                  {/* Item 4 */}
                  <div className="pack-wrap total">
                    <div className="packcont">
                      <div className="packcont-item txt">Total</div>
                      <div className="packcont-item price">${this.state.cartTotal}</div>
                    </div>
                  </div>
                  {/* Coupon */}
                  <div className="coupon">
                    <div className="form-group cpwrap">
                      <input className="cptxt" type="text" name="txt"  placeholder="Enter coupon code" onChange={getInputValue}/>
                      <button className="cpbtn btn" onClick={()=>this.checkifcouponValid()}>Apply</button>
                    </div>
                  </div>

                  {this.state.isvalidcoupon ==false && 

                    <div className="col-sm-12 col-md-12 mt-3">
                    <div className="alert alert-danger" role="alert">
                    {this.state.isvalidcoupon_message}
                    </div>
                    </div>



                  } 
                    {this.state.isvalidcoupon ==true && 

                    <div className="col-sm-12 col-md-12 mt-3">
                    <div className="alert alert-success" role="alert">
                    {/* Coupon Applied Successfully */}
                    {this.state.isvalidcoupon_message}
                    </div>
                    </div>



                    }



                  

                  {/* Item 5 */}
                  <div className="pack-wrap">
                    <div className="packcont">
                      <div className="packcont-item gt">Grand Total</div>
                      <div className="packcont-item gp">${this.state.grandTotal}</div>
                    </div>
                  </div>
                  {/* Button */}
                 {/* {  */}
                 {this.state.confirm_order ==0 && 

                    <div className="col-sm-12 col-md-12 mt-3">
                    <div className="alert alert-danger" role="alert">
                    Minimum of 15Usd purchase needed
                    </div>
                    </div>

 

                  }


                  
                 
                  <button onClick={()=>this.handleBillingClick()} className="chobtn" disabled={(this.state.confirm_order ==0)?true:false}
 >Proceed</button>
                </div>{/* End Card body */}
              </div>{/* End Card */}
            </div>{/* End Col */}
          </div>
        
     </>
 
     
 </>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("bookmarks",state.cartDetails.bookmark_items);  
	return {
		cartDetails: state.cartDetails.data,
    bookmarkItems:state.cartDetails.bookmark_items,
   
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCartDetails: () => dispatch(cartDetails()),
    updateBookmarkItems: () => dispatch(bookmarkItems()),
	};
};
export default withRouter(withToast(connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)));