import React, { Component } from 'react';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
import { withRouter } from 'react-router-dom';
import { BASE_URL } from '../helper';

import {Link} from "react-router-dom";
const axios = require('axios');

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      downloads:[],
     
     
    };
  }

componentDidMount(){
  this.getDownloads();
}
getDownloads(){
    const token = localStorage.getItem('omg_token');
    let id = localStorage.getItem('user_id');
    
    axios({
      method: 'post',
      url: BASE_URL+'api/checkout/downloads',
      headers: { 'Content-Type': 'application/json',"Authorization" : `Token ${token}` }, 
      data:{ 
  
        "user":id,      
      }
    })
    .then( (response)=> {
      this.setState({downloads:response.data.downloads})
     
    //   console.log(response)
  
      
    })
    .catch((error)=> {
      console.log('er-downloads',error);
      // this.setState({error:true});
  
    });
}


  render() {

    return (
 <>
     <Header/>
     <>
     {/*  Section */}
     <section className="cart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              {/* Cart Buttons */}
              <div className="cartbtn">
                <div className="cartbtn-wrap">
                  <button className="cartbtn-item active">Downloads</button>
                </div> 
                {/* <div className="cartbtn-wrap">
                  <button className="cartbtn-item">All Uploads</button>
                </div> */}
                {/* <div className="cartbtn-wrap searchw">
                  <input type="text" name="search" defaultValue className="searchinp" placeholder />
                  <button className="searchb"><span className="fa fa-search" /></button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              {/* My Cart */}
              <div className="card cdl">
                 {this.state.downloads.length > 0 && (
                     <>
                      <div className="card-header d-flex flex-wrap flex-row">
                  <div className="cardhead itemc1">Product Name</div>
                  <div className="cardhead itemc2">Date</div>
                  <div className="cardhead itemc3">Category</div>
                  <div className="cardhead itemc4" />
                </div>
                <div className="card-body">
                  {/* Items */}
                  {this.state.downloads.map((val)=>
                    <div className="item-wrap">
                  <div className="itemc1 d-flex flex-wrap flex-row">
                    <img src={BASE_URL.slice(0, -1)+`${val.product.thumbnail_image}`} alt="" className="iteml1" width={128} height={115} />
                    {(val.product_type =='object') && (
       <>
        
        <span className="itemc-imgtxt">{val.product.object_name.toUpperCase()}</span>
     
       </>
      )}
      {(val.product_type =='material') && (
       <>
        
        <span className="itemc-imgtxt">{val.product.material_name.toUpperCase()}</span>
     
       </>
      )}
                {(val.product_type =='graphics') && (
       <>
        
        <span className="itemc-imgtxt">{val.product.graphics_name.toUpperCase()}</span>
     
       </>
      )}    
                  </div>
                  <div className="itemc2"><span className="itemc-txt">{val.ordered_on}</span></div>
                  <div className="itemc3"><span className="itemc-txt">{val.product.category}</span></div>
                  <div className="itemc4"><a href={val.download_link}><button className="itemc-btn">Download</button></a></div>
                </div>
                  
                  
                  )}
                  
                
                </div>{/* End Card Body */}
                     </>
                 )}
               
              </div>
            </div>
          </div>
        </div>
      </section>
     </>
 
     <Footer/>
 </>
    );
  }
}
export default withRouter(Downloads);